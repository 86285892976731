<template>
  <div class="flow std-side-padding mb-12 pt-10">
    <div class="stepFlow">
      <div class="step">
        <div class="label-step complete">OFFERTA</div>
        <div :class="`circle ${flowOffert.completed ? 'complete' : ''}`">
          <span>1</span>
        </div>
        <v-progress-linear
          :value="(100 / flowOffert.list.length) * flowOffert.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div class="step">
        <div
          :class="`label-step ${step > 17 || step === 14 ? 'complete' : ''}`"
        >
          DATI
        </div>
        <div :class="`circle ${flowData.completed ? 'complete' : ''}`">
          <span>2</span>
        </div>
        <v-progress-linear
          :value="(100 / flowData.list.length) * flowData.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
      <div :class="`step ${step >= 13 && step <= 14 ? 'hidden' : ''}`">
        <div :class="`label-step ${step > 21 ? 'complete' : ''}`">CONFERMA</div>
        <div :class="`circle ${flowConferm.completed ? 'complete' : ''}`">
          <span>3</span>
        </div>
        <v-progress-linear
          :value="(100 / flowConferm.list.length) * flowConferm.index"
          color="#5eb83d"
          background-color="transparent"
        ></v-progress-linear>
      </div>
    </div>
    <div class="flow__back HeadingM mb-6" @click="prevStep">
      <img src="../../../assets/ic-chevron-sx-blue.svg" width="24" />
      <span>{{ titleLabel.title }}</span>
    </div>
    <div class="HeadingL mb-4 mt-4">
      {{ titleLabel.subTitle }}
    </div>
    <SelectAddress
      v-if="step === 0"
      @nextStep="nextStep"
      @goToStep="goToStep"
      typeCommodity="gas"
      @setFlowType="setFlowType"
    ></SelectAddress>
    <SelectOwner v-if="step === 1" @nextStep="nextStep"></SelectOwner>
    <AddressOwner v-if="step === 2" @nextStep="nextStep"></AddressOwner>
    <RequestType
      v-if="step === 3"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></RequestType>
    <OwnerSupply
      v-if="step === 4"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></OwnerSupply>
    <DiscoverEstimate
      v-if="step === 5"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></DiscoverEstimate>
    <Resident
      v-if="step === 6"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></Resident>
    <UseType
      v-if="step === 7"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></UseType>
    <CurrentSupplier v-if="step === 8" @nextStep="nextStep"></CurrentSupplier>
    <SupplierDataInputGas
      v-if="step === 9"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></SupplierDataInputGas>
    <PropertyOwner v-if="step === 10" @nextStep="nextStep"></PropertyOwner>
    <UseOfGas v-if="step === 11" @nextStep="nextStep"></UseOfGas>
    <PriceQuotation
      v-if="step === 12"
      @nextStep="nextStep"
      @goToStep="goToStep"
      @setFlowType="setFlowType"
    ></PriceQuotation>
    <SpeakWith
      v-if="step === 13"
      @nextStep="nextStep"
      @setCallMeBack="setCallMeBack"
    ></SpeakWith>
    <BillUpload
      v-if="step === 14"
      @nextStep="nextStep"
      :isCallMeBack="isCallMeBack"
    ></BillUpload>
    <HomeSize v-if="step === 15" @nextStep="nextStep"></HomeSize>
    <HeatWater
      v-if="step === 16"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></HeatWater>
    <HeatHome
      v-if="step === 17"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></HeatHome>

    <ActivationTimes
      v-if="step === 18"
      @nextStep="nextStep"
      @goToStep="goToStep"
    ></ActivationTimes>
    <!-- <FiscalCode v-if="step === 19" @nextStep="nextStep"></FiscalCode> -->
    <Payment
      v-if="step === 20"
      @nextStep="nextStep"
      @prevStep="prevStep"
      :editMode="editMode"
      @goToStep="goToStep"
    ></Payment>
    <InfoConsent v-if="step === 21" @nextStep="nextStep"></InfoConsent>

    <CheckData
      v-if="step === 22"
      @nextStep="nextStep"
      @goToEdit="goToEdit"
    ></CheckData>
  </div>
</template>
<script>
import SelectAddress from './offert/SelectAddress.vue'
import SelectOwner from './offert/SelectOwner.vue'
import AddressOwner from './offert/AddressOwner.vue'
import RequestType from './offert/RequestType.vue'
import OwnerSupply from './offert/OwnerSupply.vue'
import DiscoverEstimate from './offert/DiscoverEstimate.vue'
import Resident from './offert/Resident.vue'
import UseType from './offert/UseType.vue'
import CurrentSupplier from './offert/CurrentSupplier.vue'
import SupplierDataInputGas from './offert/SupplierDataInputGas.vue'
import PropertyOwner from './offert/PropertyOwner.vue'
import UseOfGas from './offert/UseOfGas.vue'
import PriceQuotation from './offert/PriceQuotation.vue'
import BillUpload from './offert/bill-upload/BillUpload.vue'
import SpeakWith from './offert/bill-upload/SpeakWith.vue'
import HomeSize from './offert/no-bill/HomeSize.vue'
import HeatWater from './offert/no-bill/HeatWater.vue'
import HeatHome from './offert/no-bill/HeatHome.vue'

import ActivationTimes from './data/ActivationTimes.vue'
import Payment from './data/Payment.vue'
import InfoConsent from './data/InfoConsent.vue'

import CheckData from './summary/CheckData.vue'

import { mapActions, mapMutations } from 'vuex'
import { uuidv4 } from '../../../js/utils'
export default {
  name: 'gasFlow',
  props: ['type'],
  data() {
    return {
      step: 0,
      historyStep: [{ step: 0, trackEventName: '' }],
      stepTitle: [
        {
          title: 'Seleziona indirizzo',
          subTitle:
            'Scegli su quale indirizzo vuoi richiedere l’attivazione del nuovo servizio'
        },
        { title: 'Seleziona intestatario', subTitle: '' },
        { title: 'Indirizzo intestatario', subTitle: '' },
        { title: 'Tipologia della richiesta', subTitle: '' },
        {
          title: 'Dettagli fornitura',
          subTitle: 'Confermi di essere l’attuale intestatario dell’utenza?'
        },
        { title: 'Come vuoi scoprire la stima', subTitle: '' },
        {
          title: 'Dettagli fornitura',
          subTitle: 'Sei residente in questo indirizzo?'
        },
        {
          title: 'Dettagli utenza',
          subTitle: 'Che tipo di uso fai dell’abitazione?'
        },
        {
          title: 'Dettagli utenza',
          subTitle: 'Qual è il tuo attuale fornitore?'
        },
        { title: 'Dettagli utenza', subTitle: '' },
        { title: 'Dettagli utenza', subTitle: 'Titolarità dell’immobile' },
        { title: 'Dettagli utenza', subTitle: 'Tipologia d’uso del gas' },
        { title: 'Preventivo della tua offerta', subTitle: '' },
        { title: 'Vuoi parlare con un operatore?', subTitle: '' },
        { title: 'Carica la tua bolletta', subTitle: '' },
        { title: 'Dettagli utenza', subTitle: 'Quanto è grande la tua casa?' },
        { title: 'Dettagli utenza', subTitle: 'Come scaldi l’acqua?' },
        { title: 'Dettagli utenza', subTitle: 'Come riscaldi la casa?' },
        { title: 'Tempi di attivazione', subTitle: '' },
        { title: 'Aggiungi codice fiscale', subTitle: '' },
        { title: 'Metodo di pagamento', subTitle: '' },
        { title: 'Informazioni e consensi', subTitle: '' },
        { title: 'Consensi', subTitle: 'Verifica dati' }
      ],
      flowType: '',
      flowOffert: {
        list: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        index: 1,
        completed: false
      },
      flowData: {
        list: [18, 20, 21],
        index: 0,
        completed: false
      },
      flowConferm: {
        list: [22],
        index: 0,
        completed: false
      },
      editMode: false,
      isCallMeBack: false
    }
  },
  computed: {
    titleLabel() {
      if (this.isCallMeBack) {
        return { title: 'Dati di contatto' }
      } else {
        return this.stepTitle[this.step]
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingComm', [
      'setCommodityType',
      'setUpsellingSessionId',
      'setCurrentStep',
      'resetValidationErrors'
    ]),
    nextStep(trackEventName) {
      this.resetValidationErrors()
      this.step++
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.setFlowType('')
      this.setFlow('')
      this.setCurrentStep(this.stepTitle[this.step])
      if (trackEventName !== 'notrack') {
        this.trackTapEvent({
          name: trackEventName,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.$store.getters['upsellingComm/address']
              ? this.$store.getters['upsellingComm/address'][
                  'clientOwnerCode'
                ] || ''
              : 'not_selected'
          }
        })
      }
    },
    prevStep() {
      this.resetValidationErrors()
      this.historyStep.pop(1)
      if (this.historyStep.length === 0) {
        this.$router.push({ name: 'ownerSupplies' })
        return
      }
      this.setFlowType('')
      this.setCurrentStep(this.stepTitle[this.step])
      if (
        this.historyStep[this.historyStep.length - 1].trackEventName !==
        'notrack'
      ) {
        this.trackTapEvent({
          name: this.historyStep[this.historyStep.length - 1].trackEventName,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.$store.getters['upsellingComm/address']
              ? this.$store.getters['upsellingComm/address'][
                  'clientOwnerCode'
                ] || ''
              : 'not_selected'
          }
        })
      }

      this.editMode = false
      if (
        this.step === 3 ||
        (this.step === 13 && this.historyStep.length === 1)
      ) {
        this.$router.push({ name: 'offersgas', params: { type: 'gas' } })
      } else {
        this.step = this.historyStep[this.historyStep.length - 1].step
        this.$forceUpdate()
        this.setFlow()
      }
    },
    goToStep(step, trackEventName) {
      this.resetValidationErrors()
      this.step = step
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.setFlow()
      this.setCurrentStep(this.stepTitle[this.step])
      if (trackEventName !== 'notrack') {
        this.trackTapEvent({
          name: trackEventName,
          params: {
            type: this.$store.getters['upsellingComm/commodityType'],
            client_selected: this.$store.getters['upsellingComm/address']
              ? this.$store.getters['upsellingComm/address'][
                  'clientOwnerCode'
                ] || ''
              : 'not_selected'
          }
        })
      }
    },
    goToEdit(step, trackEventName) {
      this.resetValidationErrors()
      this.step = step
      this.historyStep.push({ step: this.step, trackEventName: trackEventName })
      this.editMode = true
      this.setCurrentStep(this.stepTitle[this.step])
    },
    setFlowType(flow) {
      this.flowType = flow
      this.$forceUpdate()
    },
    setCallMeBack(val) {
      this.isCallMeBack = val
    },
    setFlow() {
      let index
      if (this.step <= 17) {
        this.flowData.index = 0
        this.flowData.completed = false
        if (this.step === 0) {
          this.flowOffert.list = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else if (this.flowType === 'selectedAddress') {
          this.flowOffert.list = [0, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        }
        if (this.step >= 3 && this.step <= 17) {
          let flowOffertHistory = this.flowOffert.list.slice(
            0,
            this.flowOffert.list.indexOf(3) + 1
          )
          if (this.flowType === 'changeSupplier') {
            this.flowOffert.list = [
              ...flowOffertHistory,
              4,
              5,
              6,
              8,
              9,
              10,
              11,
              12
            ]
          } else if (
            this.flowType === 'reactivation' ||
            this.flowType === 'newConnection'
          ) {
            this.flowOffert.list = [...flowOffertHistory, 6, 8, 9, 10, 11, 12]
          }
          if (this.step === 5) {
            this.flowOffert.list = [
              ...flowOffertHistory,
              4,
              5,
              6,
              8,
              9,
              10,
              11,
              12
            ]
            flowOffertHistory = this.flowOffert.list.slice(
              0,
              this.flowOffert.list.indexOf(5) + 1
            )
          }
          if (this.step === 12) {
            this.flowOffert.list = this.flowOffert.list.slice(
              0,
              this.flowOffert.list.indexOf(12) + 1
            )
          }
          if (this.step === 14) {
            this.flowData.index = 3
            this.flowData.completed = true
          }
          if (this.flowType === 'uploadBill') {
            this.flowOffert.list = [...flowOffertHistory, 13, 14]
          } else if (this.flowType === 'noBill') {
            this.flowOffert.list = [...flowOffertHistory, 6, 7, 15, 16, 17, 12]
          } else if (this.flowType === 'enterDataManually') {
            this.flowOffert.list = [
              ...flowOffertHistory,
              4,
              5,
              6,
              8,
              9,
              10,
              11,
              12
            ]
          } else if (this.flowType === 'support') {
            this.flowOffert.list = [...this.flowOffert.list, 13, 14]
          } else if (this.flowType === 'noOwner') {
            this.flowOffert.list = [
              ...flowOffertHistory,
              4,
              5,
              6,
              8,
              9,
              10,
              13,
              14
            ]
          }
        }
        index = this.flowOffert.list.indexOf(this.step) + 1
        this.flowOffert.index = index
        this.flowOffert.completed = index === this.flowOffert.list.length
      } else if (this.step >= 18 && this.step <= 21) {
        this.flowConferm.index = 0
        this.flowConferm.completed = false
        if (this.flowType === 'canNotAnticipate') {
          this.flowData.list = [20, 21]
        }
        index = this.flowData.list.indexOf(this.step) + 1
        this.flowData.index = index
        this.flowData.completed = index === this.flowData.list.length
      } else if (this.step >= 22) {
        index = this.flowConferm.list.indexOf(this.step) + 1
        this.flowConferm.index = index
        this.flowConferm.completed = index === this.flowConferm.list.length
      }
    }
  },
  beforeMount() {
    this.setCommodityType('gas')
    this.setUpsellingSessionId(uuidv4())
    this.setCurrentStep(this.stepTitle[this.step])
    if (this.$route.params.from === 'offer') {
      this.goToStep(3, 'upsellingcom_reqtype_open')
    } else if (this.$route.params.from === 'speakWith') {
      this.setFlowType('uploadBill')
      this.goToStep(13, 'upsellingcom_uploadbill_open')
    }
  },
  components: {
    SelectAddress,
    SelectOwner,
    AddressOwner,
    RequestType,
    OwnerSupply,
    DiscoverEstimate,
    Resident,
    UseType,
    CurrentSupplier,
    SupplierDataInputGas,
    PropertyOwner,
    UseOfGas,
    PriceQuotation,
    BillUpload,
    SpeakWith,
    HomeSize,
    HeatWater,
    HeatHome,
    ActivationTimes,
    Payment,
    InfoConsent,
    CheckData
  }
}
</script>
<style lang="scss" scoped>
@keyframes bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.9);
  }
  66% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.flow {
  border-top: 1px solid darkgray;
  &__back {
    margin-top: 48px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    img {
      margin-right: 16px;
    }
    span {
      color: #12256a;
    }
  }
  .v-progress-linear {
    position: absolute;
    top: 15px;
    left: 0;
  }
  .stepFlow {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .step {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      text-align: right;
      position: relative;
      &.hidden {
        opacity: 0;
      }
      .label-step {
        color: #ccd0e1;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: normal;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -10px;
        &.complete {
          color: black;
        }
      }
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 21px;
        font-weight: 500;
        color: #ccd0e1;
        z-index: 1;
        background: white;
        border: 2px solid;
        border-color: #ccd0e1;
        transition: all 0.5s ease;
        span {
          opacity: 1;
          transition: opacity 0.2s ease;
        }
        &::after {
          content: url('../../../assets/ic-check-white.svg');
          position: absolute;
          top: 2px;
          opacity: 0;
          transition: opacity 0.1s ease;
        }
        &.complete {
          animation: bounce 0.5s ease-in-out;
          animation-delay: 0.2s;
          background-color: #5eb83d;
          border-color: #5eb83d;
          color: #fff;
          position: relative;
          span {
            opacity: 0;
            transition: opacity 0.1s ease;
          }
          &::after {
            opacity: 1;
          }
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        background: #ccd0e1;
        top: 15px;
        left: 0;
        z-index: 0;
      }
    }
  }
}
</style>
