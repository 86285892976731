<template>
  <v-dialog
    v-model="dialogOpen"
    persistent
    max-width="558"
    overlay-opacity="0.6"
    content-class="confirmationDialog"
  >
    <div class="confirmationDialog">
      <div class="confirmationDialog__header">
        <div v-html="titleModal" class="SubheadingL"></div>
        <img
          @click="$emit('update:confirmationDialogOpen', false)"
          src="@/assets/close.svg"
        />
      </div>
      <div v-html="descriptionModal" class="BodyL mt-6"></div>
      <div class="ctaSection">
        <MYSButton
          @buttonClick="$emit('cta1Click')"
          buttonClass="primaryInvertedButtonBorded"
          width="231"
          :buttonText="cta1"
        />
        <MYSButton
          @buttonClick="
            isSending = true
            $emit('cta2Click')
          "
          buttonClass="primaryButton"
          :loading="isSending"
          width="231"
          :buttonText="cta2"
        />
      </div>
    </div>
  </v-dialog>
</template>
<script>
import MYSButton from '@/components/global/MYSButton.vue'
export default {
  name: 'ConfirmationDialog',
  data() {
    return {
      isSending: false
    }
  },
  components: {
    MYSButton
  },
  props: {
    confirmationDialogOpen: {
      type: Boolean
    },
    titleModal: {
      type: String
    },
    descriptionModal: {
      type: String
    },
    cta1: {
      type: String
    },
    cta2: {
      type: String
    }
  },
  computed: {
    dialogOpen: {
      get() {
        return this.confirmationDialogOpen
      },
      set(val) {
        this.$emit('update:confirmationDialogOpen', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-dialog {
  border-radius: 20px;
}
.confirmationDialog {
  background: white !important;
  border-radius: 20px !important;
  padding: 40px !important;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    img {
      cursor: pointer;
    }
  }
  .ctaSection {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
}
</style>
