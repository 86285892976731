<template>
  <div class="punChart">
    <div class="titleCard">
      <div class="HeadingS-strong">{{ date }}</div>
      <div class="LabelL">{{ copyDate }}</div>
    </div>
    <div v-if="hasTomorrowData" class="BodyS-strong mt-7 ml-2">
      {{ ordinateUnitMeasurement }}
    </div>
    <div class="box mt-6" v-if="!hasTomorrowData">
      <div class="noTomorrowData">
        <img src="@assets/pun/no-tomorrow-pun.svg" />
        <div class="BodyM noTomorrowData__text mt-3">
          {{ errorCopy }}
        </div>
      </div>
    </div>
    <VueApexCharts
      v-if="hasTomorrowData"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    VueApexCharts
  },
  name: 'PunChart',
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          height: 350,
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        xaxis: {
          categories: this.categories
        },
        stroke: {
          width: 3
        },
        grid: {
          show: true,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            },
            padding: {
              top: 0,
              right: 1,
              bottom: 1,
              left: 1
            }
          }
        },
        colors: ['#12256A', '#97D707'],
        markers: {
          size: 5,
          shape: 'circle',
          strokeWidth: 0,
          hover: {
            size: 5,
            sizeOffset: 0
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class="tooltip">
                      <div class="BodyS">
                        Ore ${w.globals.labels[dataPointIndex]}:00
                      </div>
                      <div class="BodyM-Strong">
                        ${series[seriesIndex][dataPointIndex]} €/Kwh
                      </div>
                    </div>`
          }
        }
      }
    }
  },
  props: {
    series: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    copyDate: {
      type: String,
      required: true
    },
    ordinateUnitMeasurement: {
      type: String,
      required: true
    },
    yaxisMin: {
      type: Number,
      required: true
    },
    yaxisMax: {
      type: Number,
      required: true
    },
    hasTomorrowData: {
      type: Boolean
    },
    errorCopy: {
      type: String
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
.LabelL {
  color: $neutri-neutral-50;
}
.titleCard {
  width: 565px;
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  padding: 16px;
  background-color: $neutri-neutral-20;
}
.punChart {
  width: 100%;
}
.apexcharts-xaxistooltip {
  background: $srg-blue !important;
  color: white !important;
}
.noTomorrowData {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__text {
    width: 290px;
    text-align: center;
  }
}
.box {
  border-style: solid;
  border-color: #dfe4ef;
  border-radius: 8px;
  border-width: 1px;
}
</style>
<style lang="scss">
.tooltip {
  width: 116px;
  height: 50px;
  background-color: white;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
