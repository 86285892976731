import { validDate } from '@/js/utils'

export default class CheckBidgelyVisibility {
  clientDetail
  supplyDetail

  constructor(clientDetail, supplies) {
    this.clientDetail = clientDetail
    this.supplyDetail = supplies
  }

  NoPrivacyNoSupplyAfterOrEqual2Month(activationDate) {
    return this.checkBidgely(false, true, false, activationDate, '>=', 2, '1')
  }

  NoPrivacyNoSupplyBefore2Month(activationDate) {
    return this.checkBidgely(false, true, false, activationDate, '<', 2, '1')
  }

  NoPrivacyYesSupplyAfterOrEqual4Month(activationDate) {
    return this.checkBidgely(false, true, true, activationDate, '>=', 4, '1')
  }

  NoPrivacyYesSupplyBefore4Month(activationDate) {
    return this.checkBidgely(false, true, true, activationDate, '<', 4, '1')
  }

  YesPrivacyNoMainRoleNoSupplyAfterOrEqual2Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      false,
      false,
      activationDate,
      '>=',
      2,
      mainRole
    )
  }

  YesPrivacyNoMainRoleNoSupplyBefore2Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      false,
      false,
      activationDate,
      '<',
      2,
      mainRole
    )
  }

  YesPrivacyNoMainRoleYesSupplyAfterOrEqual4Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      false,
      true,
      activationDate,
      '>=',
      4,
      mainRole
    )
  }

  YesPrivacyNoMainRoleYesSupplyBefore4Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      false,
      true,
      activationDate,
      '<',
      4,
      mainRole
    )
  }

  YesPrivacyYesMainRoleNoSupplyAfterOrEqual2Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      true,
      false,
      activationDate,
      '>=',
      2,
      mainRole
    )
  }

  YesPrivacyYesMainRoleNoSupplyBefore2Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      true,
      false,
      activationDate,
      '<',
      2,
      mainRole
    )
  }

  YesPrivacyYesMainRoleYesSupplyAfterOrEqual4Month(activationDate, mainRole) {
    return this.checkBidgely(
      true,
      true,
      true,
      activationDate,
      '>=',
      4,
      mainRole
    )
  }

  YesPrivacyYesMainRoleYesSupplyBefore4Month(activationDate, mainRole) {
    return this.checkBidgely(true, true, true, activationDate, '<', 4, mainRole)
  }

  checkBidgely(
    privacy,
    mainroleTest,
    supply,
    activationDate,
    operator,
    offset,
    mainRole
  ) {
    const ifPrivacy = privacy ? this.YesPrivacy() : this.NoPrivacy()
    const ifSupply = supply ? this.YesSupply() : this.NoSupply()
    const ifMainrole = this.CheckMainClient(mainRole) === mainroleTest
    const ifDate = this.offsetMonthCalculateDate(
      activationDate,
      operator,
      offset
    )
    return ifPrivacy && ifSupply && ifMainrole && ifDate
  }

  isBidgely(activationDate, mainRole) {
    return (
      this.YesPrivacyYesMainRoleNoSupplyAfterOrEqual2Month(
        activationDate,
        mainRole
      ) ||
      this.YesPrivacyYesMainRoleYesSupplyAfterOrEqual4Month(
        activationDate,
        mainRole
      )
    )
  }

  offsetMonthCalculateDate(dateToCalculate, operator, offset) {
    if (validDate(dateToCalculate)) {
      let date = new Date(dateToCalculate)
      date = date.setMonth(date.getMonth() + offset)
      switch (operator) {
        case '>=':
          return new Date().getTime() >= date
        case '<':
          return new Date().getTime() < date
      }
    }
  }

  CheckMainClient(mainRole) {
    return mainRole === '1'
  }

  NoPrivacy() {
    return (
      !this.clientDetail.profilingConsentFlag ||
      !this.clientDetail.marketingConsentFlag
    )
  }

  YesPrivacy() {
    return (
      !!this.clientDetail.profilingConsentFlag &&
      this.clientDetail.profilingConsentFlag
    )
  }

  NoSupply() {
    return (
      this.supplyDetail.typeRequest !== 'NewConnection' &&
      this.supplyDetail.typeRequest !== 'Reactivation'
    )
  }

  YesSupply() {
    return (
      this.supplyDetail.typeRequest === 'NewConnection' ||
      this.supplyDetail.typeRequest === 'Reactivation'
    )
  }

  consentsNotGranted(activationDate) {
    return (
      this.NoPrivacyNoSupplyAfterOrEqual2Month(activationDate) ||
      this.NoPrivacyNoSupplyBefore2Month(activationDate) ||
      this.NoPrivacyYesSupplyAfterOrEqual4Month(activationDate) ||
      this.NoPrivacyYesSupplyBefore4Month(activationDate)
    )
  }

  tooEarly(activationDate, mainRole) {
    return (
      this.YesPrivacyYesMainRoleNoSupplyBefore2Month(
        activationDate,
        mainRole
      ) ||
      this.YesPrivacyYesMainRoleYesSupplyBefore4Month(activationDate, mainRole)
    )
  }
}
