<template>
  <div class="page mb-15">
    <TcVars />
    <CustomDialog
      :showImage="true"
      :customDialogOpen.sync="dialog.show"
      :body="dialog.text"
      title="Dove trovo i numeri da inserire"
      :image="dialog.img"
      @close="dialog.show = false"
    />
    <div class="std-side-padding">
      <div class="HeadingXXL mb-10">
        Autolettura {{ details.serviceType === 'Gas' ? 'Gas' : 'Luce' }}
      </div>
    </div>

    <div class="mb-12">
      <div>
        <div class="HeadingL">Ultima lettura disponibile</div>
        <div class="d-flex mt-2">
          <div class="BodyXL d-flex">
            {{ lastReading.descrizioneFonte }}
            <div class="ml-2" v-if="validDate(lastReading.dataLettura)">
              - {{ $filters.formatDateWithSeparator(lastReading.dataLettura) }}
            </div>
          </div>
          <template v-if="details.serviceType === 'Ele'">
            <div class="d-flex">
              <div class="BodyXL d-flex ml-12">
                F1:
                <div class="BodyXL-Strong ml-1">{{ lastReading.lettura1 }}</div>
              </div>
              <div
                v-if="this.details.billingType !== 'ELE_MONO'"
                class="BodyXL d-flex ml-9"
              >
                F2:
                <div class="BodyXL-Strong ml-1">{{ lastReading.lettura2 }}</div>
              </div>
              <div
                v-if="this.details.billingType !== 'ELE_MONO'"
                class="BodyXL d-flex ml-9"
              >
                F3:
                <div class="BodyXL-Strong ml-1">{{ lastReading.lettura3 }}</div>
              </div>
            </div>
          </template>
          <template v-if="details.serviceType === 'Gas'">
            <div class="BodyXL d-flex ml-12">
              Gas:
              <div class="BodyXL-Strong ml-1">{{ lastReading.lettura1 }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="std-side-padding" no-gutters>
      <div class="mb-2">
        <div class="HeadingL">Inserisci i numeri</div>
      </div>
    </div>

    <template v-if="details.serviceType === 'Ele'">
      <div class="mt-6">
        <div class="d-flex">
          <div class="mr-8 campoTesto">
            <div class="LabelM">F1</div>
            <v-text-field
              type="number"
              placeholder="Inserisci numero"
              class="auth__input"
              v-model.trim="readings.f1"
              @blur="v$.readings.f1.$touch()"
              @input="updateF1"
              :error-messages="errorsF1"
            ></v-text-field>
          </div>
          <div
            v-if="this.details.billingType !== 'ELE_MONO'"
            class="mr-8 campoTesto"
          >
            <div class="LabelM">F2</div>
            <v-text-field
              type="number"
              placeholder="Inserisci numero"
              class="auth__input"
              v-model.trim="readings.f2"
              @blur="v$.readings.f2.$touch()"
              :error-messages="errorsF2"
            ></v-text-field>
          </div>
          <div
            class="campoTesto"
            v-if="this.details.billingType !== 'ELE_MONO'"
          >
            <div class="LabelM">F3</div>
            <v-text-field
              type="number"
              placeholder="Inserisci numero"
              class="auth__input"
              v-model.trim="readings.f3"
              @blur="v$.readings.f3.$touch()"
              :error-messages="errorsF3"
            ></v-text-field>
          </div>
        </div>
        <div
          @click="populateDialog()"
          class="ButtonL gasSection__form__question mt-4 mb-3"
        >
          Dove trovo i numeri da inserire?
        </div>
        <MYSButton
          buttonClass="mt-6 primaryButton"
          @buttonClick="sendReadings"
          :loading="sending"
          :disabled="
            this.details.billingType === 'ELE_MONO'
              ? v$.readingMonoLight.$invalid
              : v$.readings.$invalid
          "
          buttonText="Invia autolettura"
        />
      </div>
    </template>

    <template v-if="details.serviceType === 'Gas'">
      <div class="gasSection">
        <div class="BodyL mb-10">
          Inserisci i numeri che precedono la virgola. In alternativa, scatta
          una fotografia al tuo contatore ed inviacela tramite App MySorgenia.
        </div>
        <div class="gasSection__form">
          <div class="LabelM">CONSUMO</div>
          <div class="gasSection__textSection">
            <v-text-field
              type="number"
              v-model.trim="readingsGas.f1"
              placeholder="Inserisci il consumo"
              @blur="v$.readingsGas.f1.$touch()"
              :error-messages="errorsF1"
            ></v-text-field>
            <div class="gasSection__textSection__info">
              <img src="@/assets/icons/ic-info-gray.svg" />
              <div class="ml-1 BodyM">
                Inserisci i numeri che precedono la virgola
              </div>
            </div>
          </div>
          <div
            @click="populateDialog()"
            class="ButtonL gasSection__form__question mt-8"
          >
            Dove trovo i numeri da inserire?
          </div>
          <div>
            <MYSButton
              buttonClass="mt-6 primaryButton"
              @buttonClick="sendReadings"
              :loading="sending"
              :disabled="v$.readingsGas.$invalid"
              buttonText="Invia autolettura"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { getRequiredValidationError, validDate } from '@/js/utils'
import { required } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import { mapMutations } from 'vuex/dist/vuex.esm.browser'
import CustomDialog from '../../../../components/upselling/gas&light/CustomDialog.vue'
import MYSButton from '@/components/global/MYSButton.vue'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['details', 'lastReading', 'isAcea', 'enteredReadings'],
  name: 'selfReadingLight_Base',
  data() {
    return {
      sending: false,
      readings: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : '',
        f2: this.enteredReadings ? this.enteredReadings.f2 : '',
        f3: this.enteredReadings ? this.enteredReadings.f3 : ''
      },
      readingsGas: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : ''
      },
      readingMonoLight: {
        f1: this.enteredReadings ? this.enteredReadings.f1 : '',
        f2: '0',
        f3: '0'
      },
      validDate: validDate,
      getSupplyTrackingParams,
      dialog: {
        show: false,
        text: '',
        img: 'contatoreluce-web'
      }
    }
  },
  components: {
    TcVars,
    CustomDialog,
    MYSButton
  },
  computed: {
    errorsF1() {
      return getRequiredValidationError(this.v$.readings, 'f1')
    },
    errorsF2() {
      return getRequiredValidationError(this.v$.readings, 'f2')
    },
    errorsF3() {
      return getRequiredValidationError(this.v$.readings, 'f3')
    }
  },
  methods: {
    ...mapActions('supply', ['getDetail', 'selfReading']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('alerts', ['resetState']),
    sendReadings() {
      this.trackTapEvent({
        name: 'selfReading_send_tap',
        params: getSupplyTrackingParams(this.details)
      })
      this.$emit(
        'send-values',
        this.details.serviceType === 'Ele'
          ? this.details.billingType === 'ELE_MONO'
            ? this.readingMonoLight
            : this.readings
          : this.readingsGas
      )
      this.resetState()
      this.sending = true
    },
    populateDialog() {
      let text = ''
      if (this.details.serviceType === 'Ele') {
        text =
          '<strong>Premi il pulsante</strong> sul tuo contatore luce per visualizzare i numeri relativi ai consumi nelle fasce <strong>F1</strong>, <strong>F2</strong> ed <strong>F3</strong>. Inserisci i numeri nei rispettivi campi e premi invia per comunicare la lettura.'
        if (this.details.billingType === 'ELE_MONO') {
          text =
            '<strong>Premi il pulsante</strong> sul tuo contatore luce per visualizzare i numeri relativi ai consumi nella fascia <strong>F1</strong>. Inserisci i numeri nel rispettivo campo e premi invia per comunicare la lettura.'
        }
        if (this.isAcea) {
          text =
            '<strong>Premi il pulsante</strong> sul tuo contatore luce per visualizzare i numeri relativi ai consumi nelle fasce <strong>F1</strong>, <strong>F2</strong>, <strong>F3</strong> ed <strong>F4</strong>. Ricordati di <strong>sommare</strong> le fasce <strong>F3</strong> ed <strong>F4</strong> ed inserirle nel campo <strong>F3</strong>. Inserisci i numeri nei rispettivi campi e premi invia per comunicare la lettura.'
        }
      } else {
        text =
          'Visualizza sullo schermo del tuo contatore gas i <strong>numeri relativi ai consumi</strong>. Inserisci solo le cifre che si trovano <strong>prima della virgola</strong> (caselle a sfondo nero) e premi invia per comunicare la lettura.'
      }
      this.dialog = {
        show: true,
        text: text,
        img:
          this.details.serviceType === 'Ele'
            ? 'contatoreluce-web'
            : 'contatoregas-web'
      }
    },
    updateF1(value) {
      this.readings.f1 = value
      this.readingMonoLight.f1 = value
    }
  },
  validations: {
    readings: {
      f1: { required },
      f2: { required },
      f3: { required }
    },
    readingsGas: {
      f1: { required }
    },
    readingMonoLight: {
      f1: { required }
    }
  }
}
</script>
<style lang="scss" scoped>
.infoPhoto {
  font-size: 20px;
}
.v-dialog__content {
  .self-dialog {
    background: white;
    padding: 60px 43px 32px 48px;
    border-radius: 40px;
    position: relative;
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    h4 {
      div {
        font-weight: bold;
      }
    }
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
      &.contatore {
        margin-top: 24px;
        position: initial;
      }
    }
  }
  .self-reading-dialog {
    p {
      font-size: 20px;
      color: black;
    }

    .dialog__img {
      cursor: pointer;
      margin-top: 20px;
      margin-right: 20px;
    }
  }
}
.campoTesto {
  width: 263px;
}
.gasSection {
  width: 710px;
  display: flex;
  flex-direction: column;
  &__form {
    width: 311px;
    &__question {
      width: fit-content;
      color: rgba(18, 37, 106, 1);
      cursor: pointer;
    }
  }
  &__textSection {
    margin-top: 0px;
    padding-top: 0px;
    &__info {
      display: flex;
      align-items: center;
    }
  }
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.LabelM {
  color: rgba(110, 118, 137, 1);
}
</style>
<style lang="scss">
.v-input__slot {
  margin-bottom: 0px;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  color: $srg-blue;
}
</style>
