<template>
  <div class="successSubscribed">
    <div :class="`successSubscribed__header ${commodityType}`">
      <div class="successSubscribed__title">
        Next Energy {{ commodityType === 'ele' ? 'Luce' : 'Gas' }}: digitale e
        vantaggioso
      </div>
    </div>
    <div class="std-side-padding topRadius pt-10">
      <div class="successSubscribed__content">
        <h2 class="mb-4">Sottoscrizione conclusa con successo</h2>
        <h3 class="mb-8">
          Grazie per aver scelto Next Energy per la fornitura della tua casa.
        </h3>
        <div class="successSubscribed__box">
          <h5 class="bold">
            Data prevista di attivazione: {{ supplyDetail.dac }}
          </h5>
        </div>
        <div class="successSubscribed__title-small">Cosa Faremo noi?</div>
        <div class="desc">Ti invieremo tutti i documenti via email.</div>
        <div class="successSubscribed__title-small">Cosa Farai tu?</div>
        <div class="desc box">
          Puoi controllare l'avanzamento del processo di attivazione nella
          sezione dedicata alle utenze che puoi raggiungere anche dall'App
          MySorgenia.
        </div>
        <MYSButton
          width="262"
          buttonText="Torna all'homepage"
          buttonClass="primaryButton mb-10"
          @buttonClick="goHome"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MYSButton from '../../../../components/global/MYSButton.vue'
export default {
  name: 'successSubscribed',
  components: {
    MYSButton
  },
  computed: {
    ...mapGetters('offers', ['upsellingInfo']),
    ...mapGetters('upsellingComm', ['commodityType', 'supplyDetail', 'client'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingComm', ['resetUpsellingComm', 'setCurrentStep']),
    goHome() {
      this.trackTapEvent({
        name: 'upsellingcom_thankyoupage_back',
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.client.clientOwnerCode || ''
        }
      })
      this.resetUpsellingComm()
      this.$router.push('home')
    }
  },
  beforeMount() {
    this.setCurrentStep({ title: 'Sottoscrizione ricevuta' })
  }
}
</script>
<style lang="scss" scoped>
.successSubscribed {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  .bold {
    font-weight: 500;
  }
  &__header {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: cover;
    &.ele {
      background-image: url('../../../../assets/img-promo-luce-2022.png');
    }
    &.gas {
      background-image: url('../../../../assets/img-promo-gas-2022.png');
    }
  }
  &__title {
    font-size: 40px;
    font-family: 'Roboto';
    width: 85%;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 7%;
    padding-left: 51%;
    line-height: normal;
  }
  &__title-small {
    font-weight: 500;
    color: black;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .topRadius {
    margin-top: -30px !important;
    background: white;
  }
  &__content {
    width: 652px;
    h2,
    h3 {
      font-weight: 500;
    }
  }
  &__box {
    width: 652px;
    height: 66px;
    margin: 24px 0;
    padding: 0 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
      margin-right: 16px;
      content: url('../../../../assets/ic-calendar-blue.svg');
      width: 16px;
    }
  }
  .desc {
    font-size: 20px;
    line-height: 1.3;
    color: black;
    margin-bottom: 48px;
    &.box {
      font-weight: 500;
      padding: 24px;
      border-radius: 16px;
      background-color: #ccd0e1;
    }
    &.alert {
      background-color: #ffd9b7;
    }
  }
}
</style>
