<template>
  <page
    title="Variazione potenza"
    page-name="Variazione potenza"
    :disabled="disabled"
    idContextualHelp="help-modify-power"
  >
    <TcVars />
    <power-change :data.sync="data" :disabled="disabled" @submit="request" />
  </page>
</template>

<script>
import PowerChange from '@/components/actions/PowerChange'
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions, mapGetters } from 'vuex'
import Template from './Template'

export default {
  name: 'PowerAction',
  props: {},
  components: {
    page: Template,
    PowerChange,
    TcVars
  },
  data: () => ({
    details: null,
    data: null,
    loading: false
  }),
  computed: {
    ...mapGetters('account', ['clientDetails']),
    disabled() {
      return !this.data
    }
  },
  methods: {
    request() {
      this.loading = true
      const body = {
        cognome: this.clientDetails.surname,
        email: this.clientDetails.contactMail,
        nome: this.clientDetails.name,
        codiceCliente: this.clientDetails.clientCode,
        login: this.$store.state.session.username,
        puntoDiFornitura: this.$route.params.supplyCode,
        fieldsCommodity: {
          ...this.data,
          email: this.clientDetails.contactMail
        }
      }
      const type =
        this.data.commodity === 'aumentoPotenza' ? 'power1' : 'power2'
      this.createRequest(body)
        .then((res) => {
          this.trackTapEvent('supply_changeVariation_view')
          if (res.status === 'OK')
            this.$router.push({ name: 'promSuccess', params: { type } })
        })
        .catch((err) => {
          console.error('Error creating request', err)
        })
      this.loading = false
    },
    ...mapActions('support', ['createRequest']),
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>

<style lang="scss" scoped></style>
