<template>
  <div class="informative">
    <TcVars />
    <div class="informative__container-title">
      <h5 class="HeadingsTitleM">Informative</h5>
      <div class="informative__terms-item">
        <p class="BodyL">
          Accetto i
          <a
            :href="goToDocument('termsAndConditions')"
            class="ButtonM-Underline"
            target="_blank"
          >
            Termini e le Condizioni*</a
          >
          prendo visione dell'
          <a
            :href="goToDocument('informativePrivacyWeb')"
            class="ButtonM-Underline"
            target="_blank"
            >Informativa sulla Privacy*</a
          >
        </p>
        <v-switch
          inset
          class="dark-blue-switch"
          value
          input-value="true"
          disabled
        ></v-switch>
      </div>
      <div class="informative__terms-item">
        <p class="BodyL mt-4">
          Ti invitiamo a prendere visione di tutte le informative sul
          trattamento dei dati personali a
          <a
            :href="goToDocument('sorgeniaSiteDocuments')"
            class="ButtonM-Underline"
            target="_blank"
          >
            questo link.</a
          >
        </p>
      </div>
    </div>
    <div
      class="informative__container-title"
      v-if="
        (filteredClientDetails.clientOwnerCode &&
          !filteredClientDetails.clientCodeFV) ||
        (filteredClientDetails.clientOwnerCode &&
          filteredClientDetails.clientCodeFV)
      "
    >
      <h5 class="HeadingsTitleM">Consensi</h5>
      <div class="informative__consent-item mb-6">
        <div class="BodyL firstConsens">
          Consenso per
          <a
            :href="goToDocument('informativePrivacyWeb')"
            class="ButtonM-Underline"
            target="_blank"
          >
            ricevere da Sorgenia offerte vantaggiose, informazioni sulle sue
            iniziative e far sentire la tua voce tramite brevi questionari</a
          >
          <span> [art. 2 lett. e]</span>
        </div>
        <v-switch
          v-model="checkMarketing"
          :disabled="
            !filteredClientDetails.clientOwnerCode &&
            filteredClientDetails.clientCodeFV
          "
          inset
          class="dark-blue-switch"
        ></v-switch>
      </div>
      <div class="informative__consent-item mb-6">
        <div class="BodyL">
          Consenso per
          <a
            :href="goToDocument('informativePrivacyWeb')"
            class="ButtonM-Underline"
            target="_blank"
            >analizzare le tue abitudini di consumo al fine di offrirti prodotti
            e servizi personalizzati</a
          >
          [art. 2 lett. f]
        </div>
        <v-switch
          v-model="checkConsumption"
          inset
          :disabled="
            !filteredClientDetails.clientOwnerCode &&
            filteredClientDetails.clientCodeFV
          "
          class="dark-blue-switch"
        ></v-switch>
      </div>
    </div>
    <div
      class="informative__container-button"
      v-if="filteredClientDetails.clientOwnerCode"
    >
      <MYSButton
        button-class="primaryButton"
        @buttonClick="updateProfile()"
        :loading="sendingEdit"
        button-text="Aggiorna"
      />
      <MYSButton
        button-class="primaryInvertedButtonBorded ml-6"
        @buttonClick="cancelUpdate()"
        button-text="Annulla"
      />
    </div>
  </div>
</template>
<script>
import TcVars from '@/components/tcTracker/TcVars'
import { AlertService } from '@/js/service/alertService'
import { mapActions, mapGetters } from 'vuex'
import { ENV_CONFIGS } from '../../js/configs'
import MYSButton from '../../components/global/MYSButton.vue'

export default {
  name: 'informativeProfile',
  data() {
    return {
      checkTermsPrivacy: '',
      checkMarketing: '',
      checkConsumption: '',
      sendingEdit: false
    }
  },
  components: {
    TcVars,
    MYSButton
  },
  created() {
    this.init()
  },
  watch: {
    userProfile() {
      this.init()
    }
  },
  computed: {
    ...mapGetters('profile', ['userProfile']),
    ...mapGetters('account', ['clientDetails', 'filteredClientDetails'])
  },
  methods: {
    ...mapActions('account', ['getDetailForClient', 'editcontent']),
    init() {
      this.getDetailForClient(this.userProfile).then((res) => {
        this.checkMarketing = res.marketingConsentFlag
        this.checkConsumption = res.profilingConsentFlag
      })
    },
    cancelUpdate() {
      this.checkMarketing = this.clientDetails.marketingConsentFlag
      this.checkConsumption = this.clientDetails.profilingConsentFlag
    },
    async updateProfile() {
      this.sendingEdit = true
      let params = {
        codiceCliente: this.clientDetails.clientCode,
        street: this.clientDetails.residentialAddress,
        zipCode: this.clientDetails.residentialCAP,
        city: this.clientDetails.residentialCity,
        provincial: this.clientDetails.residentialProvince,
        nation: this.clientDetails.residentialNation,
        phone: this.clientDetails.phone,
        mobilePhone: this.clientDetails.mobilePhone,
        email: this.clientDetails.contactMail,
        marketDenial: !this.checkMarketing,
        profilazione: !this.checkConsumption,
        channel: 'Web'
      }
      await this.editcontent(params)
        .then(() => {
          this.sendingEdit = false
        })
        .catch(() => {
          this.sendingEdit = false
          AlertService(
            'Servizio non disponibile',
            'Al momento non è possibile salvare le modifiche al profilo. Ti invitiamo a riprovare.',
            false,
            'Chiudi',
            '#'
          )
        })
    },
    goToDocument(id) {
      switch (id) {
        case 'termsAndConditions':
          return ENV_CONFIGS.DYNAMIC_LINKS.termsAndConditions
        case 'informativePrivacyWeb':
          return ENV_CONFIGS.DYNAMIC_LINKS.informativePrivacyWeb
        case 'sorgeniaSiteDocuments':
          return ENV_CONFIGS.DYNAMIC_LINKS.sorgeniaSiteDocuments
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.firstConsens {
  width: 88%;
}
.informative {
  max-width: 815px;
  margin-bottom: 130px;
  &__container-title {
    margin-bottom: 44px;
    .HeadingsTitleM {
      margin-bottom: 30px;
    }
  }

  a {
    text-decoration: underline;
  }
  &__container-button {
    .button-primary {
      background: #12256a;
      border-radius: 20px;
      padding: 14.5px 48px;
      font-weight: bold;
      color: white;
      letter-spacing: 0.8px;
    }
    .button-seconday {
      margin-left: 20px;
      background: #ffffff;
      padding: 14.5px 48px;
      font-weight: bold;
      border: 1px solid #dfe4ef;
      letter-spacing: 0.8px;
      border-radius: 20px;
    }
  }

  &__consent-item,
  &__terms-item {
    display: flex;
    justify-content: space-between;

    p {
      max-width: 80%;
      margin-bottom: 0;
    }
    .v-input--selection-controls {
      margin-top: 0;
    }
  }
}
</style>
