var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"informative"},[_c('TcVars'),_c('div',{staticClass:"informative__container-title"},[_c('h5',{staticClass:"HeadingsTitleM"},[_vm._v("Informative")]),_c('div',{staticClass:"informative__terms-item"},[_c('p',{staticClass:"BodyL"},[_vm._v(" Accetto i "),_c('a',{staticClass:"ButtonM-Underline",attrs:{"href":_vm.goToDocument('termsAndConditions'),"target":"_blank"}},[_vm._v(" Termini e le Condizioni*")]),_vm._v(" prendo visione dell' "),_c('a',{staticClass:"ButtonM-Underline",attrs:{"href":_vm.goToDocument('informativePrivacyWeb'),"target":"_blank"}},[_vm._v("Informativa sulla Privacy*")])]),_c('v-switch',{staticClass:"dark-blue-switch",attrs:{"inset":"","value":"","input-value":"true","disabled":""}})],1),_c('div',{staticClass:"informative__terms-item"},[_c('p',{staticClass:"BodyL mt-4"},[_vm._v(" Ti invitiamo a prendere visione di tutte le informative sul trattamento dei dati personali a "),_c('a',{staticClass:"ButtonM-Underline",attrs:{"href":_vm.goToDocument('sorgeniaSiteDocuments'),"target":"_blank"}},[_vm._v(" questo link.")])])])]),(
      (_vm.filteredClientDetails.clientOwnerCode &&
        !_vm.filteredClientDetails.clientCodeFV) ||
      (_vm.filteredClientDetails.clientOwnerCode &&
        _vm.filteredClientDetails.clientCodeFV)
    )?_c('div',{staticClass:"informative__container-title"},[_c('h5',{staticClass:"HeadingsTitleM"},[_vm._v("Consensi")]),_c('div',{staticClass:"informative__consent-item mb-6"},[_c('div',{staticClass:"BodyL firstConsens"},[_vm._v(" Consenso per "),_c('a',{staticClass:"ButtonM-Underline",attrs:{"href":_vm.goToDocument('informativePrivacyWeb'),"target":"_blank"}},[_vm._v(" ricevere da Sorgenia offerte vantaggiose, informazioni sulle sue iniziative e far sentire la tua voce tramite brevi questionari")]),_c('span',[_vm._v(" [art. 2 lett. e]")])]),_c('v-switch',{staticClass:"dark-blue-switch",attrs:{"disabled":!_vm.filteredClientDetails.clientOwnerCode &&
          _vm.filteredClientDetails.clientCodeFV,"inset":""},model:{value:(_vm.checkMarketing),callback:function ($$v) {_vm.checkMarketing=$$v},expression:"checkMarketing"}})],1),_c('div',{staticClass:"informative__consent-item mb-6"},[_c('div',{staticClass:"BodyL"},[_vm._v(" Consenso per "),_c('a',{staticClass:"ButtonM-Underline",attrs:{"href":_vm.goToDocument('informativePrivacyWeb'),"target":"_blank"}},[_vm._v("analizzare le tue abitudini di consumo al fine di offrirti prodotti e servizi personalizzati")]),_vm._v(" [art. 2 lett. f] ")]),_c('v-switch',{staticClass:"dark-blue-switch",attrs:{"inset":"","disabled":!_vm.filteredClientDetails.clientOwnerCode &&
          _vm.filteredClientDetails.clientCodeFV},model:{value:(_vm.checkConsumption),callback:function ($$v) {_vm.checkConsumption=$$v},expression:"checkConsumption"}})],1)]):_vm._e(),(_vm.filteredClientDetails.clientOwnerCode)?_c('div',{staticClass:"informative__container-button"},[_c('MYSButton',{attrs:{"button-class":"primaryButton","loading":_vm.sendingEdit,"button-text":"Aggiorna"},on:{"buttonClick":function($event){return _vm.updateProfile()}}}),_c('MYSButton',{attrs:{"button-class":"primaryInvertedButtonBorded ml-6","button-text":"Annulla"},on:{"buttonClick":function($event){return _vm.cancelUpdate()}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }