<template>
  <div class="page">
    <div class="mt-8">
      <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
        <template v-slot:divider>
          <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
        </template>
      </v-breadcrumbs>
    </div>
    <PaymentMethod
      v-if="selectedClient"
      :client="selectedClient"
      :cancelOption="cancelOption"
      :paymentOptionData.sync="data"
      @openConfirmDialog="choosePaymentDialog"
      @resetOption="cancelOption = false"
      @addOption="optionDialog"
    />
    <ConfirmationDialog
      :confirmationDialogOpen.sync="confirmDialog"
      :titleModal="confirmDialogTitle"
      :descriptionModal="confirmDialogDescription"
      :cta1="confirmDialogCta1"
      :cta2="confirmDialogCta2"
      @cta1Click="abortDialog"
      @cta2Click="submitOrNewOption"
    />
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PaymentMethod from './PaymentMethod'
import { capitalize } from '@/js/utils'
import ConfirmationDialog from '../../../components/global/ConfirmationDialog.vue'

export default {
  name: 'PaymentAction',
  props: {},
  components: {
    PaymentMethod,
    ConfirmationDialog
  },
  data: () => ({
    selectedClient: null,
    details: null,
    data: null,
    loading: false,
    paymentDialog: false,
    newOptionDialog: false,
    confirmDialog: false,
    confirmDialogTitle: '',
    confirmDialogDescription: '',
    confirmDialogCta1: '',
    confirmDialogCta2: '',
    cancelOption: false,
    address: ''
  }),
  beforeMount() {
    if (!this.$store.state.supply.supplyDetail) {
      this.$store
        .dispatch('supply/getDetail', {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
    this.getDetailForClient({
      clientOwnerCode: this.$route.params.clientOwnerCode
    }).then((accountDetails) => {
      this.getClientsForAccount().then((res) => {
        let account = Object.values(res)
        account = account.filter(
          (client) =>
            this.$route.params.clientOwnerCode === client.clientOwnerCode
        )[0]

        const clientWithDetails = {
          ...accountDetails,
          ...account
        }
        this.setClient(clientWithDetails)
        this.selectedClient = this.$store.getters['upsellingFiber/client']
      })
    })
  },

  computed: {
    ...mapGetters('account', ['clientDetails']),
    ...mapGetters('supply', ['supplyDetail']),

    disabled() {
      return !this.data
    },
    breadcrumbsItems() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let res
      res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: { name: 'ownerSupplies' },
          text: 'Servizi e Consumi',
          exact: true
        },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Metodo di pagamento'
        }
      ]

      return res
    },
    userString() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let user =
        this.supplyDetail[this.$route.params.supplyCode].serviceType === 'Ele'
          ? 'utenza Luce di ' + address
          : 'utenza Gas di ' + address
      return user
    }
  },
  methods: {
    tranformExpirationDateFormat(dateStr) {
      // format IN = MM/AAAA
      // format OUT =  AAMM
      return dateStr.split('/')[1].substring(2) + dateStr.split('/')[0]
    },
    submitOptionChange() {
      if (this.data) {
        let body
        if (this.data.creditCardCircuit) {
          body = {
            xaliasPan: this.data.aliasPan,
            bankIBAN: '',
            circuit:
              this.data.creditCardCircuit.toLowerCase() === 'visa'
                ? '01'
                : '02',
            creditCardCode: this.data.creditCardNumber.replace(/\*/g, ''),
            creditCardHolder: this.data.businessName, // creditCardOwner also have that field but its empty
            expireDateCC: this.tranformExpirationDateFormat(
              this.data.creditCardExpirationDate
            ),
            firstName: this.data.businessName
              ? this.data.businessName.split(' ')[0]
              : ' ',
            fiscalCodeCust: '',
            fiscalCodeHolder: '',
            interactionSubtypology: '',
            lastName: this.data.businessName
              ? this.data.businessName.split(' ')[1]
              : ' ',
            login: this.$store.state.session.username,
            mcsExternalId: this.clientDetails.clientCode,
            nameAlias: this.data.businessName,
            note: '',
            codicePunto: this.$route.params.supplyCode,
            signatureDate: '',
            swiftCode: '',
            tipology: '',
            crecurr: this.data.crecurr
          }
          this.loading = true
          this.addMdPCreditCard(body)
            .then(() => {
              this.loading = false
              this.$store.commit(
                'supply/resetSupplyDetailByCode',
                this.$route.params.supplyCode
              )
              this.resetState()
              this.$router.push({
                name: 'promSuccess',
                params: { type: 'payment' }
              })
            })
            .catch((error) => {
              GenErr.handleGenericError(error && error.message)
              GenErr.showErrorMessage(
                "Si è verificato un errore durante l'operazione."
              )
              this.loading = false
            })
        } else {
          body = {
            mcsExternalId: this.clientDetails.clientCode,
            codicePunto: this.$route.params.supplyCode,
            login: this.$store.state.session.username,
            bankIBAN: this.data.iban,
            nameAlias: this.data.businessName,
            fiscalCodeHolder: this.data.ibanSubscriberFiscalCode,
            firstName: this.data.ibanSubscriberFirstName,
            lastName: this.data.ibanSubscriberLastName,
            fiscalCodeCust: this.data.fiscalCodeVAT,
            codiceCliente: this.clientDetails.clientCode
          }
          this.loading = true
          this.ibanRequest(body)
            .then(() => {
              this.loading = false
              this.$store.commit(
                'supply/resetSupplyDetailByCode',
                this.$route.params.supplyCode
              )
              this.resetState()
              this.$router.push({
                name: 'promSuccess',
                params: { type: 'payment' }
              })
            })
            .catch((error) => {
              this.confirmDialog = false
              this.loading = false

              if (error.message === 'SAME_CASE_IN_PROGRESS') {
                GenErr.showErrorMessage(
                  "E' in corso un'operazione di modifica del metodo di pagamento per questa fornitura. Attenderne il completamento per richiedere nuove modifiche. Grazie!"
                )
              } else {
                GenErr.handleGenericError(error && error.message)
                GenErr.showErrorMessage(
                  "Si è verificato un errore durante l'operazione."
                )
              }
            })
        }
      }
    },
    ...mapActions('account', [
      'getMainClient',
      'getClientsForAccount',
      'getDetailForClient'
    ]),
    ...mapActions('payment', ['ibanRequest', 'addMdPCreditCard']),
    ...mapMutations('upsellingFiber', ['setClient']),
    ...mapMutations('supply', ['resetState']),
    optionDialog() {
      this.newOptionDialog = true
      this.confirmDialogTitle =
        'Vuoi registrare un nuovo metodo di pagamento per l’utenza?'
      this.confirmDialogDescription =
        'Il nuovo metodo di pagamento verrà associato all’<b>' +
        this.userString +
        '</b>. Vuoi proseguire?'
      this.confirmDialogCta1 = 'Annulla'
      this.confirmDialogCta2 = 'Si, prosegui'
      this.confirmDialog = true
    },
    choosePaymentDialog() {
      this.newOptionDialog = false
      this.confirmDialogTitle =
        'Stai modificando il metodo di pagamento per l’utenza'
      this.confirmDialogDescription =
        'Il metodo di pagamento scelto verrà associato all’<b>' +
        this.userString +
        '</b>. Vuoi proseguire?'
      this.confirmDialogCta1 = 'Annulla'
      this.confirmDialogCta2 = 'Si, conferma'
      this.confirmDialog = true
    },
    abortDialog() {
      this.confirmDialog = false
      this.newOptionDialog = false
      if (this.paymentDialog) {
        this.cancelOption = true
      }
      this.paymentDialog = false
    },
    submitOrNewOption() {
      if (this.newOptionDialog) {
        this.$router.push({ name: 'newPaymentMethod' })
      } else {
        this.submitOptionChange()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.option-dialog {
  min-height: 360px;
  h2 {
    font-size: 32px;
    font-weight: bold;
    line-height: 44px;
  }

  p {
    font-size: 20px;
    color: black;
  }
  .normal {
    font-weight: bold;
    letter-spacing: normal;
  }
}
</style>
