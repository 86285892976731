<template>
  <div class="link" @click="openFootPrint">
    <LayoutCard :registered="initiative.result !== ''" :loaded="loaded">
      <template v-slot:bkg>
        <div :class="`bkg ${initiative.result !== '' ? 'full' : ''}`" />
      </template>
      <template v-slot:text>
        <div class="text justify-end" v-if="initiative.result === ''">
          <div class="title">
            <div class="t-white HeadingL-Upper">MISURA IL TUO IMPATTO</div>
            <div class="t-green HeadingL-Upper">SUL PIANETA</div>
          </div>
        </div>
        <div class="text justify-space-between" v-else>
          <div class="title">
            <div class="t-white HeadingL-Upper">IL TUO IMPATTO</div>
          </div>
          <div class="d-flex align-center">
            <img src="../../../assets/icons/card/CFP.svg" width="40" />
            <div class="desc">
              <h5>{{ formatResult(initiative.result) }} Kg</h5>
              <div class="smallText">Emissioni di co2</div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:button>
        <div class="btn-card" v-if="!loaded" />
        <div class="btn-card" v-else-if="initiative.result === ''">
          <div class="btn-text">FAI IL TEST</div>
          <img src="../../../assets/icons/active.svg" width="24px" />
        </div>
        <div class="btn-card" v-else>
          <div class="btn-text">SUGGERIMENTI</div>
          <img src="../../../assets/icons/chevron_rx.svg" width="24px" />
        </div>
      </template>
    </LayoutCard>
  </div>
</template>
<script>
import LayoutCard from './LayoutCard'
import { eventBus } from '@/main'
import { mapActions } from 'vuex'
export default {
  name: 'greenersCard',
  props: {
    loaded: { type: Boolean, default: false },
    initiative: {
      type: Object,
      default() {
        return { result: '' }
      }
    }
  },
  components: {
    LayoutCard
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    openFootPrint() {
      this.trackTapEvent({ name: 'engagement_cfp_tap' })
      if (this.loaded) {
        eventBus.$emit('footprint:open')
        this.trackTapEvent('cfp_viewed')
      }
    },
    formatResult(result) {
      let value = result.split(' ')
      return Math.round(value[0])
    }
  }
}
</script>
<style lang="scss" scoped>
.bkg {
  background-image: url('../../../assets/new-home/cfp-med-opacity.png');
  background-size: cover;
  &.full {
    background-image: url('../../../assets/new-home/cfp-full-opacity.png');
  }
}
.link {
  cursor: pointer;
}
</style>
