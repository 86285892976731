<template>
  <div class="page">
    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="HeadingsTitleXXL">Parental Control</div>
      </v-col>
    </v-row>
    <v-row v-if="load">
      <v-col>
        <v-progress-circular :size="50" indeterminate color="#12256A" />
      </v-col>
    </v-row>
    <StatusHeader
      v-if="!load && parentalConstrolStatus !== 'UNKNOWN'"
      :messageStatus="messageStatus"
      :requestMessage="requestMessage"
      :statusColor="statusColor"
      :isDisabled="isDisabled"
      @sendParentalControl="sendParentalControl"
    />
    <StatusUnknown v-if="!load && parentalConstrolStatus === 'UNKNOWN'" />
    <InfoParentalControl v-if="parentalConstrolStatus !== 'UNKNOWN'" />
    <FaqParentalControl />
    <v-dialog
      v-model="openKoDialog"
      persistent
      max-width="558"
      overlay-opacity="0.6"
      content-class="customDialog"
    >
      <KoDialog @setOpenKoDialog="setOpenKoDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { capitalize } from '@/js/utils'

import StatusHeader from '../../components/parentalControl/StatusHeader.vue'
import InfoParentalControl from '../../components/parentalControl/InfoParentalControl.vue'
import FaqParentalControl from '../../components/parentalControl/FaqParentalControl.vue'
import StatusUnknown from '../../components/parentalControl/StatusUnknown.vue'
import KoDialog from '../../components/parentalControl/KoDialog.vue'
import router from '../../router/router'

export default {
  name: 'ParentalControl',
  components: {
    StatusHeader,
    InfoParentalControl,
    FaqParentalControl,
    StatusUnknown,
    KoDialog
  },
  data() {
    return {
      load: false,
      openKoDialog: false
    }
  },
  mounted() {
    this.dettaglioFornitura()
  },
  computed: {
    ...mapGetters('supply', [
      'supplyDetail',
      'getParentalControlStatus',
      'getCheckParentalControlDeeplink'
    ]),
    ...mapGetters('account', ['clientDetails', 'accountClients']),
    breadcrumbsItems() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        { to: { name: 'parentalControlAction' }, text: 'Parental Control' }
      ]
      return res
    },
    messageStatus() {
      return this.parentalConstrolStatus === 'ACTIVE'
        ? 'ATTIVO'
        : this.parentalConstrolStatus === 'INACTIVE'
        ? 'NON ATTIVO'
        : this.parentalConstrolStatus === 'ACTIVATION_PENDING'
        ? 'ATTIVAZIONE IN ELABORAZIONE'
        : this.parentalConstrolStatus === 'DEACTIVATION_PENDING'
        ? 'DISATTIVAZIONE IN ELABORAZIONE'
        : this.setParentalControlStatus('UNKNOWN')
    },
    statusColor() {
      return this.parentalConstrolStatus === 'ACTIVE'
        ? 'activeStatusColor'
        : this.parentalConstrolStatus === 'INACTIVE'
        ? 'noActiveStatusColor'
        : 'pendingStatusColor'
    },
    requestMessage() {
      return this.parentalConstrolStatus === 'ACTIVE'
        ? 'Richiedi disattivazione'
        : this.parentalConstrolStatus === 'INACTIVE'
        ? 'Richiedi attivazione'
        : this.parentalConstrolStatus === 'DEACTIVATION_PENDING'
        ? 'Richiedi disattivazione'
        : 'Richiedi attivazione'
    },
    isDisabled() {
      return (
        this.parentalConstrolStatus === 'ACTIVATION_PENDING' ||
        this.parentalConstrolStatus === 'DEACTIVATION_PENDING'
      )
    },
    parentalConstrolStatus() {
      return this.getParentalControlStatus
    }
  },
  methods: {
    ...mapActions('fiber', [
      'sendParentalControlRequest',
      'sendParentalControlRequestMock'
    ]),
    ...mapActions('account', ['retrieveMessageCenter', 'getNumberMessage']),
    ...mapActions('supply', ['dettaglioFornituraV2']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('supply', ['setParentalControlStatus']),
    async sendParentalControl() {
      this.load = true
      let body = {
        clientCode:
          this.supplyDetail[this.$route.params.supplyCode].clientOwnerCode,
        prCode: this.$route.params.supplyCode, //Codice punto di fornitura fibra
        requestTypology:
          this.parentalConstrolStatus === 'ACTIVE'
            ? 'DEACTIVATION'
            : 'ACTIVATION' // Tipo di richiesta. Valori possibili: ACTIVATION, DEACTIVATION.
      }
      const res = await this.sendParentalControlRequest(body)
      if (res.data.status === 'OK') {
        this.trackTapEvent({
          name: 'parental_control_set',
          params: {
            request_type: body.requestTypology.toLowerCase()
          }
        })
        let data = Object.keys(this.accountClients) //con data vuoto mi ritorna la mock response per i messaggi
        setTimeout(() => {
          this.retrieveMessageCenter(data)
          this.getNumberMessage(data)
          // this.dettaglioFornitura()
          if (body.requestTypology === 'DEACTIVATION') {
            this.setParentalControlStatus('DEACTIVATION_PENDING')
          } else {
            this.setParentalControlStatus('ACTIVATION_PENDING')
          }
          this.load = false
        }, 5000)
      } else {
        if (res.data.status === 'KO') {
          this.setOpenKoDialog()
          this.trackTapEvent('parental_control_set_error')
          this.load = false
        }
      }
    },

    async dettaglioFornitura() {
      this.load = true
      try {
        await this.dettaglioFornituraV2({
          supplyCode: this.$route.params.supplyCode,
          clientOwnerCode: this.$route.params.clientOwnerCode
        })
        this.trackTapEvent({
          name: 'parental_control_viewed',
          params: {
            status: this.parentalConstrolStatus
          }
        })
      } catch (error) {
        if (error.message === 'USER_NOT_AUTHORIZED') {
          router.push({ name: 'home' })
        }
        this.trackTapEvent('parental_control_viewed_error')
      }

      this.load = false
    },
    setOpenKoDialog() {
      this.openKoDialog = !this.openKoDialog
    }
  }
}
</script>
