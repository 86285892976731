<template>
  <div class="check">
    <h4 class="mb-2">Informazioni e consensi</h4>
    <h5 class="mb-6">Leggi e accetta tutti i consensi per poter proseguire</h5>

    <h5
      @click="setCheckBox('contractCondition')"
      :class="`checkbox ${contractCondition ? 'selected' : ''}`"
    >
      <div class="BodyL">
        Accetto le
        <span
          @click.stop="openPdf('contract-conditions')"
          class="BodyL-Underline underline brand-blu"
          >Condizioni di contratto*</span
        >
        <span class="BobyL"> e ho preso visione dell'</span>
        <span
          @click.stop="openPdf('infoPrivacy')"
          class="BodyL-Underline underline brand-blu"
          >Informativa generale clienti*</span
        >
        <span class="BobyL"> e </span>
        <span
          @click.stop="openPdf('infoSIC')"
          class="BodyL-Underline underline brand-blu"
          >Informativa SIC*</span
        >
      </div>
    </h5>
    <h5
      @click="setCheckBox('clauses')"
      :class="`checkbox ${clauses ? 'selected' : ''}`"
    >
      <div class="BodyL">
        Ho preso visione e accetto le
        <span
          @click.stop="openPdf('clauses')"
          class="BodyL-Underline underline brand-blu"
          >Clausole di sottoscrizione specifica*</span
        >
      </div>
    </h5>
    <h5
      @click="setAllcheckBox"
      class="BodyL-Strong"
      :class="`checkbox allConsent d-flex ${accept ? 'selected' : ''}`"
    >
      Accetto tutti i consensi
    </h5>
    <v-btn
      color="primary"
      height="54"
      width="260"
      class="v-btn--shadowed"
      :disabled="!contractCondition || !clauses"
      @click="addInfoConsent"
      >Continua</v-btn
    >
    <pdf-viewer
      :showDialog="showPdf"
      :url="pdfDataUrl"
      @closing-pdfViewer="showPdf = false"
      height="600px"
    />
    <div class="contentLoader" v-if="loader">
      <v-progress-circular
        class="loader"
        :size="100"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import GenErr from '@/js/genericErrors'
import { mapActions, mapGetters } from 'vuex'
import { ENV_CONFIGS } from '@/js/configs'
export default {
  name: 'checkComponent',
  data() {
    return {
      accept: false,
      contractCondition: false,
      clauses: false,
      showPdf: false,
      pdfDataUrl: '',
      pdfDocs: {},
      loader: false
    }
  },
  //C00047575
  computed: {
    ...mapGetters('offers', ['changeProduct', 'getParamsChangeProduct']),
    ...mapGetters('supply', ['supplies']),
    supply() {
      return this.supplies.filter(
        (s) => s.supplyCode === this.getParamsChangeProduct.prCode
      )[0]
    }
  },
  mounted() {
    this.getDocUpsellingUnfairTerms().then(({ data }) => {
      this.pdfDocs.clauses = data.commodity
    })
  },
  methods: {
    ...mapActions('offers', ['submitChangeProduct']),
    ...mapActions('documents', [
      'getDocsByCategory',
      'getDocUpsellingUnfairTerms'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),

    openPdf(doc) {
      switch (doc) {
        case 'contract-conditions':
          this.pdfDataUrl = this.changeProduct.contractConditionDoc
          this.showPdf = true
          break
        case 'clauses':
          window.open(this.pdfDocs.clauses)
          break
        case 'infoPrivacy':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.generalClientsInformative)
          break
        case 'infoSIC':
          window.open(ENV_CONFIGS.DYNAMIC_LINKS.informativaSIC)
          break
      }
    },
    setCheckBox(checkbox) {
      this.$data[checkbox] = !this.$data[checkbox]
      if (this.contractCondition && this.clauses) {
        this.accept = true
      } else {
        this.accept = false
      }
    },
    setAllcheckBox() {
      this.accept = !this.accept
      if (this.accept) {
        this.contractCondition = true
        this.clauses = true
      } else {
        this.contractCondition = false
        this.clauses = false
      }
    },
    addInfoConsent() {
      this.$emit('nextStep')
      this.loader = true
      let body = {
        codiceCliente: this.getParamsChangeProduct.clientCode,
        codicePunto: this.getParamsChangeProduct.prCode,
        prodottoOfferto: this.changeProduct.offerData.productTitle,
        listinoOfferto: this.changeProduct.offerData.tariffList,
        codiceProposta: this.changeProduct.offerData.proposalCode,
        dataSottoscrizione: new Intl.DateTimeFormat('it', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        }).format(new Date())
      }
      this.submitChangeProduct(body)
        .then((resp) => {
          let params = {
            client_code: this.getParamsChangeProduct.clientCode,
            client_type: this.supply.clientOwnerType.toLowerCase(),
            pr_code: this.getParamsChangeProduct.prCode,
            supply_type: this.changeProduct.contractType
          }
          this.trackTapEvent({
            name: 'changeOffer_acceptConsents_tap',
            params: params
          })
          if (resp === 'OK') {
            this.$router.push({
              name: 'resultChangeOffer',
              params: {
                status: 'ok',
                clientOwnerCode: this.$route.params.clientOwnerCode
              }
            })
          }
          if (resp === 'alreadySigned') {
            this.$router.push({
              name: 'resultChangeOffer',
              params: {
                status: 'alreadySigned',
                clientOwnerCode: this.$route.params.clientOwnerCode
              }
            })
          }
          this.loader = false
        })
        .catch((error) => {
          this.loader = false
          GenErr.handleGenericError(error && error.message)
          GenErr.showErrorMessage(
            'Si è verificato un errore durante la fase di sottoscrizione. Ti preghiamo di verificare i dati e riprovare. Grazie.'
          )
        })
      return false
    }
  },
  components: {
    pdfViewer: PdfViewer
  }
}
</script>
<style lang="scss" scoped>
.check {
  max-width: 652px;
  h4 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 1px;
  }
  //h5 {
  //  color: black;
  //  line-height: 1.4;
  //  letter-spacing: 1px;
  //}
  .underline {
    text-decoration: underline;
  }
  .brand-blu {
    color: $brand-blue;
  }
  .checkbox {
    margin: 30px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    letter-spacing: 1.2px;
    &.selected {
      &:before {
        height: 24px;
        margin-right: 16px;
        content: url('../../../assets/icons/checkbox_active.svg');
      }
    }
    &:before {
      height: 24px;
      margin-right: 16px;
      content: url('../../../assets/icons/checkbox.svg');
    }
    &.allConsent {
      width: 340px;
      border-top: 1px solid #eaeef5;
      height: 80px;
      font-weight: bold;
      margin-bottom: 32px;
      line-height: 1.4;
      letter-spacing: 1px;
      color: black;
      text-decoration: none;
    }
  }
}
.contentLoader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.48);
  opacity: 0.6;
}
</style>
