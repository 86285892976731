<template>
  <div
    :class="{ header: !loginArea, 'header--old': loginArea }"
    v-if="!isMyShop && !isLogin"
  >
    <template v-if="!isUpselling">
      <div class="content d-flex align-center justify-space-between">
        <div class="logo pt-3 pl-3">
          <router-link :to="{ name: 'home' }" v-if="!loginArea">
            <img
              :src="`${require('../../assets/my-sorgenia-logo.png')}`"
              width="141"
              alt="my sorgenia"
            />
          </router-link>

          <img
            v-if="loginArea"
            :src="`${require('../../assets/my-sorgenia-logo.png')}`"
            width="198"
            alt="my sorgenia"
          />
        </div>
        <div class="main-menu">
          <MenuNew v-if="showMenu"></MenuNew>
        </div>
        <div
          class="message"
          @click="openMessageCenter"
          v-if="filteredClientDetails?.clientOwnerCode"
        >
          <div v-if="countNumberMessage > 0" class="msgCounter">
            {{ countNumberMessage }}
          </div>
        </div>
      </div>
      <v-btn
        width="56"
        height="56"
        class="chatFixed"
        color="#97D707"
        v-if="chatOpen && !mainClient?.isOnlyFV"
        fab
        depressed
        @click="showChatDialog()"
      >
        <v-icon size="32" color="#12256A">mdi-message-outline</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <div class="d-flex align-center justify-space-between pt-6">
        <div class="d-flex align-center cursor" @click="closeUpselling()">
          <img src="../../assets/ic_chevron_top_black.png" width="24" />
          <div class="close">Chiudi</div>
        </div>
        <div class="support-links" v-if="showSupportSection">
          <v-btn
            text
            depressed
            large
            color="primary"
            v-if="isAuthenticated"
            @click="goToSupport()"
          >
            ASSISTENZA
            <span v-if="hasNotifications" class="notify">{{
              totalNotifications
            }}</span>
          </v-btn>
        </div>
      </div>
      <v-btn
        width="56"
        height="56"
        class="chatFixed"
        color="#97D707"
        v-if="chatOpen && isChangeOffer"
        fab
        depressed
        @click="showChatDialog()"
      >
        <v-icon size="32" color="#12256A">mdi-message-outline</v-icon>
      </v-btn>
      <v-btn
        class="chatFixed"
        color="#97D707"
        v-if="chatOpenSales && showChatbotSales"
        fab
        depressed
        @click="
          showChatDialogSales(),
            trackTapEvent(buildTrackEventPoperties('chatsales'))
        "
      >
        <v-icon size="32" color="#12256A">mdi-message-outline</v-icon>
      </v-btn>
    </template>
    <v-dialog v-model="dialogFiberNew" max-width="652">
      <v-row>
        <v-col class="clients-dialog">
          <img
            @click="undoCloseUpselling()"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Attenzione</h2>
          <h4 v-if="isChangeOffer" class="mb-5">
            Stai per tornare alla Home e perderai tutti i dati della
            sottoscrizione. Vuoi continuare?
          </h4>
          <h4 v-else class="mb-5">
            Tornando alla Home perderai tutti i dati della sottoscrizione. Vuoi
            continuare?
          </h4>
          <div class="d-flex justify-center">
            <v-btn
              color="primary"
              :width="isChangeOffer ? 156 : 262"
              height="54"
              class="v-btn--shadowed"
              @click="confirmResetUpselling"
              >{{ isChangeOffer ? 'Sì, continua' : 'Conferma' }}
            </v-btn>
            <v-btn
              v-if="isChangeOffer"
              color="primary"
              text
              width="136"
              height="54"
              class="v-btn--shadowed ml-3"
              @click="dialogFiberNew = false"
              >Annulla
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog v-model="dialogMultiMainClient" max-width="652">
      <v-row>
        <v-col class="multiCLients-dialog">
          <img
            @click="dialogMultiMainClient = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <!-- <h2 v-if="loyaltyResToFix">OPS! L'accesso a questa sezione deve essere abilitato dal nostro Servizio
            Clienti</h2>
          <h2 v-else>Per iscrivere un utente business devi contattare il Servizio Clienti</h2> -->
          <h2>Per l’iscrizione contatta il Servizio Clienti</h2>
          <h4>
            Contattaci al numero <span>800294333</span> (da fisso) oppure allo
            <span>0244388001</span> (da mobile). Un nostro operatore ti
            assisterà per abilitare il servizio che sarà disponibile entro le
            successive 24h.
          </h4>
        </v-col>
      </v-row>
    </v-dialog>
    <v-dialog max-width="1140" v-model="dialogClients">
      <v-row>
        <v-col class="clients-dialog">
          <img
            @click="dialogClients = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Seleziona il profilo</h2>
          <h4>
            Per poterti aiutare al meglio, indica per quale profilo hai bisogno
            di supporto
          </h4>
          <v-radio-group row v-model="clientSelected">
            <div
              class="client"
              v-for="(client, i) in clientsForAccount"
              :key="i"
            >
              <v-radio
                :key="i"
                :value="client"
                color="#12256A"
                class="label-radio"
              >
                <template v-slot:label>
                  <div class="black--text">{{ client.businessName }}</div>
                </template>
              </v-radio>
            </div>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-dialog>
    <Chatbot
      v-if="!isUpselling || isChangeOffer"
      :showDialog="showChat"
      :url="chatUrl"
      :clientCode="fromClient && fromClient.clientOwnerCode"
      :role="fromClient && fromClient.mainRole"
      :process="chatProcess"
      @closing-chatbot="showChat = false"
      @reset-client-selected="resetClients()"
      :attachTo="'.header'"
      height="600px"
      content-class="chat-dialog"
    />
    <ChatbotSales
      v-if="showChatbotSales"
      :showDialog="showChatSales"
      :isCommodity="isCommodityFlow"
      :url="chatUrlSales"
      @closing-chatbot-sales="showChatSales = false"
      :attachTo="'.header'"
      height="600px"
      content-class="chat-dialog"
    />
    <AlertError
      :showAlert.sync="showAlertDialog"
      :type="typeError"
      :title="alertTite"
      :message="alertMessage"
    />
    <SelectClientDialog v-model="showSelectClientDialog" />
  </div>
</template>
<script>
import Chatbot from '@/components/chatbot/Chatbot.vue'
import ChatbotSales from '@/components/chatbot/ChatbotSales.vue'
import MenuNew from '@/components/menu/MenuNew.vue'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import { eventBus } from '@/main'
import router from '@/router/router'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import AlertError from '../error/AlertError.vue'
import SelectClientDialog from '../greeners/SelectClientDialog.vue'

import { ENV_CONFIGS } from '@/js/configs.js'

export default {
  name: 'headerNew',
  data() {
    return {
      alertTite: null,
      alertMessage: null,
      isUpselling: false,
      showChatbotSales: false,
      isCommodityFlow: false,
      isFiberFlow: false,
      isChangeOffer: false,
      showSupportSection: false,
      loginArea: false,
      showMenu: false,
      chatOpen: false,
      chatOpenSales: false,
      showChat: false,
      showChatSales: false,
      chatUrl: '',
      chatUrlSales: '',
      fromClient: '',
      chatProcess: '',
      clientSelected: null,
      clientsForAccount: [],
      dialogMultiMainClient: false,
      dialogClients: false,
      dialogFiberNew: false,
      isMyShop: false,
      isLogin: false,
      typeError: '',
      showAlertDialog: false,
      showSelectClientDialog: false
    }
  },
  computed: {
    ...mapGetters('account', [
      'numberMessage',
      'mainClient',
      'filteredClientDetails',
      'loyaltyResToFix'
    ]),
    hasNotifications() {
      return this.totalNotifications > 0
    },
    isAuthenticated() {
      return this.$store.getters['session/isAuthenticated']
    },
    isZeroMainClients() {
      return this.$store.getters['account/isZeroMainClients']
    },
    totalNotifications() {
      return this.$store.getters['account/totalNotifications']
    },
    countNumberMessage() {
      if (!this.numberMessage) return null
      if (!this.numberMessage.listaClienti) return null
      return this.numberMessage.listaClienti
        .reduce((acc, current) => {
          return acc + parseInt(current.numeroMessaggiDaLeggere)
        }, 0)
        .toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          useGrouping: false
        })
    }
  },
  methods: {
    ...mapActions('interactions', ['isChannelOpen']),
    ...mapActions('account', [
      'getMainClient',
      'getTotalNotifications',
      'getClientsForAccount'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingFiber', ['resetFiberState']),
    ...mapMutations('upsellingComm', ['resetUpsellingComm']),
    setupHeader(routeName) {
      if (
        [
          'login',
          'superlogin',
          'changePassword',
          'ProspectLogin',
          'AccountNotReady',
          'RegistrationChoise',
          'retrieveCredentials',
          'retrievePassword',
          'selfRegistration',
          'RegistrationUserData',
          'RegistrationConfirmation',
          'confirmAccountDeleted'
        ].includes(routeName)
      ) {
        this.isLogin = true
        this.loginArea = true
        this.showSupportSection = false
        this.showMenu = false
        this.isMyShop = false
        this.showChatbotSales = false
        this.isCommodityFlow = false
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.chatOpen = false
        this.chatOpenSales = false
      } else if (routeName === 'fiberFlow') {
        this.isLogin = false
        this.showChatbotSales = true
        this.isUpselling = true
        this.showMenu = false
        this.isMyShop = false
        this.isCommodityFlow = false
        this.isFiberFlow = true
        this.isChangeOffer = false
        this.chatOpen = true
        this.chatOpenSales = true
      } else if (
        routeName === 'gasOffering' ||
        routeName === 'eleOffering' ||
        routeName === 'offersgas' ||
        routeName === 'offersele'
      ) {
        this.showChatbotSales = true
        this.isCommodityFlow = true
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.isUpselling = true
        this.showMenu = false
        this.isMyShop = false
        this.chatOpen = true
        this.chatOpenSales = true
      } else if (
        routeName === 'changeOffer' ||
        routeName === 'changeOfferCommodity' ||
        routeName === 'resultChangeOffer'
      ) {
        this.showChatbotSales = false
        this.isCommodityFlow = true
        this.isFiberFlow = false
        this.isChangeOffer = true
        this.isUpselling = true
        this.showMenu = false
        this.isMyShop = false
        this.chatOpen = true
        this.chatOpenSales = false
      } else if (
        routeName === 'subscribedgas' ||
        routeName === 'subscribedele'
      ) {
        this.showChatbotSales = true
        this.isCommodityFlow = true
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.isUpselling = true
        this.showMenu = true
        this.isMyShop = false
        this.chatOpen = false
        this.chatOpenSales = true
      } else if (routeName === 'notice') {
        this.isLogin = false
        this.loginArea = true
        this.isUpselling = false
        this.showSupportSection = false
        this.showMenu = false
        this.isMyShop = false
        this.showChatbotSales = false
        this.isCommodityFlow = false
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.chatOpen = false
        this.chatOpenSales = false
      } else if (routeName === 'myshop') {
        this.isLogin = false
        this.isMyShop = true
        this.isCommodityFlow = false
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.showChatbotSales = false
      } else {
        this.isLogin = false
        this.loginArea = false
        this.isUpselling = false
        this.showSupportSection = true
        this.showMenu = true
        this.showChatbotSales = false
        this.isCommodityFlow = false
        this.isFiberFlow = false
        this.isChangeOffer = false
        this.isMyShop = false
        this.chatOpen = true
        this.chatOpenSales = true
      }
    },
    isMultiMainClients() {
      if (this.$route.query.isMultiMainClients) {
        router.push({ path: '', query: {} })
        this.dialogMultiMainClient = true
      }
    },
    async buildTrackEventPoperties(action) {
      const section = 'header'
      let name = ''
      let params = {}
      switch (action) {
        case 'support':
          name = `${section}_support_tap`
          break
        case 'chat':
          if (this.$route.name === 'supplyDetail') {
            let detail = await this.$store.dispatch('supply/getDetail', {
              clientOwnerCode: this.$route.params.clientOwnerCode,
              supplyCode: this.$route.params.supplyCode
            })
            params = getSupplyTrackingParams(detail)
          }
          name = `${section}_chat_tap`
          break
        case 'chatsales':
          name = `upsellingv2_chatsales_tap`
          break
      }

      params.page_name = this.$route.name

      return { name, params }
    },
    openMessageCenter: function () {
      this.trackTapEvent('home_msgcenter_tap')
      eventBus.$emit('message-center:open')
      this.trackTapEvent('message_center_viewed')
    },
    goToSupport() {
      this.trackTapEvent(this.buildTrackEventPoperties('support'))
      this.$router.push({ name: 'SupportPage' })
    },
    async showChatDialog() {
      if (this.clientsForAccount.length > 1) {
        if (this.$route.name === 'supplyDetail') {
          this.clientSelected = this.clientsForAccount.filter(
            (c) => c.clientOwnerCode === this.$route.params.clientOwnerCode
          )[0]
        } else {
          this.dialogClients = true
        }
      } else {
        this.showChat = true
      }
      let params = await this.buildTrackEventPoperties('chat')
      this.trackTapEvent(params)
    },
    showChatDialogSales() {
      this.showChatSales = true
    },
    resetClients() {
      this.clientSelected = null
      this.fromClient = this.mainClient
      if (this.$route.meta.openChat) {
        this.$router.push({ name: 'home' })
      }
    },
    confirmResetUpselling() {
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      const address = this.$store.getters['upsellingComm/address']
      if (this.isFiberFlow) {
        this.trackTapEvent({
          name: 'upsellingv2_exit_confirm',
          params: {
            client_selected: addressCovered
              ? addressCovered['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      } else if (this.isCommodityFlow) {
        if (this.isChangeOffer) {
          this.trackTapEvent({
            name: 'upsellingv2_exit_confirm',
            params: {
              client_selected:
                this.$route.params.clientOwnerCode || 'not_selected'
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_exit_confirm',
            params: {
              client_selected: address
                ? address['clientOwnerCode'] || ''
                : 'not_selected'
            }
          })
        }
      }
      this.resetFiberState()
      this.resetUpsellingComm()
      this.dialogFiberNew = false

      this.$router.push({ name: 'home' })
    },
    closeUpselling() {
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      const address = this.$store.getters['upsellingComm/address']
      if (this.isFiberFlow) {
        this.trackTapEvent({
          name: 'upsellingv2_exit_tap',
          params: {
            client_selected: addressCovered
              ? addressCovered['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      } else if (this.isCommodityFlow) {
        if (this.isChangeOffer) {
          this.trackTapEvent({
            name: 'upsellingv2_exit_tap',
            params: {
              client_selected:
                this.$route.params.clientOwnerCode || 'not_selected'
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_exit_tap',
            params: {
              client_selected: address
                ? address['clientOwnerCode'] || ''
                : 'not_selected'
            }
          })
        }
      }
      if (this.$route.name === 'resultChangeOffer') {
        this.$router.push({ name: 'home' })
      } else {
        this.dialogFiberNew = true
      }
    },
    undoCloseUpselling() {
      const addressCovered =
        this.$store.getters['upsellingFiber/addressCovered']
      const address = this.$store.getters['upsellingComm/address']
      if (this.isFiberFlow) {
        this.trackTapEvent({
          name: 'upsellingv2_exit_close',
          params: {
            client_selected: addressCovered
              ? addressCovered['clientOwnerCode'] || ''
              : 'not_selected'
          }
        })
      } else if (this.isCommodityFlow) {
        if (this.isChangeOffer) {
          this.trackTapEvent({
            name: 'upsellingv2_exit_close',
            params: {
              client_selected:
                this.$route.params.clientOwnerCode || 'not_selected'
            }
          })
        } else {
          this.trackTapEvent({
            name: 'upsellingv2_exit_close',
            params: {
              client_selected: address
                ? address['clientOwnerCode'] || ''
                : 'not_selected'
            }
          })
        }
      }
      this.dialogFiberNew = false
    }
  },
  beforeMount() {
    const update = async () => {
      if (
        !this.$store.getters['session/username'] ||
        this.$store.getters['session/passwordExpired']
      )
        return

      const resp = await this.getClientsForAccount()
      let arr = []
      for (const key in resp) {
        const value = resp[key]
        if (value.clientOwnerType === 'RES') arr.push(value)
      }
      for (const key in resp) {
        const value = resp[key]
        if (value.clientOwnerType === 'BUS') arr.push(value)
      }
      this.clientsForAccount = arr
      if (resp) {
        this.getTotalNotifications()
      }

      if (!this.fromClient) {
        this.getMainClient().then((resp) => {
          const openChat = this.$route.meta.openChat
          const mainClient = resp
          // TODO transform promise with await
          if (openChat) {
            const alterego =
              this.clientsForAccount.find(
                (item) => item.clientOwnerCode === this.$route.params.clientCode
              ) || false
            if (alterego) {
              this.fromClient = alterego
              this.chatProcess = this.$route.query.process || ''
              this.showChat = true
            }
          } else {
            this.fromClient = mainClient
          }
          const clientCode = mainClient.clientOwnerCode

          if (!clientCode) return ''

          this.chatUrl = ENV_CONFIGS.CHATBOT_URL
          this.chatUrlSales = ENV_CONFIGS.CHATBOT_URL_SALES

          this.isChannelOpen({
            channel: consts.CALLCENTER_CHANNELS.CHAT,
            area: consts.CALLCENTER_AREAS.SUPPORT
          })
            .then((resp) => {
              this.chatOpen = resp
            })
            .catch((error) => {
              // We do not expect any business logic error, so show an alert with generic error
              GenErr.handleGenericError(error && error.message)
            })
          this.isChannelOpen({
            channel: consts.CALLCENTER_CHANNELS.CHAT,
            area: consts.CALLCENTER_AREAS.SUPPORT_FIBER
          })
            .then((resp) => {
              this.chatOpenSales = resp
            })
            .catch((error) => {
              // We do not expect any business logic error, so show an alert with generic error
              GenErr.handleGenericError(error && error.message)
            })
        })
      } else {
        const clientCode = this.fromClient.clientOwnerCode
        if (!clientCode) return ''

        this.chatUrl = ENV_CONFIGS.CHATBOT_URL
        this.chatUrlSales = ENV_CONFIGS.CHATBOT_URL_SALES

        this.isChannelOpen({
          channel: consts.CALLCENTER_CHANNELS.CHAT,
          area: consts.CALLCENTER_AREAS.SUPPORT
        })
          .then((resp) => {
            this.chatOpen = resp
          })
          .catch((error) => {
            // We do not expect any business logic error, so show an alert with generic error
            GenErr.handleGenericError(error && error.message)
          })
        this.isChannelOpen({
          channel: consts.CALLCENTER_CHANNELS.CHAT,
          area: consts.CALLCENTER_AREAS.SUPPORT_FIBER
        })
          .then((resp) => {
            this.chatOpenSales = resp
          })
          .catch((error) => {
            // We do not expect any business logic error, so show an alert with generic error
            GenErr.handleGenericError(error && error.message)
          })
      }
    }

    update()

    eventBus.$on('chat:open', ({ from, client }) => {
      // console.log('open from: ' + from + ' ' + JSON.stringify(client))
      this.showChat = true
      if (client) {
        this.fromClient = client
        update()
      }
    })

    eventBus.$on('greeners:error', ({ type }) => {
      this.typeError = type
      this.showAlertDialog = true
    })

    eventBus.$on('greenersSelectClient', (text) => {
      this.showSelectClientDialog = true
    })

    router.beforeEach((to, from, next) => {
      update()

      next()
    })
  },
  created: function () {
    this.setupHeader(this.$route.name)
    this.isMultiMainClients()

    // Initial setup of title using meta data in routes definition
    document.title = this.$route.meta.title || 'My Sorgenia'
  },
  watch: {
    $route(to) {
      this.setupHeader(to.name)
      this.isMultiMainClients()
      if (to.params.typeError) {
        this.typeError = to.params.typeError
        this.showAlertDialog = true
      } else {
        this.showAlertDialog = false
      }
      // Update title using meta data in routes definition
      document.title = to.meta.title || 'My Sorgenia'
    },

    clientSelected(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.dialogClients = false
        eventBus.$emit('chat:open', { from: 'support', client: newVal })
      }
    }
  },
  components: {
    Chatbot: Chatbot,
    ChatbotSales: ChatbotSales,
    MenuNew: MenuNew,
    AlertError: AlertError,
    SelectClientDialog: SelectClientDialog
  }
}
</script>
<style lang="scss">
.header {
  padding: 0 std-side-padding();
  background-color: white;
  height: 93px;
  position: fixed;
  z-index: 201;
  width: 100%;
  box-shadow: 0px 15px 10px -15px rgb(108, 108, 108);

  .content {
    height: 100%;
  }

  &--old {
    padding: 0 std-side-padding();
    height: 110px;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(44, 53, 66, 0.08),
      0 4px 8px 0 rgba(44, 53, 66, 0.08), 0 12px 24px 0 rgba(44, 53, 66, 0.06);
    &--old {
      padding: 0 std-side-padding();
      height: 110px;
      width: 100%;
      position: static;
      z-index: 1;
      box-shadow: none;
    }
    .logo {
      width: 20%;
      z-index: 1;

      img {
        margin-top: 10px;
      }
    }
  }

  .main-menu {
    width: 80%;
  }

  .cursor {
    cursor: pointer;
  }

  .close {
    font-size: 16px;
    margin-left: 16px;
  }

  .support-links {
    text-align: right;
    padding-right: 0;

    .v-btn {
      margin: auto 16px;
      z-index: 1;
    }

    .msgCenter {
      position: relative;
      background: white;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.12));

      .msgCounter {
        position: absolute;
        top: -25px;
        right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #97d707;
        border-radius: 20px;
        font-size: 16px;
        font-weight: bold;
        width: 27px;
        height: 27px;
      }
    }
  }

  .notify {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $srg-green;
    color: white;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    position: relative;
    left: 5px;
    padding-top: 2px;
  }

  .v-dialog,
  .chat-dialog {
    max-width: 400px !important;
    padding: 0px;
    background: white;
    border-radius: 1;
    position: fixed;
    bottom: 0;
    right: 0;
  }

  .chatFixed {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 24px;
    bottom: 24px;
  }

  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $srg-blue;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: relative;
    &::after {
      content: url('../../assets/icons/message-center.svg');
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .msgCounter {
      position: absolute;
      top: -6px;
      right: -6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $srg-purple;
      border-radius: 11px;
      font-size: 12px;
      font-weight: bold;
      color: white;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
}

.v-dialog__content {
  .multiCLients-dialog {
    background: white;
    padding: 75px 55px 48px 55px;
    border-radius: 40px;
    position: relative;

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    h4 {
      span {
        font-weight: bold;
      }
      /*  &:hover {
        background: #193494;
      }*/
    }

    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }

  .clients-dialog {
    background: white;
    padding: 55px 45px 45px 55px;
    border-radius: 40px;
    position: relative;

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;

      &.preview {
        position: initial;
      }
    }

    .client {
      width: 326px;
      height: 66px;
      margin-top: 33px;
      margin-right: 30px;
      padding: 20px 22px 20px 24px;
      border-radius: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);

      .black--text {
        color: black;
      }

      &:nth-child(3n + 0) {
        margin-right: 0px;
      }
    }
  }
}
</style>
