<template>
  <div class="survey mt-10">
    <h5 class="mb-1">fotovoltaico</h5>
    <h4 class="mb-6">
      Tieni aggiornate le informazioni su casa e impianto fotovoltaico: possiamo
      aiutarti a trovare la soluzione ideale per te, anche se devi ancora
      installarlo.
    </h4>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel
        v-for="(item, i) in surveyList"
        :key="i"
        class="survey__box mb-6"
        @click="selectAddress(item)"
      >
        <v-expansion-panel-header>
          <h4>{{ item.indirizzo }}</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            :class="`survey__item mb-4 mt-4 ${item.saved ? 'edit' : ''}`"
            @click="openSurvey(item)"
          >
            <h6>
              Questionario 1<img
                class="ml-6 mr-1"
                src="../../../assets/profile/clock.svg"
              />1 min
            </h6>
            <h6 class="bold">Com’è fatto?</h6>
          </div>
          <div
            v-if="item.completed && isCompletedHouse(item)"
            class="survey__suggestion ButtonS mt-6"
            @click="
              $router.push({
                name: 'serviceSection',
                params: { type: 'fotovoltaico' }
              })
            "
          >
            Scopri le offerte su misura per te
          </div>
          <div
            v-else-if="item.completed && !isCompletedHouse(item)"
            class="survey__suggestion ButtonS mt-6"
            @click="gotoSurveyHome"
          >
            Completa le domande sulla tua casa
          </div>
          <div
            v-else-if="item.saved"
            class="survey__suggestion ButtonS mt-6"
            @click="openSurvey(item)"
          >
            Finisci di compilare il questionario!
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex align-center justify-center mt-8">
      <h5 @click="addAddressDialogOpen" class="addAddress">
        Aggiungi nuovo indirizzo
      </h5>
    </div>
    <v-dialog v-model="dialog" max-width="555">
      <div class="dialogSurvey" :class="`${step === 0 ? 'scrollable' : ''}`">
        <div
          v-if="step > 0"
          class="d-flex justify-space-between align-center mb-6"
        >
          <h3>Il mio impianto</h3>
          <img
            class="close"
            src="../../../assets/icons/ic-close.svg"
            @click="exitSurveyDialog"
          />
        </div>
        <AddressProfile
          v-if="step === 0"
          :address.sync="address"
          :dialog.sync="dialog"
          :view.sync="viewAddressList"
          @next="next"
          @resetState="resetState"
          :canSaveAddress="true"
        />
        <div v-if="step === 1">
          <h5 class="mb-2">{{ step }} di 3</h5>
          <div class="question implant mb-6">
            <h4>Hai un impianto fotovoltaico?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              photovoltaicData.possessoImpianto ===
              Installation.YES_WHIT_SORGENIA
                ? 'selected'
                : ''
            }`"
            @click="
              photovoltaicData.possessoImpianto = Installation.YES_WHIT_SORGENIA
            "
          >
            <h6>Sì, ho già un pannello installato con Sorgenia</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              photovoltaicData.possessoImpianto ===
              Installation.YES_WHIT_ANOTHER
                ? 'selected'
                : ''
            }`"
            @click="
              photovoltaicData.possessoImpianto = Installation.YES_WHIT_ANOTHER
            "
          >
            <h6>Sì, ho un pannello installato con altro fornitore</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              photovoltaicData.possessoImpianto === Installation.NOT_YET
                ? 'selected'
                : ''
            }`"
            @click="photovoltaicData.possessoImpianto = Installation.NOT_YET"
          >
            <h6>Non ancora, ma vorrei saperne di più</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              photovoltaicData.possessoImpianto === Installation.CANT_PUT
                ? 'selected'
                : ''
            }`"
            @click="photovoltaicData.possessoImpianto = Installation.CANT_PUT"
          >
            <h6>Non potrei metterlo</h6>
          </div>
        </div>
        <div v-if="step === 2">
          <h5 class="mb-2">{{ step }} di 3</h5>
          <div class="question implant mb-6">
            <h4>
              Ti interessano altri prodotti? Seleziona quello che più ti
              interessa
            </h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              photovoltaicData.interesse === Interest.BATTERY ? 'selected' : ''
            }`"
            @click="setInteresse(Interest.BATTERY)"
          >
            <h6>Batteria di accumulazione</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              photovoltaicData.interesse === Interest.HEAT_PUMP
                ? 'selected'
                : ''
            }`"
            @click="setInteresse(Interest.HEAT_PUMP)"
          >
            <h6>Pompa di calore</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              photovoltaicData.interesse === Interest.EXPANSION
                ? 'selected'
                : ''
            }`"
            @click="setInteresse(Interest.EXPANSION)"
          >
            <h6>Potenziamento dell'impianto</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              photovoltaicData.interesse === Interest.NOT_INTERESTED
                ? 'selected'
                : ''
            }`"
            @click="setInteresse(Interest.NOT_INTERESTED)"
          >
            <h6>No, non sono interessato</h6>
          </div>
          <div class="text-area-label mb-2">ALTRO</div>
          <v-textarea
            v-model="other"
            @change="setInteresseToOther(val)"
            rows="4"
            name="message"
            placeholder="Specificare"
            persistent-placeholder
            outlined
          />
        </div>
        <div v-if="step === 3">
          <h5 class="mb-2">{{ step }} di 3</h5>
          <div class="question callme mb-6">
            <h4>Vuoi avere una consulenza?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              photovoltaicData.consulenza === Consulting.RECALL
                ? 'selected'
                : ''
            }`"
            @click="photovoltaicData.consulenza = Consulting.RECALL"
          >
            <h6>Sì, voglio lasciare un mio recapito</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              photovoltaicData.consulenza === Consulting.NOT_INTERESTED
                ? 'selected'
                : ''
            }`"
            @click="photovoltaicData.consulenza = Consulting.NOT_INTERESTED"
          >
            <h6>No, non mi interessa</h6>
          </div>
          <div
            v-if="photovoltaicData.consulenza === Consulting.RECALL"
            class="pt-5 mb-5"
          >
            <v-text-field
              class="auth__input mb-6"
              label="E-mail per essere ricontattato"
              color="#6e7689"
              v-model.trim="photovoltaicData.mailDiContatto"
              @blur="v$.photovoltaicData.mailDiContatto.$touch()"
              :error-messages="emailErrors"
            />
            <v-text-field
              type="number"
              class="auth__input"
              label="telefono per essere ricontattato"
              color="#6e7689"
              v-model.trim="photovoltaicData.telefonoDiContatto"
              @blur="v$.photovoltaicData.telefonoDiContatto.$touch()"
              :error-messages="phoneErrors"
            />
          </div>
        </div>
        <Exit
          v-if="!Number.isInteger(step)"
          :dialog.sync="dialog"
          @backToStep="backToStep"
          @saveSurvey="saveSurvey"
          type="photovoltaic"
        />
        <div class="d-flex justify-space-between align-center">
          <MYSButton
            v-if="step > 1"
            buttonText="Indietro"
            buttonClass="primaryInvertedButtonXSBorded"
            @buttonClick="prev"
          />
          <MYSButton
            v-if="step > 0"
            :disabled="isDisabled"
            :buttonText="step === 3 ? 'Conferma' : 'Avanti'"
            buttonClass="primaryButtonXS ml-auto"
            @buttonClick="next"
          />
        </div>
      </div>
    </v-dialog>
    <Completed :dialog.sync="dialogCompleted" type="photovoltaic" />
  </div>
</template>
<script>
import { eventBus } from '@/main'
import { email, maxLength, minLength, required } from '@vuelidate/validators'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertService } from '../../../js/service/alertService'
import {
  Consulting,
  Installation,
  Interest
} from '../../../js/type/survey_photovoltaic.type.js'
import AddressProfile from './Address.vue'
import Completed from './Completed.vue'
import Exit from './Exit.vue'

import { useVuelidate } from '@vuelidate/core'
import MYSButton from '../../../components/global/MYSButton.vue'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  name: 'photovoltaicComponent',
  data() {
    return {
      dialog: false,
      step: 0,
      photovoltaicData: {
        possessoImpianto: '',
        interesse: '',
        consulenza: '',
        mailDiContatto: '',
        telefonoDiContatto: '',
        username: ''
      },
      other: '',
      address: '',
      viewAddressList: 'list',
      dialogCompleted: false,
      Installation: Installation,
      Interest: Interest,
      Consulting: Consulting
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        setTimeout(() => {
          this.viewAddressList = 'list'
        }, 400)
      } else {
        if (this.step > 0) {
          this.trackTapEvent({
            name: 'profile2_openSurvey_tap',
            params: {
              survey_type: 'photovoltaic',
              survey_name: 'Com’è fatto??',
              client_code: this.userProfile.clientOwnerCode
            }
          })
        }
      }
    },
    'photovoltaicData.interesse': function (val) {
      if (
        val === Interest.BATTERY ||
        val === Interest.HEAT_PUMP ||
        val === Interest.EXPANSION ||
        val === Interest.NOT_INTERESTED
      ) {
        this.other = ''
      } else {
        this.other = val
      }
    }
  },
  created() {
    eventBus.$on('profile:onboardingClosed', () => {
      this.panel = []
    })
    let onboardingStorage = JSON.parse(
      localStorage.getItem('onboardingProfile')
    )
    if (
      !onboardingStorage ||
      !onboardingStorage[this.mainClient.clientOwnerCode]
    ) {
      this.panel = 0
    }
  },
  beforeCreate() {
    eventBus.$on('photovoltaic:changeSurvey', (address) => {
      if (address) {
        this.getSurveyByAddress(address)
        this.selectAddress(address)
        this.openSurvey(address)
      } else {
        this.getSurveyByAddress()
        this.selectAddress(this.surveyList[0])
        this.openSurvey(this.surveyList[0])
      }
    })
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'clientDetails']),
    ...mapGetters('profile', [
      'photovoltaic',
      'photovoltaicSelected',
      'house',
      'userProfile'
    ]),
    ...mapGetters('session', ['username']),
    surveyList() {
      if (
        this.photovoltaic.length === 0 ||
        !this.photovoltaic[0].egonIndirizzo
      ) {
        return [
          {
            indirizzo: 'Il mio impianto',
            saved: false
          }
        ]
      } else {
        return this.photovoltaic
      }
    },
    panel() {
      return this.surveyList.map((survey, index) => index) || []
    },
    isDisabled() {
      if (this.step === 1) {
        return !this.photovoltaicData.possessoImpianto
      } else if (this.step === 2) {
        return !this.photovoltaicData.interesse
      } else if (this.step === 3) {
        if (this.photovoltaicData.consulenza === Consulting.RECALL) {
          return (
            this.v$.photovoltaicData.mailDiContatto.$invalid ||
            this.v$.photovoltaicData.telefonoDiContatto.$invalid
          )
        }
        return !this.photovoltaicData.consulenza
      }
      return true
    },
    emailErrors() {
      let errArray = []

      if (!this.v$.photovoltaicData.mailDiContatto.$dirty) return errArray

      this.v$.photovoltaicData.mailDiContatto.email.$invalid === true &&
        errArray.push('Formato email non valido')
      this.v$.photovoltaicData.mailDiContatto.required.$invalid === true &&
        errArray.push("L'email è obbligatoria")

      return errArray
    },
    phoneErrors() {
      let errArray = []

      if (!this.v$.photovoltaicData.telefonoDiContatto.$dirty) return errArray

      this.v$.photovoltaicData.telefonoDiContatto.minLength.$invalid === true &&
        errArray.push('Il numero di telefono deve essere di almeno 6 cifre')
      this.v$.photovoltaicData.telefonoDiContatto.maxLength.$invalid === true &&
        errArray.push('Il numero di telefono può avere al massimo 12 cifre')
      this.v$.photovoltaicData.telefonoDiContatto.required.$invalid === true &&
        errArray.push('Il Numero di telefono è obbligatorio')

      return errArray
    }
  },
  components: {
    AddressProfile,
    Exit,
    Completed,
    MYSButton
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('profile', ['photovoltaicSaveSurvey']),
    ...mapMutations('profile', [
      'setPhotovoltaicValues',
      'setHouseValues',
      'setAddressSelected',
      'setTypOnboardingPhotovoltaic'
    ]),
    prev() {
      this.step--
    },
    next() {
      if (this.step === 0) {
        this.setAddressSelected({
          egonIndirizzo: this.address.egonIndirizzo,
          indirizzo: this.address.indirizzo
        })
        this.photovoltaicData.indirizzo = this.address.indirizzo
        this.photovoltaicData.egonIndirizzo = this.address.egonIndirizzo
        this.setPhotovoltaicValues(this.address)
        this.setHouseValues(this.address)
      } else if (this.step === 3) {
        // if (this.photovoltaicData.completed) {
        //   this.photovoltaicData.edited = true
        // }
        // this.photovoltaicData.completed = true
        this.saveSurvey()
        this.dialog = false
        // this.dialogCompleted = true
      }
      if (this.step < 3) this.step++
    },
    selectAddress(item) {
      if (item.egonIndirizzo) {
        this.setAddressSelected({
          egonIndirizzo: item.egonIndirizzo,
          indirizzo: item.indirizzo
        })
      }
    },
    openSurvey(item) {
      this.selectAddress(item)
      this.resetState()
      Object.assign(this.photovoltaicData, item)
      if (this.photovoltaicData.egonIndirizzo) {
        this.step = 1
      } else {
        this.step = 0
      }
      this.dialog = true
    },
    saveSurvey() {
      this.photovoltaicData.saved = !!this.photovoltaicData.possessoImpianto
      if (this.photovoltaicData.consulenza === Consulting.RECALL) {
        this.photovoltaicData.username = this.username
      } else {
        this.photovoltaicData.username = undefined
        this.photovoltaicData.mailDiContatto = undefined
        this.photovoltaicData.telefonoDiContatto = undefined
      }
      if (this.step === 3) {
        if (this.photovoltaicData.completed) {
          this.photovoltaicData.edited = true
        }
        this.photovoltaicData.completed = true
      }
      this.photovoltaicData.cap = this.clientDetails.residentialCAP
      this.photovoltaicData.comune = this.clientDetails.residentialCity
      this.photovoltaicData.provincia = this.clientDetails.residentialProvince
      this.setPhotovoltaicValues(this.photovoltaicData)
      this.photovoltaicSaveSurvey()
        .then(() => {
          if (this.step === 3) {
            this.dialogCompleted = true
          }
        })
        .catch(() => {
          AlertService(
            'Servizio non disponibile',
            'Al momento non è possibile salvare i questionari. Ti invitiamo a riprovare.',
            false,
            'Chiudi',
            '#'
          )
        })
    },
    resetState() {
      this.photovoltaicData = {
        possessoImpianto: '',
        interesse: '',
        consulenza: '',
        cap: '',
        comune: '',
        provincia: ''
      }
    },
    addAddressDialogOpen() {
      this.step = 0
      this.viewAddressList = 'list' // addAddress
      this.dialog = true
    },
    exitSurveyDialog() {
      this.setTypOnboardingPhotovoltaic(false)
      this.trackTapEvent({
        name: 'profile2_surveyClose_tap',
        params: {
          survey_type: 'photovoltaic',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      if (this.step > 1) {
        this.step = this.step + 'exit'
      } else {
        this.dialog = false
      }
    },
    backToStep() {
      this.step = parseInt(this.step.replace('exit', ''))
    },
    setInteresseToOther() {
      this.photovoltaicData.interesse = this.other
    },
    setInteresse(val) {
      this.other = ''
      this.photovoltaicData.interesse = val
    },
    isCompletedHouse(item) {
      if (!item || !item.egonIndirizzo) return false
      return this.house.find((h) => h.egonIndirizzo === item.egonIndirizzo)
        ?.completed
    },
    gotoSurveyHome() {
      eventBus.$emit('onboarding:profile', 'house')
    },
    getSurveyByAddress(survey) {
      if (survey) {
        this.panel =
          this.photovoltaic.findIndex(
            (s) => s.indirizzo === survey.indirizzo
          ) || 0
      } else {
        this.panel = 0
      }
    }
  },
  validations: {
    photovoltaicData: {
      mailDiContatto: { required, email },
      telefonoDiContatto: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(12)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
.text-area-label {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: #6e7689;
}
</style>
<style lang="scss">
.dialogSurvey {
  .v-input.v-textarea {
    &.v-text-field--outlined fieldset {
      border: 1px solid #dfe4ef;
    }
  }
  label.v-label.theme--light {
    top: -25px;
    transition: none;
    transform: none;
  }
  .theme--light.v-input input {
    color: $srg-blue !important;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .v-text-field .v-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #6e7689;
    text-transform: uppercase;
  }
}
</style>
