<template>
  <div>
    <div class="page">
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <transition name="fade">
        <div v-if="!loaded">
          <v-row>
            <v-col
              ><img src="@assets/fotovoltaico/placeholder.svg" width="100%"
            /></v-col>
          </v-row>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="loaded">
          <v-row>
            <v-col class="pb-0" cols="12">
              <MonitorTitleVue
                title="Produzione del mio impianto"
                :abstract="textLabelTime"
              ></MonitorTitleVue>
            </v-col>
          </v-row>
        </div>
      </transition>
    </div>
    <div class="title-section">
      <div class="page">
        <v-row>
          <v-col cols="12">
            <MonitorValueVue title="Produzione totale" :value="formattedValue">
            </MonitorValueVue>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="page">
      <v-row>
        <v-col cols="12">
          <MonitorHeaderVue
            :currentOptionList="currentOptionList"
            :currentEnd="body.endDate"
            :currentStart="body.startDate"
            :installationConnectionStartDate="installationConnectionStartDate"
            @change-current="changeCurrent"
            @change-unit="changeUnit"
          ></MonitorHeaderVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorChartVue
            :series="series"
            :categories="categories"
            :columnWidth="columnWidth"
            chartLabelY="produzione"
            :load="load"
          ></MonitorChartVue>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <MonitorControlsVue
            @change-control="changeControl"
            :controls="controls"
          ></MonitorControlsVue>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import MonitorHeaderVue from '../../../components/fotovoltaico/monitor/MonitorHeader.vue'
import MonitorTitleVue from '../../../components/fotovoltaico/monitor/MonitorTitle.vue'
import MonitorValueVue from '../../../components/fotovoltaico/monitor/MonitorValue.vue'
import MonitorChartVue from '../../../components/fotovoltaico/monitor/MonitorChart.vue'
import MonitorControlsVue from '../../../components/fotovoltaico/monitor/MonitorControls.vue'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import functionsUtilityFV from '@/js/fotovoltaico/functions'

const allRules = ['Energia immessa in rete', 'Energia autoconsumata']

export default {
  components: {
    MonitorTitleVue,
    MonitorValueVue,
    MonitorHeaderVue,
    MonitorChartVue,
    MonitorControlsVue
  },
  beforeMount() {
    if (!this.addressSelected.photovoltaicData && !this.$route.params.siteId) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.trackTapEvent({
      name: 'monitoring_historical_data_viewed',
      params: {
        category: 'production'
      }
    })
    this.loadProduction()
  },
  data() {
    return {
      loaded: true,
      load: false,
      value: '12,27 kW',
      type: 'Giorno',
      textLabelTime: 'Dati aggiornati ogni 15 minuti.',
      current: new Date().toISOString(),
      rules: [...allRules],
      body: {
        acuId: '', // retrieveclientaccount
        siteId: '', // retrievehomepage,
        granularity: 'Giorno',
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          0,
          0,
          0
        ).toISOString(),
        endDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          23,
          59,
          59
        ).toISOString()
      }
    }
  },
  computed: {
    ...mapState('fotovoltaico', ['productionData']),
    ...mapGetters('fotovoltaico', [
      'getProductionDataTemp',
      'getProductionData',
      'getProductionControl',
      'getInstallationData',
      'getFlagEnterByDeeplink'
    ]),
    ...mapState('fotovoltaico', ['addressSelected']),
    ...mapGetters('account', ['mainClient']),

    breadcrumbsItems() {
      this.setFlagEnterByDeeplink(false)

      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'dashboardFotovoltaico' }, text: 'Flussi energetici' },
        { to: { name: 'fotovoltaicoProduzione' }, text: 'Produzione' }
      ]

      return res
    },
    series: {
      get() {
        return this.productionResponse && this.productionResponse.values
          ? this.productionResponse.values.series
          : []
      },
      set(value) {
        this.productionResponse.values.series = value
      }
    },
    categories() {
      return this.productionResponse && this.productionResponse.categories
        ? this.productionResponse.categories
        : []
    },
    currentOptionList() {
      const date = new Date(this.current)
      const dateStart = new Date(this.installationConnectionStartDate)
      switch (this.body.granularity) {
        case 'Giorno':
          return functionsUtilityFV.formatDayMonth(date)
        case 'Settimana':
          const startOfWeek = functionsUtilityFV.getStartOfWeek(date)
          const endOfWeek = functionsUtilityFV.getEndOfWeek(date)
          const checkWeekYear =
            startOfWeek.getFullYear() == endOfWeek.getFullYear()
          return `${functionsUtilityFV.formatDayMonth(
            startOfWeek,
            true,
            checkWeekYear
          )} - ${functionsUtilityFV.formatDayMonth(endOfWeek, true)}`

        case 'Mese':
          return functionsUtilityFV.formatMonth(date)
        case 'Anno':
          return functionsUtilityFV.formatYear(date)
        case 'Dall inizio':
          return `${functionsUtilityFV.formatFromActivation(
            dateStart
          )} - ${functionsUtilityFV.formatFromActivation(new Date())}`
        default:
          return functionsUtilityFV.formatDayMonth(date)
      }
    },
    columnWidth() {
      return this.productionResponse && this.productionResponse.columnWidth
        ? this.productionResponse.columnWidth
        : 0
    },
    productionSum() {
      return this.productionResponse &&
        this.productionResponse.energyProductionSum
        ? this.productionResponse.energyProductionSum
        : 0
    },
    totalProduction() {
      return this.productionResponse && this.getProductionControl
        ? this.getProductionControl.totalProduction
        : 0
    },
    formattedValue() {
      return (
        this.totalProduction.toLocaleString('it-IT', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) + ' kWh '
      )
    },
    installationConnectionStartDate() {
      return this.getInstallationData?.siteProfile
        ?.installationConnectionStartDate
    },
    productionResponse() {
      return this.getProductionData.data
    },
    controls() {
      let data = [
        {
          load: this.load,
          title: 'Energia autoconsumata',
          icon: 'icon-green-light',
          value:
            this.getProductionControl &&
            this.getProductionControl.selfConsumption
              ? this.getProductionControl.selfConsumption[0].value
              : 0,
          percentage:
            this.getProductionControl &&
            this.getProductionControl.selfConsumptionPercentage
              ? this.getProductionControl.selfConsumptionPercentage[0].value
              : 0
        },
        {
          load: this.load,
          title: 'Energia immessa in rete',
          icon: 'icon-green-blue',
          value:
            this.getProductionControl &&
            this.getProductionControl.energyToNetwork
              ? this.getProductionControl.energyToNetwork[0].value
              : 0,
          percentage:
            this.getProductionControl &&
            this.getProductionControl.energyToNetworkPercentage
              ? this.getProductionControl.energyToNetworkPercentage[0].value
              : 0
        }
      ]

      if (this.$route.params.showbattery) {
        data.push({
          load: this.load,
          title: 'Energia caricata in batteria',
          icon: 'icon-green',
          value:
            this.getProductionControl &&
            this.getProductionControl.energyToBattery
              ? this.getProductionControl.energyToBattery[0].value
              : 0,
          percentage:
            this.getProductionControl &&
            this.getProductionControl.energyToBatteryPercentage
              ? this.getProductionControl.energyToBatteryPercentage[0].value
              : 0
        })
      }

      return data
    }
  },
  methods: {
    ...mapActions('fotovoltaico', [
      'retrieveProductionKPI',
      'retrieveProductionKPIControl',
      'photovoltaicInstallationDetails',
      'retrieveDashboardData'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('supply', ['getSupplies']),
    ...mapMutations('fotovoltaico', ['setFlagEnterByDeeplink']),

    async changeCurrent(current, amount) {
      this.load = true
      functionsUtilityFV.updateDates(this.body.granularity, amount, this.body)
      this.current = this.body.endDate
      await this.retrieveProductionKPI(this.body)
      switch (this.body.granularity) {
        case 'Giorno':
          let bodyGran = { ...this.body, granularity: 'DAILY' }
          await this.retrieveProductionKPIControl(bodyGran)
          break

        case 'Settimana':
          let bodyWeek = { ...this.body, granularity: 'WEEKLY' }
          await this.retrieveProductionKPIControl(bodyWeek)
          break

        case 'Mese':
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          await this.retrieveProductionKPIControl(bodyMonth)
          break

        case 'Anno':
          let bodyAnnual = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveProductionKPIControl(bodyAnnual)
          break

        case 'Dall inizio':
          let bodyFrom = { ...this.body, granularity: 'ANNUAL' }
          await this.retrieveProductionKPIControl(bodyFrom)
          break

        default:
          break
      }
      this.load = false
    },
    async changeUnit(unit) {
      this.load = true
      const today = new Date()
      switch (unit) {
        case 'Giorno':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            23,
            59,
            59
          ).toISOString()
          let bodyDay = { ...this.body, granularity: 'DAILY' }
          this.retrieveProductionKPIControl(bodyDay)
          break
        case 'Settimana':
          this.textLabelTime = 'Dati aggiornati ogni 60 minuti.'
          const dayOfWeek = today.getDay()
          const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1
          const firstDayOfWeek = new Date(today)
          firstDayOfWeek.setDate(today.getDate() - daysToSubtract)

          this.body.startDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate(),
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate() + 6,
            23,
            59,
            59
          ).toISOString()
          let newBody = { ...this.body, granularity: 'WEEKLY' }
          this.retrieveProductionKPIControl(newBody)
          break
        case 'Mese':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0,
            23,
            59,
            59
          ).toISOString()
          let bodyMonth = { ...this.body, granularity: 'MONTHLY' }
          this.retrieveProductionKPIControl(bodyMonth)
          break
        case 'Anno':
          this.textLabelTime = 'Dati aggiornati ogni 24 ore.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyYear = { ...this.body, granularity: 'ANNUAL' }
          this.retrieveProductionKPIControl(bodyYear)
          break
        case 'Dall inizio':
          this.textLabelTime = 'Dati aggiornati ogni 15 minuti.'
          this.body.startDate = new Date(
            today.getFullYear(),
            0,
            1,
            0,
            0,
            0
          ).toISOString()
          this.body.endDate = new Date(
            today.getFullYear(),
            11,
            31,
            23,
            59,
            59
          ).toISOString()
          let bodyFromActivation = {
            ...this.body,
            granularity: 'FROM_ACTIVATION'
          }
          this.retrieveProductionKPIControl(bodyFromActivation)
          break
      }
      this.body.granularity = unit
      await this.retrieveProductionKPI(this.body)
      this.current = this.body.endDate
      this.load = false
    },

    changeControl({ title, switchRadio }) {
      let persistantData = this.getProductionDataTemp.series
      let filterData = this.getProductionData.data.values.series
      if (switchRadio) {
        this.rules = [...this.rules, title]
        let seriesToAdd = persistantData.filter((el) => el.name === title)
        if (title === 'Energia caricata in batteria') {
          this.series.splice(0, 0, seriesToAdd[0])
        }
        if (title === 'Energia immessa in rete') {
          this.series.splice(1, 0, seriesToAdd[0])
        }
        if (title === 'Energia autoconsumata') {
          this.series.splice(2, 0, seriesToAdd[0])
        }
      } else {
        this.rules = this.rules.filter((curr) => curr !== title)
        this.series = filterData.filter((el) => el.name !== title)
      }
    },
    async loadProduction() {
      this.load = true
      await this.getSupplies()
      if (
        this.$route.params.acuId !== this.mainClient?.acuId ||
        !this.mainClient.acuId
      ) {
        this.$router.push({ name: 'home' })
      } else {
        const siteId = this.$route.params.siteId
        const acuId = this.$route.params.acuId
        const granularityDaily = 'DAILY'
        const granularityFromStart = 'FROM_ACTIVATION'
        const startDateFromStart =
          this.addressSelected?.photovoltaicData
            ?.installationConnectionStartDate
        let endDate = new Date().toISOString().slice(0, 19) + 'Z'
        const today = new Date()
        const startDateDaily =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          )
            .toISOString()
            .slice(0, 19) + 'Z'
        const body = {
          siteId,
          acuId,
          granularityDaily,
          granularityFromStart,
          startDateFromStart,
          endDate,
          startDateDaily
        }
        await this.photovoltaicInstallationDetails(body)
        await this.retrieveDashboardData(body)
        this.body.acuId = this.$route.params.acuId
        this.body.siteId = this.$route.params.siteId
        let bodyStart = { ...this.body, granularity: 'DAILY' }
        await this.retrieveProductionKPIControl(bodyStart)
        await this.retrieveProductionKPI(this.body)
      }
      this.load = false
    }
  }
}
</script>
<style lang="scss" scoped>
.title-section {
  margin-top: 40px;
  background-color: #eaeef5;
  padding: 8px;
}
</style>
