<template>
  <div class="header-stats">
    <div class="swipe">
      <img
        v-if="first"
        class="swipe-img"
        src="@/assets/icons/chevron-sx-grey.svg"
        width="12"
      />
      <img
        v-else
        class="swipe-img link"
        src="@/assets/icons/chevron-sx-blue.svg"
        width="12"
        @click="prev"
      />
      <span class="currentOptionList"> {{ currentOptionList }}</span>

      <img
        v-if="last"
        class="swipe-img"
        src="@/assets/icons/chevron-dx-grey.svg"
        width="12"
      />
      <img
        v-else
        class="swipe-img link"
        src="@/assets/icons/chevron-dx-blue.svg"
        width="12"
        @click="next"
      />
    </div>
    <div class="resize-btn">
      <v-select
        v-model="selectedType"
        :items="types"
        placeholder="Seleziona"
        persistent-placeholder
        :width="188"
        outlined
        rounded
        append-icon="mdi-chevron-down"
        @change="changeUnit"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentOptionList: { type: String, required: true },
    installationConnectionStartDate: { type: String },
    currentStart: { type: String },
    currentEnd: { type: String }
  },
  data: () => {
    return {
      selectedType: 'Giorno',
      types: ['Giorno', 'Settimana', 'Mese', 'Anno', 'Dall inizio'],
      index: 0
    }
  },

  created() {
    switch (this.$route.name) {
      case 'fotovoltaicoImpatto':
        this.selectedType = 'Dall inizio'
        this.types.shift()
        break
      case 'fotovoltaicoRisparmio':
        this.selectedType = 'Dall inizio'
        this.types.shift()
        break
      case 'fotovoltaicoProduzione':
        this.selectedType = 'Giorno'
        this.types.pop()
        break
      case 'fotovoltaicoConsumo':
        this.selectedType = 'Giorno'
        this.types.pop()
        break
      default:
        break
    }
  },
  computed: {
    first() {
      let installationDate = new Date(
        new Date(this.installationConnectionStartDate).getFullYear(),
        new Date(this.installationConnectionStartDate).getMonth(),
        new Date(this.installationConnectionStartDate).getDate()
      )
      let newCurrent = new Date(
        new Date(this.currentStart).getFullYear(),
        new Date(this.currentStart).getMonth(),
        new Date(this.currentStart).getDate()
      )
      return installationDate >= newCurrent
    },
    last() {
      let newCurrent = new Date(
        new Date(this.currentEnd).getFullYear(),
        new Date(this.currentEnd).getMonth(),
        new Date(this.currentEnd).getDate() + 1
      )
      return newCurrent > new Date()
    }
  },
  methods: {
    next() {
      this.$emit('change-current', this.currentOptionList, 1)
    },
    prev() {
      this.$emit('change-current', this.currentOptionList, -1)
    },
    changeUnit() {
      this.$emit('change-unit', this.selectedType)
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.header-stats {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.resize-btn {
  max-width: 188px;
}
.swipe {
  min-width: 310px;
  display: flex;
  justify-content: space-between;
}
.swipe-img {
  vertical-align: middle;
}
.currentOptionList {
  margin: auto;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 20.8px;
  color: #12256a;
}
.v-input__slot {
  border-radius: 16px !important;
  padding: 8px 16px !important;
}
.v-select__selection--comma {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 22.4px;
}
</style>
