<template>
  <div class="heating">
    <div class="heating__btn" @click="next('ELE', 'ELE')">
      <h5>Pompa di calore</h5>
    </div>
    <div class="heating__btn" @click="next('GAS', 'GAS')">
      <h5>Caldaia a gas autonoma</h5>
    </div>
    <div class="heating__btn mb-10" @click="next('CEN', 'CENTRE')">
      <h5>Impianto centralizzato</h5>
    </div>
    <div class="heating__box">
      <div class="title">Perchè te lo chiediamo</div>
      <h5 class="desc">
        Insieme agli altri dati che ci hai fornito, la modalità di riscaldamento
        della casa ci aiuta a stabilire il tuo profilo di consumo.
      </h5>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
export default {
  name: 'heatHome',
  computed: {
    ...mapGetters('upsellingComm', ['commodityType'])
  },
  methods: {
    ...mapMutations('upsellingComm', ['setSupplyDetail']),
    ...mapActions('analytics', ['trackTapEvent']),
    next(type, trackId) {
      this.setSupplyDetail({ homeHeatingMode: type })
      if (this.commodityType === 'gas') {
        this.$emit('goToStep', 12, 'notrack')
      } else {
        this.$emit('goToStep', 14, 'upsellingcom_uploadbill_open')
      }
      this.trackTapEvent({
        name: `upsellingcom_heathome${trackId}_tap`,
        params: {
          type: this.$store.getters['upsellingComm/commodityType'],
          client_selected: this.$store.getters['upsellingComm/address']
            ? this.$store.getters['upsellingComm/address']['clientOwnerCode'] ||
              ''
            : 'not_selected'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.heating {
  max-width: 652px;
  color: black;
  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 652px;
    height: 66px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    margin: 8px 0 24px;
    padding: 0 24px;
    position: relative;
    cursor: pointer;
    &::after {
      content: url('../../../../../assets/icons/chevron_rx.svg');
      position: absolute;
      right: 25px;
      top: calc(50% - 12px);
    }
  }
  &__box {
    padding: 24px;
    border-radius: 16px;
    background-color: #ccd0e1;
    margin-bottom: 48px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
  }
}
</style>
