<template>
  <div>
    <TcVars />
    <v-row class="std-side-padding">
      <v-col>
        <h1 class="self-reading__title">Attenzione!</h1>
      </v-col>
    </v-row>

    <v-row class="last-reading">
      <v-col>
        <v-row class="std-side-padding">
          <v-col>
            <h2 class="self-reading__subtitle">
              Stai inserendo la seguente lettura:
            </h2>

            <template v-if="details.serviceType === 'Ele'">
              <span class="last-reading__value"
                >F1:
                <strong>{{
                  enteredReadings && enteredReadings.f1
                }}</strong></span
              >
              <span class="last-reading__value ml-12"
                >F2:
                <strong>{{
                  enteredReadings && enteredReadings.f2
                }}</strong></span
              >
              <span class="last-reading__value ml-12"
                >F3:
                <strong>{{
                  enteredReadings && enteredReadings.f3
                }}</strong></span
              >
            </template>
            <template v-if="details.serviceType === 'Gas'">
              <span class="last-reading__value"
                >Gas: <strong>{{ enteredReadings.f1 }}</strong></span
              >
            </template>

            <p class="last-reading__text mt-8">
              I valori inseriti risultano incompatibili con l’ultima lettura
              presente a sistema. Ti preghiamo di verificare i dati inseriti e
              riprovare.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col class="text-center">
        <MYSButton
          buttonClass="primaryButton"
          @buttonClick="backToBase"
          :loading="sending"
          buttonText="Verifica autolettura"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '../../../../router/router'
import { mapActions } from 'vuex'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import TcVars from '@/components/tcTracker/TcVars'
import MYSButton from '@/components/global/MYSButton'

export default {
  props: ['details', 'enteredReadings'],
  name: 'selfReadingLight_Warning1',
  data() {
    return {
      sending: false,
      supply: {}
    }
  },
  components: {
    TcVars,
    MYSButton
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    backToBase() {
      this.trackTapEvent({
        name: 'selfReading_check_tap',
        params: getSupplyTrackingParams(this.details)
      })
      router.push({ name: 'selfReadingLight_Base' })
      // if (this.details.serviceType === 'Ele') {
      //   router.push({ name: 'selfReadingLight_Base' })
      // } else if (this.details.serviceType === 'Gas') {
      //   router.push({ name: 'selfReadingLight_Base2' })
      // }
    }
  }
}
</script>
