<template>
  <div>
    <div v-if="launcher">
      <div class="HeadingL mt-8 mb-4">Tipologia della richiesta</div>
      <ListLauncher
        :listLauncher="launchers"
        :buttonLabel="true"
        @redirecting="powerChange"
      />
    </div>

    <div v-if="!launcher">
      <div @click="returnToLauncher" class="returnToLauncher">
        <img src="@assets/ic-chevron-sx-blue.svg" width="24" height="24" />
        <div class="HeadingM mt-6 mb-6 ml-2">Tipologia richiesta</div>
      </div>

      <div class="titleChange mb-2">
        <div class="HeadingL">
          {{
            change === 'increasePower'
              ? 'Aumento Potenza'
              : 'Diminuzione Potenza'
          }}
        </div>
        <img
          @click="dialogHelp = true"
          class="titleChange__info"
          src="@/assets/icons/ic-info.svg"
        />
      </div>
      <div class="BodyL">
        {{
          change === 'increasePower'
            ? 'Inserisci le informazioni necessarie per procedere alla richiesta di aumento potenza'
            : 'Inserisci le informazioni necessarie per procedere alla richiesta di diminuzione potenza'
        }}
      </div>
      <div class="changeForm mt-8">
        <div class="LabelM">SCEGLI LA TENSIONE</div>
        <v-select
          v-model="selectedType"
          :items="actions.types"
          placeholder="Seleziona"
          persistent-placeholder
          dense
        />
        <div class="LabelM mt-2 mb-2">SCEGLI LA POTENZA</div>
        <v-select
          v-model="selectedPower"
          :items="
            power[
              change === 'increasePower'
                ? 'aumentoPotenza'
                : 'diminuzionePotenza'
            ]
          "
          placeholder="Seleziona"
          persistent-placeholder
          dense
        />
        <div class="LabelM mt-2 mb-2">MESSAGGIO</div>
        <v-textarea
          v-model="message"
          rows="4"
          name="message"
          placeholder="Scrivi quello di cui hai bisogno"
          solo
          no-resize
        />
        <div class="BodyL infoNumber">
          Inserisci il numero sul quale vuoi essere ricontattato per la gestione
          di questa richiesta
        </div>
        <div class="LabelM mt-5">NUMERO DI TELEFONO</div>
        <v-text-field
          ref="phone"
          @input="filterPhone"
          :value="phone"
          placeholder="Numero di telefono"
          persistent-placeholder
        />
      </div>
      <MYSButton
        width="260"
        buttonClass="primaryButton mt-8 mb-15"
        @buttonClick="buttonClick"
        :disabled="disabled"
        :loading="loading"
        buttonText="Invia richiesta"
      />
    </div>
    <DialogContextualHelp
      v-model="dialogHelp"
      :clientCode="$route.params.clientOwnerCode"
      :idContextualHelp="
        activeTab === 'aumentoPotenza'
          ? 'help-increase-power'
          : 'help-decrease-power'
      "
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { actions } from '@/js/constants'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'
import ListLauncher from '@/components/global/ListLauncher'
import MYSButton from '@/components/global/MYSButton'
export default {
  name: 'PowerChange',
  components: {
    ListLauncher,
    DialogContextualHelp,
    MYSButton
  },
  props: {
    disabled: Boolean
  },
  data() {
    return {
      activeTab: '',
      selectedType: '',
      selectedPower: '',
      phone: '',
      message: '',
      dialogHelp: false,
      types: [
        { label: 'Aumento potenza', text: 'aumento', name: 'aumentoPotenza' },
        {
          label: 'Diminuzione potenza',
          text: 'diminuzione',
          name: 'diminuzionePotenza'
        }
      ],
      launchers: [
        { label: 'Aumento Potenza', redirect: 'increasePower' },
        { label: 'Diminuzione Potenza', redirect: 'decreasePower' }
      ],
      launcher: true,
      change: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    power() {
      let power = this.supplyDetail
        ? Number(this.supplyDetail[this.$route.params.supplyCode].power)
        : null
      power = 3.5
      power = `${power} kW`
      if (power) {
        const index = this.actions.items.indexOf(power)
        const arr = [...this.actions.items]
        const max = arr.splice(index + 1)
        const min = arr.splice(0, index)
        return { aumentoPotenza: max, diminuzionePotenza: min }
      }
      return {
        aumentoPotenza: actions.power,
        diminuzionePotenza: actions.power
      }
    },
    actions() {
      return actions.power
    },
    data() {
      if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.selectedType !== '' &&
        this.selectedPower !== ''
      ) {
        return {
          telefono: this.phone[0],
          tensione: this.selectedType,
          potenza: this.selectedPower,
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      }
      return null
    }
  },
  watch: {
    data(value) {
      this.$emit('update:data', value)
    }
  },
  methods: {
    filterPhone(val) {
      if (val) {
        this.phone = val.match(/[0-9]+/) || this.$refs.phone[0].reset()
      } else {
        this.phone = ''
      }
    },
    powerChange(change) {
      this.change = change
      this.activeTab =
        change === 'increasePower' ? 'aumentoPotenza' : 'diminuzionePotenza'
      this.launcher = false
    },
    buttonClick() {
      this.loading = true
      this.$emit('submit')
    },
    returnToLauncher() {
      this.selectedType = ''
      this.selectedPower = ''
      this.phone = ''
      this.message = ''
      this.launcher = true
    }
  }
}
</script>
<style lang="scss" scoped>
.returnToLauncher {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  .HeadingM {
    color: rgba(18, 37, 106, 1);
  }
}
.titleChange {
  margin-top: 30px;
  display: flex;
  &__info {
    margin-left: 10px;
    cursor: pointer;
  }
}
.infoNumber {
  width: 600px;
}
.LabelM {
  color: rgba(110, 118, 137, 1);
}
.changeForm {
  width: 652px;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.changeForm {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    border: 1px solid #b7bfd2;
    border-radius: 8px;
  }
}
</style>
