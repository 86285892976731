<template>
  <div class="successPage bkg--blue">
    <TcVars />
    <v-row class="successPage__content">
      <v-col class="col-5 img" />
      <v-col class="col-7 info-block">
        <div v-if="type === 'fiber'">
          <h1 class="info-block__title">
            Grazie! <br />
            La tua richiesta è <br />
            stata inviata!
          </h1>
          <span class="info-block__text"
            >Verrai ricontattato nella fascia<br />oraria da te indicata dal
            numero<br />{{ phone }}.</span
          >
        </div>
        <div v-if="type === 'power1' || type === 'power2'">
          <h1 class="info-block__title">
            La tua richiesta <br />è stata inviata!
          </h1>
        </div>
        <div
          v-if="
            type === 'counter1' || type === 'voltage2' || type === 'voltage1'
          "
        >
          <h1 class="info-block__title">
            La tua richiesta <br />è stata inviata!
          </h1>
        </div>
        <div v-if="type === 'counter2'">
          <h1 class="info-block__title">
            La tua richiesta <br />è stata inviata!
          </h1>
        </div>
        <div v-if="type === 'payment'">
          <h1 class="info-block__title">
            La richiesta di <br />modifica di <br />pagamento è stata
            <br />inviata con successo!
          </h1>
          <span class="info-block__text"
            >A breve troverai il metodo di<br />pagamento aggiornato nella
            pagina<br />di dettaglio della tua utenza.</span
          >
        </div>
        <v-btn
          @click="
            haveToReturnUser
              ? $router.push({
                  name: 'supplyDetail'
                })
              : $router.push({ name: 'home' })
          "
          class="info-block__button v-btn--shadowed"
          x-large
          width="288"
          color="white"
          >{{
            haveToReturnUser ? "Torna all'utenza" : 'Torna alla homepage'
          }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import TcVars from '@/components/tcTracker/TcVars'

export default {
  name: 'SuccessPage',
  data() {
    return {
      phone: ''
    }
  },
  components: {
    TcVars
  },
  created() {
    this.getChannelInfo({
      channel: consts.CALLCENTER_CHANNELS.MOBILE,
      area: consts.CALLCENTER_AREAS.SUPPORT
    })
      .then((resp) => {
        this.phone = resp.contact
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  },
  computed: {
    type() {
      return this.$route.params.type
    },
    haveToReturnUser() {
      if (this.type !== 'fiber' && this.type !== 'payment') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('interactions', ['getChannelInfo'])
  }
}
</script>

<style lang="scss" scoped>
.v-btn:not(.v-btn--round).v-size--x-large {
  padding: 0 15px;
}

.successPage {
  height: 100%;

  h1 {
    color: white;
  }

  &__content {
    height: 100%;

    .img {
      background-image: url('../assets/img_typ_girl.png');
      background-size: cover;
      background-position: center;
    }
    .info-block {
      color: white;
      padding: 0 calc((58% - 551px) / 2);
      &__title {
        padding: 100px 0 20px 0;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: normal;
      }

      &__text {
        margin-top: 10px;
        font-weight: bold;
        font-size: 1.4rem;
      }

      &__button {
        padding-left: -10px;
        margin-top: 20px;
        display: block;
        letter-spacing: normal;
        margin-bottom: 100px;
      }
    }
  }
}
</style>
