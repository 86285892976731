import { axiosCMS, axiosACN } from '@/js/axiosInstances.js'
import { categoryProperties, supportProperties } from '@/js/constants'

const filter = (array) => {
  array = array.filter((item) => item.name !== 'POD e PDR')
  let filterd = array.filter((item) => item.parents.length === 0)
  let retFiltered = []

  filterd.forEach((item) => {
    let findAll = array.filter(
      (findCat) =>
        findCat?.parents?.length > 0 &&
        findCat?.parents?.find((f) => f === item.id)
    )
    item.children = findAll
    delete item.parents
    retFiltered.push(item)
  })
  retFiltered.sort((a, b) => {
    // Ordina in ordine alfabetico discendente in base al campo 'name'
    return b.name.localeCompare(a.name)
  })
  // serve per cambiare l'ordine di Fibra con Fotovoltaico
  // Trova gli indici degli oggetti con i nomi desiderati
  const indexFotovoltaico = retFiltered.findIndex(
    (obj) => obj.name === 'Fotovoltaico'
  )
  const indexFibra = retFiltered.findIndex((obj) => obj.name === 'Fibra')

  // Verifica se entrambi gli oggetti esistono nell'array
  if (indexFotovoltaico !== -1 && indexFibra !== -1) {
    // Effettua lo scambio
    ;[retFiltered[indexFotovoltaico], retFiltered[indexFibra]] = [
      retFiltered[indexFibra],
      retFiltered[indexFotovoltaico]
    ]
  } else {
    // console.log("Uno o entrambi gli oggetti non sono presenti nell'array.") TODO da gestire
  }
  // nel caso in cui non ci siano ancora le faq per FV, in quel caso non viene mostrata la categoria
  let retFilteredFV = retFiltered.filter((el) => el.name === 'Fotovoltaico')

  // Verifica se almeno uno dei figli ha delle FAQ
  let hasFaqs = retFilteredFV[0].children.some(
    (element) => element.items.length > 0
  )

  // Se nessun figlio ha delle FAQ, rimuovi la categoria "Fotovoltaico" da retFiltered
  if (!hasFaqs) {
    retFiltered = retFiltered.filter((el) => el.name !== 'Fotovoltaico')
  }

  return retFiltered
}

const getVisibleCategories = (categories, properties, account) => {
  if (
    !account ||
    !categories ||
    !properties ||
    !Object.keys(account).length ||
    !Object.keys(categories).length
  )
    return {}
  return categories.filter((cat) => {
    if (!properties[cat.id] || properties[cat.id].access?.length === 0) {
      return cat
    } else {
      return properties[cat.id].access.some((el) => account[el]) ? cat : false
    }
  })
}

export default {
  namespaced: true,
  state: {
    categories: [],
    categoryProperties,
    supportProperties
  },
  getters: {
    categoryProperties: (state) => state.categoryProperties,
    supportProperties: (state) => state.supportProperties,
    visibleCategories: (state, getters, rootState) =>
      getVisibleCategories(
        state.categories,
        state.categoryProperties,
        rootState.account.accountProperties
      )
  },
  mutations: {
    resetState(state) {
      state.categories = []
      state.categoryProperties = categoryProperties
      state.supportProperties = supportProperties
    },
    setCategories(state, payload) {
      state.categories = filter(payload)
    }
  },
  actions: {
    getCategories({ commit }) {
      return axiosCMS
        .get('/seyj/faqcategorylist.json?client_type=CUSTOMER')
        .then((resp) => {
          commit('setCategories', resp.data)
        })
        .catch((err) => {
          console.error(err)
          alert(err)
        })
    },
    createRequest({ commit, rootGetters }, body) {
      return axiosACN
        .post('/commodity/creazioneInterazione', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          return resp.data
        })
        .catch((err) => {
          console.error(err)
          alert(err)
          throw err
        })
    },

    callMeBackUtente({ rootGetters }, body) {
      return axiosACN
        .post('/commodity/callMeBackUtente', body, {
          headers: {
            Authorization: 'Bearer ' + rootGetters['session/token']
          }
        })
        .then((resp) => {
          return resp.data
        })
        .catch((err) => {
          console.error(err)
          alert(err)
        })
    },

    init({ dispatch, state, rootState }) {
      dispatch('supply/getSupplies', false, { root: true })
      if (!state.categories.length) {
        dispatch('getCategories')
      }
    }
  }
}
