<template>
  <div class="page fotovoltaico-main">
    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <transition name="fade">
      <div v-if="!loaded">
        <v-row>
          <Placeholder type="photovoltaic"></Placeholder>
        </v-row>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="loaded">
        <v-row>
          <v-col class="pb-0" cols="8">
            <h1 class="supplies__title">Flussi energetici</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="dashboard">
              <DashboardHeaderStatusVue
                :lastUpdate="dashboard.lastUpdate"
                :connectionStart="
                  installation.siteProfile.installationConnectionStartDate
                "
                :overallStatus="dashboard.overallStatus"
                :isMulti="isMultisite"
              ></DashboardHeaderStatusVue>
              <!-- <DashboardComponentsVue
                :overallStatus="overallStatus"
                :components="components"
              ></DashboardComponentsVue> -->
              <div class="dashboard-monitor">
                <div style="flex: 1 1 auto; margin: 0 16px 0 0">
                  <DashboardAnimation
                    :produzione="dashboard.produzione.value"
                    :carica="dashboard.batteria.carica"
                    :rete="dashboard.rete.value"
                    :consumo="dashboard.consumo.value"
                    :batteria="dashboard.batteria.value"
                    :showBatteria="getBattery"
                    :inFromOutBattery="dashboard.inFromOutBattery"
                    :inFromOutNet="dashboard.inFromOutNet"
                    :inFromOutProduction="dashboard.inFromOutProduction"
                    :inFromOutConsumation="dashboard.inFromOutConsumation"
                    :consumptionAnimation="
                      dashboard.animationDirections.consumptionAnimation
                    "
                    :batteryAnimation="
                      dashboard.animationDirections.batteryAnimation
                    "
                    :productionAnimation="
                      dashboard.animationDirections.productionAnimation
                    "
                    :networkAnimation="
                      dashboard.animationDirections.networkAnimation
                    "
                  ></DashboardAnimation>
                </div>
                <div style="flex: 2 1 auto" class="dashboard-monitor-right">
                  <div class="dashboard-monitor">
                    <div style="width: 50%">
                      <DashboardMonitorStatVue
                        :title="dashboard.produzione.title"
                        :value="dashboard.produzione.value"
                        :iconName="dashboard.produzione.iconName"
                        :items="dashboard.produzione.items"
                        :showBatteria="getBattery"
                      ></DashboardMonitorStatVue>
                    </div>
                    <div style="width: 50%">
                      <DashboardMonitorStatVue
                        :title="dashboard.consumo.title"
                        :value="dashboard.consumo.value"
                        :iconName="dashboard.consumo.iconName"
                        :items="dashboard.consumo.items"
                        :showBatteria="getBattery"
                      ></DashboardMonitorStatVue>
                    </div>
                  </div>
                  <DashboardMonitorAutosufficienzaVue
                    :value="dashboard.autosufficienzaValue"
                  ></DashboardMonitorAutosufficienzaVue>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="dashboard-charts">
              <DashboardChartVue
                :chartData="dashboard.chartProduzione"
                :handleClick="handleClickProduzione"
                :showBatteria="getBattery"
              ></DashboardChartVue>
              <DashboardChartVue
                :chartData="dashboard.chartConsumo"
                :handleClick="handleClickConsumo"
                :showBatteria="getBattery"
              ></DashboardChartVue>
              <DashboardChartVue
                :chartData="dashboard.chartRisparmio"
                :handleClick="handleClickRisparmio"
                :showBatteria="getBattery"
              ></DashboardChartVue>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            ><DashboardCFPVue
              :cfp="dashboard.cfp"
              :handleClick="handleClickImpatto"
            ></DashboardCFPVue
          ></v-col>
        </v-row>
      </div>
    </transition>
    <v-snackbar
      v-if="textSnackbar"
      v-model="showSnackBar"
      width="936px"
      :color="statusSnackbar === 'snack-error' ? '#F9E3E3' : '#E7F5F7'"
      :class="`${statusSnackbar} snackbarFV`"
    >
      <div class="changed BodyL">{{ textSnackbar }}</div>
    </v-snackbar>
  </div>
</template>
<script>
import DashboardAnimation from '@components/fotovoltaico/dashboard/DashboardAnimation.vue'
import DashboardMonitorStatVue from '@components/fotovoltaico/dashboard/DashboardMonitorStat.vue'
import DashboardMonitorAutosufficienzaVue from '@components/fotovoltaico/dashboard/DashboardMonitorAutosufficienza.vue'
//import DashboardComponentsVue from '@components/fotovoltaico/dashboard/DashboardComponents.vue'
import DashboardHeaderStatusVue from '@components/fotovoltaico/dashboard/DashboardHeaderStatus.vue'
import DashboardChartVue from '@components/fotovoltaico/dashboard/DashboardChart.vue'
import DashboardCFPVue from '@components/fotovoltaico/dashboard/DashboardCFP.vue'
import { OVERALL_STATUS } from '@/js/constants.js'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import account from '../../../store/modules/account'
import Placeholder from '@components/global/Placeholder.vue'

export default {
  data() {
    return {
      loaded: false,
      snackbar: false,
      textSnackbar: null,
      classError: '',
      OVERALL_STATUS: OVERALL_STATUS,
      isMultisite: false
    }
  },
  components: {
    DashboardMonitorStatVue,
    DashboardMonitorAutosufficienzaVue,
    //DashboardComponentsVue,
    DashboardHeaderStatusVue,
    DashboardChartVue,
    DashboardCFPVue,
    DashboardAnimation,
    Placeholder
  },

  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  beforeMount() {
    if (!this.addressSelected.photovoltaicData && !this.$route.params.siteId) {
      this.$router.push({ name: 'home' })
    }
  },

  mounted() {
    this.loadDashboard()
    this.trackTapEvent({
      name: 'service_detail_viewed',
      params: {
        service_type: 'fotovoltaico'
      }
    })
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  },
  watch: {
    addressSelected() {
      this.loadDashboard()
    }
  },
  computed: {
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('supply', ['supplies']),
    ...mapState('fotovoltaico', [
      'installation',
      'dashboard',
      'addressSelected'
    ]),
    ...mapGetters('fotovoltaico', ['getFlagEnterByDeeplink']),

    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'dashboardFotovoltaico' }, text: 'Flussi energetici' }
      ]

      return res
    },

    getBattery() {
      // verifico se c'è la batteria
      const storage = this.installation?.siteProfile?.macroDevices.find(
        (item) => item.macroDeviceType === 'STORAGE_SYSTEM'
      )
      // se c'è esco direttamente
      if (storage) {
        return true
      }
      // se non c'è faccio partire hideInformationWithoutBattery che nasconde le info sul grafico della batteria e allinea consumo e produzione
      this.hideInformationWithoutBattery()
      return false
    },

    showSnackBar: {
      get() {
        if (
          this.dashboard.overallStatus !== OVERALL_STATUS.NO_ANOMALY &&
          this.dashboard.overallStatus !== OVERALL_STATUS.UNKNOWN
        ) {
          return true
        }
        return false
      },
      set(val) {}
    },

    statusSnackbar() {
      if (
        this.dashboard.overallStatus === OVERALL_STATUS.ALARM ||
        this.dashboard.overallStatus === OVERALL_STATUS.NOT_COMMUNICATING
      ) {
        return 'snack-error'
      }
      return 'snack-alert'
    },

    components() {
      if (this.dashboard.overallStatus == OVERALL_STATUS.NOT_COMMUNICATING) {
        return [
          { title: 'Inverter', iconName: 'inverter', status: 'offline' },
          { title: 'Pannello', iconName: 'pannelli', status: 'offline' },
          { title: 'Batteria', iconName: 'batteria', status: 'offline' }
        ]
      } else if (this.dashboard.overallStatus == OVERALL_STATUS.NO_ANOMALY) {
        return [
          { title: 'Inverter', iconName: 'inverter', status: 'connesso' },
          { title: 'Pannello', iconName: 'pannelli', status: 'connessi' },
          { title: 'Batteria', iconName: 'batteria', status: 'connessa' }
        ]
      }
      return [
        { title: 'Inverter', iconName: 'inverter', status: 'connesso' },
        { title: 'Pannello', iconName: 'pannelli', status: 'connessi' },
        { title: 'Batteria', iconName: 'batteria', status: 'non funzionante' }
      ]
    }
  },
  methods: {
    ...mapActions('fotovoltaico', [
      'photovoltaicInstallationDetails',
      'retrieveDashboardData',
      'retrievePhotovoltaicData'
    ]),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('supply', ['getSupplies']),
    ...mapMutations('fotovoltaico', [
      'setAddressSelected',
      'setAddressSelectedDashboard'
    ]),
    //SETTARE A FALSE mel dialog
    hideInformationWithoutBattery() {
      this.dashboard.chartProduzione.stats.splice(2, 1)
      this.dashboard.chartConsumo.stats.splice(2, 1)
    },

    textSnack() {
      if (this.installation && this.installation.siteProfile) {
        this.textSnackbar = this.installation?.siteProfile?.staticErrorMessage
      }
    },

    handleBeforeUnload(event) {
      if (!this.isNavigating) {
        this.isNavigating = true
        event.preventDefault()
        event.returnValue = ''
      }
    },

    async loadDashboard() {
      this.loaded = false
      await this.getSupplies()
      if (
        this.$route.params.acuId !== this.mainClient.acuId ||
        !this.mainClient.acuId
      ) {
        this.$router.push({ name: 'home' })
      } else {
        // Recupero acuId e siteId da indirizzo selezionato
        const siteId = this.addressSelected?.photovoltaicData?.siteId
          ? this.addressSelected.photovoltaicData.siteId
          : this.$route.params?.siteId
        const acuId = this.addressSelected.acuId
          ? this.addressSelected.acuId
          : this.$route.params.acuId
        const granularityDaily = 'DAILY'
        const granularityFromStart = 'FROM_ACTIVATION'
        const startDateFromStart =
          this.addressSelected.photovoltaicData?.installationConnectionStartDate
        // .slice(0, 19) + 'Z' pechè il servizio non accetta il formato data 2023-12-06T23:00:00.000Z
        let endDate = new Date().toISOString().slice(0, 19) + 'Z'
        const today = new Date()
        const startDateDaily =
          new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            0,
            0,
            0
          )
            .toISOString()
            .slice(0, 19) + 'Z'
        const body = {
          siteId,
          acuId,
          granularityDaily,
          granularityFromStart,
          startDateFromStart,
          endDate,
          startDateDaily
        }

        await this.photovoltaicInstallationDetails(body)
        await this.retrieveDashboardData(body)
        this.checkIsMultiSite()

        this.loaded = true
        this.textSnack()
        this.getBattery
      }
    },
    handleClickProduzione(event) {
      this.$router.push({
        name: 'fotovoltaicoProduzione',
        params: {
          showbattery: this.getBattery,
          acuId: this.$route.params.acuId,
          siteId: this.$route.params.siteId
        }
      })
    },
    handleClickConsumo(event) {
      this.$router.push({
        name: 'fotovoltaicoConsumo',
        params: {
          acuId: this.$route.params.acuId,
          siteId: this.$route.params.siteId
        }
      })
    },
    handleClickRisparmio(event) {
      this.$router.push({
        name: 'fotovoltaicoRisparmio',
        params: {
          acuId: this.$route.params.acuId,
          siteId: this.$route.params.siteId
        }
      })
    },
    handleClickImpatto(event) {
      this.$router.push({
        name: 'fotovoltaicoImpatto',
        params: {
          acuId: this.$route.params.acuId,
          siteId: this.$route.params.siteId
        }
      })
    },
    checkIsMultiSite() {
      if (this.getFlagEnterByDeeplink) {
        let addressSelected = this.supplies.filter(
          (item) => item.photovoltaicData?.siteId === this.$route.params?.siteId
        )
        this.setAddressSelectedDashboard(addressSelected[0])
        if (
          this.supplies.filter((item) => item.serviceType === 'PHOTOVOLTAIC')
            .length > 1
        ) {
          this.isMultisite = true
        }
      } else {
        if (
          this.supplies.filter((item) => item.serviceType === 'PHOTOVOLTAIC')
            .length > 1
        ) {
          this.isMultisite = true
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.v-sheet.v-snack__wrapper {
  border-radius: 30px !important;
}
.supplies__title {
  font-size: $title-font-size--large;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 52px;
}

.dashboard {
  background-color: #f8f9fc;
  border-radius: 24px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dashboard-monitor {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.dashboard-monitor-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 32px;
}

.dashboard-charts {
  display: flex;
  justify-content: space-between;

  > :not(:last-child) {
    border-right: 1px solid #eaeef5;
  }
}

.snackbarFV {
  .BodyL {
    color: #2c313a;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 35px;
      height: 20px;
      display: block;
      background-position: center;
      margin-right: 8px;
    }
  }
  &.snack-error {
    .BodyL {
      &::before {
        background-image: url('../../../assets/icons/snack-Error.svg');
      }
    }
  }
  &.snack-alert {
    .BodyL {
      &::before {
        background-image: url('../../../assets/icons/snack-Alert.svg');
      }
    }
  }
}
</style>

<style>
.snackbarFV .v-snack__wrapper {
  border-radius: 16px !important;
  min-width: 1116px;
  box-shadow: none;
}
.snackbarFV .v-snack__content {
  padding: 16px;
}
</style>
