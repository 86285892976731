<template>
  <div>
    <div v-show="!showSiaFrame">
      <div class="newMethodSection">
        <div v-if="selectLauncher === 'launcher'">
          <div class="BodyL mb-7">
            Scegli il metodo di pagamento alternativo desiderato. Ricorda che
            completando con successo il cambio del metodo di pagamento, la
            modifica verrà apportata solamente all’utenza selezionata
          </div>
          <ListLauncher
            :listLauncher="launchers"
            :buttonLabel="true"
            @redirecting="redirect"
          />
        </div>

        <div class="paymentMethodForm" v-if="selectLauncher === 'newIban'">
          <div class="HeadingL mb-8">Nuovo conto corrente</div>
          <div class="LabelM">NUOVO IBAN</div>
          <v-text-field
            id="newIban"
            placeholder="Inserisci il codice IBAN"
            v-model.trim="newIban"
            :error-messages="newIbanErrors"
            persistent-placeholder
            @input="ibanValidator(newIban)"
            class="auth__input"
          ></v-text-field>
          <div
            v-if="
              this.supplyDetail[this.$route.params.supplyCode]
                .clientOwnerType === 'RES'
            "
            class="sameHolder"
          >
            <div class="BodyL mr-4">
              L’intestatario del conto corrente è lo stesso della fornitura
            </div>
            <v-switch v-model="sameHolder" inset color="#12256A" />
          </div>
          <div v-if="!sameHolder">
            <div class="LabelM mt-3">NOME INTESTATARIO</div>
            <v-text-field
              placeholder="Inserisci il nome"
              v-model.trim="newIbanName"
              :error-messages="newIbanNameErrors"
              @blur="v$.newIbanName.$touch()"
              class="auth__input"
            ></v-text-field>
            <div class="LabelM mt-3">COGNOME INTESTATARIO</div>
            <v-text-field
              placeholder="Inserisci il cognome"
              v-model.trim="newIbanSurname"
              :error-messages="newIbanSurnameErrors"
              @blur="v$.newIbanSurname.$touch()"
              class="auth__input"
            ></v-text-field>
            <div class="LabelM mt-3">CODICE FISCALE INTESTATARIO</div>
            <v-text-field
              placeholder="Inserisci il codice fiscale"
              v-model.trim="newIbanCf"
              :error-messages="newIbanCfErrors"
              @blur="v$.newIbanCf.$touch()"
              class="auth__input"
            ></v-text-field>
          </div>
          <MYSButton
            buttonClass="primaryButton mt-8 mb-12"
            @buttonClick="addPaymentMethod"
            :disabled="!paymentMethodValid"
            :loading="isSending"
            buttonText="Conferma e procedi"
          />
        </div>
      </div>
    </div>

    <div class="siaDialog" v-show="showSiaFrame">
      <iframe
        id="fiberSiaCC"
        v-if="siaUrl"
        @load="iframeUrlChanged"
        :src="siaUrl"
        frameborder="0"
      ></iframe>
    </div>
    <LoaderDialog :loaderDialogOpen.sync="showLoaderDialog" />
  </div>
</template>
<script>
import Consts from '@/js/constants'
import { getRequiredValidationError, validateCF } from '@/js/utils'
import { alphaNum, required } from '@vuelidate/validators'
import ListLauncher from '@/components/global/ListLauncher'
import MYSButton from '@/components/global/MYSButton'
import LoaderDialog from '@/components/global/LoaderDialog'
import { mapActions, mapGetters } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  props: ['value', 'type'],
  name: 'paymentMethodForm',
  components: {
    ListLauncher,
    MYSButton,
    LoaderDialog
  },
  data() {
    return {
      newPaymentMethod: null,
      sameHolder: null,
      newIban: '',
      newIbanName: '',
      newIbanSurname: '',
      newIbanBusiness: '',
      newIbanCf: '',
      paymentData: null,
      selectLauncher: 'launcher',
      showSiaFrame: false,
      siaUrl: '',
      showLoaderDialog: false,
      isSending: false,
      isValidateIban: false,
      PAYMENT_METHODS: Consts.PAYMENT_METHODS,
      launchers: [
        {
          label: 'Addebito in conto corrente',
          redirect: 'newIban'
        },
        {
          label: 'Addebito diretto carta di credito',
          redirect: 'newCreditCard'
        }
      ]
    }
  },
  mounted() {
    if (
      this.supplyDetail[this.$route.params.supplyCode].clientOwnerType === 'BUS'
    ) {
      this.sameHolder = false
    } else {
      this.sameHolder = true
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails', 'getcontactMail']),
    ...mapGetters('upsellingFiber', ['client']),
    ...mapGetters('supply', ['supplyDetail']),
    newIbanErrors() {
      let errArray = []
      if (!this.v$.newIban.$dirty) return errArray

      this.v$.newIban.required.$invalid &&
        errArray.push("L'IBAN è obbligatorio")
      if (!this.isValidateIban) {
        errArray.push('Il codice IBAN non è corretto')
      }
      return errArray
    },
    newIbanNameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanName',
        'Il nome intestatario è obbligatorio'
      )
    },
    newIbanSurnameErrors() {
      return getRequiredValidationError(
        this.v$,
        'newIbanSurname',
        'Il cognome intestatario è obbligatorio'
      )
    },
    newIbanCfErrors() {
      let errArray = []

      if (!this.v$.newIbanCf.$dirty) return errArray

      this.v$.newIbanCf.required.$invalid === true &&
        errArray.push('Il codice fiscale intestatario è obbligatorio')
      this.v$.newIbanCf.validateCF === false &&
        errArray.push('Formato codice fiscale non valido')

      return errArray
    },
    paymentMethodValid() {
      if (!this.newPaymentMethod) return false
      if (this.newPaymentMethod === 'newBankAccount') {
        if (this.sameHolder) {
          return !this.v$.newIban.$invalid
        } else {
          return (
            !this.v$.newIban.$invalid &&
            !this.v$.newIbanName.$invalid &&
            !this.v$.newIbanCf.$invalid
          )
        }
      } else {
        return false
      }
    },
    supplyClient() {
      return this.$store.getters['upsellingFiber/client']
    }
  },
  watch: {
    newIbanCf(newVal) {
      this.newIbanCf = newVal.toUpperCase()
    },
    newIban(newVal) {
      this.newIban = newVal.toUpperCase().replace(' ', '')
      this.isValidateIban = false
    }
  },
  methods: {
    ...mapActions('account', ['getDetailForClient']),
    ...mapActions('upsellingFiber', ['getSiaUrl']),
    ...mapActions('upsellingComm', ['checkIBAN']),
    ...mapActions('analytics', ['trackTapEvent']),
    addPaymentMethod(ccParams) {
      this.v$.newIban.$touch()
      this.ibanValidator(this.newIban)
      if (this.newPaymentMethod === 'newBankAccount') {
        if (this.isValidateIban) {
          this.isSending = true
          this.paymentData = {
            type: this.PAYMENT_METHODS.BANK_ACCOUNT,
            iban: this.newIban,
            name:
              this.newIbanName ||
              (this.supplyClient
                ? this.supplyClient.firstName
                : this.clientDetails.name),
            surname:
              this.newIbanSurname ||
              (this.supplyClient
                ? this.supplyClient.lastName
                : this.clientDetails.surname),
            fiscalCode:
              this.newIbanCf ||
              (this.supplyClient
                ? this.supplyClient.fiscalCode
                : this.clientDetails.fiscalCode)
          }
          this.$emit('update:data', this.paymentData)
          this.$emit('submit')
        }
      } else if (this.newPaymentMethod === 'newCreditCard') {
        this.paymentData = {
          type: this.PAYMENT_METHODS.CREDIT_CARD,
          fiscalCode: this.supplyClient
            ? this.supplyClient.fiscalCode
            : this.clientDetails.fiscalCode,
          ...ccParams
        }
        this.$emit('update:data', this.paymentData)
      }
    },
    closeDialog() {
      this.$emit('input')
    },
    iframeUrlChanged(event) {
      try {
        const loc = event.target.contentWindow.location.href
        if (loc.indexOf('sia/back.html') > -1) {
          this.siaUrl = ''
          this.showSiaFrame = false
          this.newPaymentMethod = null
          this.selectLauncher = 'launcher'
        } else if (loc.indexOf('sia/done.html') > -1) {
          this.showSiaFrame = false
          this.showLoaderDialog = true
          this.selectLauncher = 'launcher'
          const queryParams = loc.substring(loc.indexOf('?') + 1).split('&')
          let queryParamsObj = {}
          queryParams.forEach((qp) => {
            const parts = qp.split('=')
            queryParamsObj[parts[0]] = parts[1]
          })

          const ccParams = {
            aliasPan: queryParamsObj.PANALIAS,
            creditCardCircuit: queryParamsObj.NETWORK,
            creditCardNumber:
              '****' + '****' + '****' + queryParamsObj.PANALIASTAIL,
            creditCardExpirationDate: queryParamsObj.PANALIASEXPDATE,
            crecurr: queryParamsObj.CRECURR,
            trecurr: queryParamsObj.TRECURR
          }
          this.addPaymentMethod(ccParams)

          this.siaUrl = ''
        }
      } catch (error) {
        console.log(
          'we have no access to the iframe data because it is crossdomain. Error:',
          error
        )
        // do nothing. it means we have no access to the iframe data because it is crossdomain
      }
    },
    async ibanValidator(iban) {
      this.isValidateIban = await this.checkIBAN(iban)
    },
    redirect(redirect) {
      this.selectLauncher = redirect
      if (redirect === 'newCreditCard') {
        this.getDetailForClient({
          clientOwnerCode:
            this.$store.getters['upsellingFiber/client'].clientOwnerCode
        }).then((resp) => {
          this.getSiaUrl({
            email: resp.contactMail ? resp.contactMail : this.getcontactMail
          }).then((url) => {
            this.siaUrl = url
            this.showSiaFrame = true
          })
        })
        this.newPaymentMethod = 'newCreditCard'
        this.showSiaFrame = true
      } else {
        this.newPaymentMethod = 'newBankAccount'
      }
    }
  },

  validations: {
    newIban: {
      required,
      validateIban: (value, vm) => {
        return vm.checkIBAN(value)
      }
    },
    newIbanName: { required },
    newIbanSurname: { required },
    newIbanCf: { required, validateCF }
  }
}
</script>
<style lang="scss" scoped>
.paymentDialog {
  background: white;
  padding: 48px;
  position: relative;

  &__title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 26px;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
}

.siaDialog {
  height: 80vh;

  iframe {
    width: 100%;
    height: 100%;
  }
}
.newMethodSection {
  margin-top: 30px;
  width: 652px;
}
.sameHolder {
  display: flex;
  align-items: center;
}
.LabelM {
  color: rgba(110, 118, 137, 1);
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
}
.v-input--selection-controls {
  margin-top: 12px;
}
</style>
<style lang="scss">
.paymentMethodForm {
  .error--text {
    .v-messages__message {
      display: flex;
      align-items: center;
      margin-top: 5px;
      &::before {
        margin-right: 5px;
        content: url('../../../assets/icons/Error.svg');
      }
    }
  }
}
</style>
