import { eventBus } from '@/main'
import store from '../../store'
import consts from '@/js/constants'
import { retry } from 'rxjs/operators'
export default class PhysiCall {
  #elementsClicked = {}
  #timerSection = 0
  #timerDoc = 0
  #maxCounter = 0
  #timeout = 0
  #timerSectionAlreadyDisplayed = false
  #videoCallBooked = false
  #isShowable = false
  #journey = ''
  #journeySection = ''
  #journeyDoc = ''
  #journeyAction = ''
  #trackJourney = ''
  // timerSection: limite di tempo, nella sezione in cui si atterra, dopo il quale la PhysicallDialog comapare
  // timerDoc: limite di tempo, all'apertura di un doc, dopo il quale la PhysicallDialog comapare
  // maxCounter: limite di interazione con un elemento dopo il quale la PhysicallDialog comapare
  constructor(timerSection, timerDoc, maxCounter) {
    this.#videoCallBooked = localStorage.getItem('videoCallBooked') === 'true'
    this.#timerSection = timerSection
    this.#timerDoc = timerDoc
    this.#maxCounter = maxCounter
    this.init()
  }

  async init() {
    this.#isShowable = await this.#checkTime()
    this.#initTimerSection()
  }

  async #checkTime() {
    //const testHours = 15
    //const testDay = 5
    const now = new Date()
    const hours = this.formatTwoDigits(now.getHours()) //testHours
    const day = now.getDay() //testDay  //restituisce un valore compreso tra 0 e 6 (dove 0 è la domenica e 6 è il sabato)
    const todayDate = { day: now.getDate(), month: now.getMonth() + 1 }
    const festivity = [
      { day: 1, month: 1 },
      { day: 6, month: 1 },
      { day: 25, month: 4 },
      { day: 1, month: 5 },
      { day: 2, month: 6 },
      { day: 15, month: 8 },
      { day: 1, month: 11 },
      { day: 8, month: 12 },
      { day: 25, month: 12 },
      { day: 26, month: 12 }
    ]

    if (!store.getters['interactions/callCenterTimes']) {
      await store.dispatch('interactions/getCallCenterTimes')
    }

    const physicallSupport = store.getters['interactions/callCenterTimes'].find(
      (item) => item.id === 'physicall'
    )
    const checkFestivity = festivity.some(
      (item) => item.day === todayDate.day && item.month === todayDate.month
    )
    if (physicallSupport.active_on_holidays == 0 && checkFestivity) {
      return false
    }
    if (physicallSupport.enabled == 0) {
      return false
    }
    const monday_friday_start = physicallSupport.monday_friday_start.split(
      ':',
      1
    )
    const monday_friday_end = physicallSupport.monday_friday_end.split(':', 1)
    const saturday_start = physicallSupport.saturday_start.split(':', 1)
    const saturday_end = physicallSupport.saturday_end.split(':', 1)

    return (
      (day != 0 &&
        day != 6 &&
        hours >= monday_friday_start &&
        hours < monday_friday_end) ||
      (day == 6 && hours >= saturday_start && hours < saturday_end)
    )
  }

  #initTimerSection() {
    if (!this.#isShowable) return false
    if (!this.#timerSection) return false
    this.#videoCallBooked = localStorage.getItem('videoCallBooked') === 'true'
    this.#timeout = setTimeout(() => {
      if (!this.#timerSectionAlreadyDisplayed && !this.#videoCallBooked) {
        this.#journey = this.#journeySection
        this.#trackJourney = this.#journeySection
        eventBus.$emit('open:physicallDialog', 'eventTime')
        this.#timerSectionAlreadyDisplayed = true
      }
    }, this.#timerSection)
  }

  #initTimerDoc(id) {
    if (!this.#isShowable) return false
    if (!this.#timerDoc) return false
    this.#videoCallBooked = localStorage.getItem('videoCallBooked') === 'true'
    this.#timeout = setTimeout(() => {
      if (!this.#videoCallBooked) {
        this.#journey = consts.JOURNEY.TIMER_DOCUMENTI_ID.replace('ID', id)
        this.trackJourney = consts.JOURNEY.TIMER_DOCUMENTI
        eventBus.$emit('open:physicallDialog', 'eventTime')
        this.#elementsClicked[id].timeoutDone = true
      }
    }, this.#timerSection)
  }

  checkCountClick(id, noRestartDoc) {
    if (!this.#isShowable) return false
    this.#videoCallBooked = localStorage.getItem('videoCallBooked') === 'true'
    if (!!this.#elementsClicked[id]) {
      this.#elementsClicked[id].count++
    } else {
      this.#elementsClicked[id] = {
        count: 1,
        clickDone: false,
        timeoutDone: false
      }
    }
    if (!this.#elementsClicked[id].timeoutDone) {
      if (!noRestartDoc) {
        this.restartTimerDoc(id)
      } else {
        this.restartTimerSection()
      }
    }
    if (!this.#videoCallBooked) {
      if (this.#elementsClicked[id].count === this.#maxCounter) {
        this.#elementsClicked[id].clickDone = true
        console.log(this.#journeyDoc)
        this.#journey = this.#journeyDoc.replace('ID', id)
        this.#trackJourney = this.#journeyDoc.replace('_ID', '')
        eventBus.$emit('open:physicallDialog', 'eventClick')
      }
      return this.#elementsClicked[id].count === this.#maxCounter
    } else {
      return false
    }
  }

  checkAction() {
    if (!this.#isShowable) return false
    this.#videoCallBooked = localStorage.getItem('videoCallBooked') === 'true'
    if (!this.#videoCallBooked) {
      this.#journey = this.#journeyAction
      this.#trackJourney = this.#journeyAction
      eventBus.$emit('open:physicallDialog', 'eventClick')
    }
    return this.#videoCallBooked
  }

  clearTimer() {
    clearTimeout(this.#timeout)
  }

  restartTimerSection() {
    this.clearTimer()
    this.#initTimerSection()
  }

  restartTimerDoc(id) {
    this.clearTimer()
    this.#initTimerDoc(id)
  }

  resetElementsClicked() {
    this.#elementsClicked = {}
  }

  reserve() {
    this.#videoCallBooked = true
    // setTimeout(() => {
    //   localStorage.setItem('videoCallBooked', false)
    //   this.#videoCallBooked = false
    // }, 120000)
  }

  resetAll() {
    this.clearTimer()
    this.#elementsClicked = {}
    this.#timerSectionAlreadyDisplayed = false
  }

  formatTwoDigits(value) {
    return value < 10 ? `0${value}` : value
  }

  setJourneySection(val) {
    this.#journeySection = val
  }

  setJourneyDoc(val) {
    this.#journeyDoc = val
  }

  setJourneyAction(val) {
    this.#journeyAction = val
  }

  getJourney() {
    return this.#journey
  }
  getTrackJourney() {
    return this.#trackJourney
  }
}
