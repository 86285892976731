<template>
  <div>
    <v-dialog
      :value="showFootprint"
      :max-width="cfpSize.width"
      :persistent="true"
    >
      <div
        class="cfp-dialog"
        :class="{ 'cfp-big': cfpSize.isBig, 'cfp-small': !cfpSize.isBig }"
      >
        <div v-if="!cfpLoaded">
          <v-progress-circular
            color="white"
            :size="50"
            indeterminate
          ></v-progress-circular>
        </div>
        <iframe
          id="cfp"
          @load="iframeUrlChanged"
          :src="cfpUrl"
          frameborder="0"
          v-show="cfpLoaded"
        ></iframe>
      </div>
    </v-dialog>

    <BidgelyEleDialog
      v-if="mainClient && !mainClient?.isOnlyFV"
      :show-dialog="showDialog"
      @BidgelyEleDialogClose="showDialog = false"
    ></BidgelyEleDialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import BidgelyEleDialog from '../../components/bidgely/BidgelyEleDialog'
import { eventBus } from '@/main'

export default {
  name: 'CfpDialog',
  props: ['showFootprint', 'cfpUrl', 'resetPropsCfp'],
  data() {
    return {
      cfpSize: {
        width: 375,
        isBig: false
      },
      cfpLoaded: false,
      showDialog: false
    }
  },
  components: {
    BidgelyEleDialog
  },
  computed: {
    ...mapGetters('account', [
      'mainClient',
      'mainClientLoyaltyRes',
      'oldestLoyaltyRes',
      'mainClientLoyaltyBus',
      'oldestLoyaltyBus'
    ]),
    ...mapGetters('loyalty', ['memberStatus'])
  },
  methods: {
    ...mapMutations('loyalty', ['resetState']),
    ...mapActions('analytics', ['trackTapEvent']),

    iframeUrlChanged() {
      this.cfpLoaded = true
    },
    onResize() {
      this.cfpSize.isBig = window.innerWidth >= 1600
      this.cfpSize.width = window.innerWidth < 1600 ? 375 : 500
    },
    closeFootprint() {
      this.resetState()
      this.resetPropsCfp()
      this.cfpLoaded = false
    },
    checkAccessLoyalty() {
      if (
        (this.mainClientLoyaltyRes || this.oldestLoyaltyRes) &&
        (this.mainClientLoyaltyBus || this.oldestLoyaltyBus)
      ) {
        eventBus.$emit('greenersSelectClient', 'prova')
      } else if (
        this.memberStatus.user_registered &&
        this.memberStatus.status_category !== 'UNAWARE'
      ) {
        this.$router.push({ name: 'loyaltyPage' })
      } else {
        this.$router.push({ name: 'introPage' })
      }
    },
    handleMessage: function (event) {
      if (event.data === 'cfp-close') {
        this.closeFootprint()
      } else if (event.data === 'goToLoyalty') {
        this.closeFootprint()
        this.checkAccessLoyalty()
      } else if (event.data === 'openLoyalty') {
        this.closeFootprint()
        this.checkAccessLoyalty()
      } else if (event.data === 'goToUpsellingEle') {
        this.closeFootprint()
        this.$router.push({ name: 'eleOffering' })
      } else if (event.data === 'openBeyondEnergy') {
        this.closeFootprint()
        this.showDialog = true
      }
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('message', this.handleMessage)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('message', this.handleMessage)
  }
}
</script>
<style lang="scss" scoped>
.cfp-big {
  height: 800px;
  min-height: 800px;
}
.cfp-small {
  height: 600px;
  min-height: 600px;
}
.cfp-dialog {
  overflow: hidden;
  background-color: $srg-green;
  div {
    display: flex;
    width: 100%;
    height: 100%;
    div {
      margin: auto;
    }
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
