var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.launcher)?_c('div',[_c('div',{staticClass:"HeadingL mt-8 mb-4"},[_vm._v("Tipologia della richiesta")]),_c('ListLauncher',{attrs:{"listLauncher":_vm.launchers,"buttonLabel":true},on:{"redirecting":_vm.powerChange}})],1):_vm._e(),(!_vm.launcher)?_c('div',[_c('div',{staticClass:"returnToLauncher",on:{"click":_vm.returnToLauncher}},[_c('img',{attrs:{"src":require("@assets/ic-chevron-sx-blue.svg"),"width":"24","height":"24"}}),_c('div',{staticClass:"HeadingM mt-6 mb-6 ml-2"},[_vm._v("Tipologia richiesta")])]),_c('div',{staticClass:"titleChange mb-2"},[_c('div',{staticClass:"HeadingL"},[_vm._v(" "+_vm._s(_vm.change === 'increasePower' ? 'Aumento Potenza' : 'Diminuzione Potenza')+" ")]),_c('img',{staticClass:"titleChange__info",attrs:{"src":require("@/assets/icons/ic-info.svg")},on:{"click":function($event){_vm.dialogHelp = true}}})]),_c('div',{staticClass:"BodyL"},[_vm._v(" "+_vm._s(_vm.change === 'increasePower' ? 'Inserisci le informazioni necessarie per procedere alla richiesta di aumento potenza' : 'Inserisci le informazioni necessarie per procedere alla richiesta di diminuzione potenza')+" ")]),_c('div',{staticClass:"changeForm mt-8"},[_c('div',{staticClass:"LabelM"},[_vm._v("SCEGLI LA TENSIONE")]),_c('v-select',{attrs:{"items":_vm.actions.types,"placeholder":"Seleziona","persistent-placeholder":"","dense":""},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}}),_c('div',{staticClass:"LabelM mt-2 mb-2"},[_vm._v("SCEGLI LA POTENZA")]),_c('v-select',{attrs:{"items":_vm.power[
            _vm.change === 'increasePower'
              ? 'aumentoPotenza'
              : 'diminuzionePotenza'
          ],"placeholder":"Seleziona","persistent-placeholder":"","dense":""},model:{value:(_vm.selectedPower),callback:function ($$v) {_vm.selectedPower=$$v},expression:"selectedPower"}}),_c('div',{staticClass:"LabelM mt-2 mb-2"},[_vm._v("MESSAGGIO")]),_c('v-textarea',{attrs:{"rows":"4","name":"message","placeholder":"Scrivi quello di cui hai bisogno","solo":"","no-resize":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c('div',{staticClass:"BodyL infoNumber"},[_vm._v(" Inserisci il numero sul quale vuoi essere ricontattato per la gestione di questa richiesta ")]),_c('div',{staticClass:"LabelM mt-5"},[_vm._v("NUMERO DI TELEFONO")]),_c('v-text-field',{ref:"phone",attrs:{"value":_vm.phone,"placeholder":"Numero di telefono","persistent-placeholder":""},on:{"input":_vm.filterPhone}})],1),_c('MYSButton',{attrs:{"width":"260","buttonClass":"primaryButton mt-8 mb-15","disabled":_vm.disabled,"loading":_vm.loading,"buttonText":"Invia richiesta"},on:{"buttonClick":_vm.buttonClick}})],1):_vm._e(),_c('DialogContextualHelp',{attrs:{"clientCode":_vm.$route.params.clientOwnerCode,"idContextualHelp":_vm.activeTab === 'aumentoPotenza'
        ? 'help-increase-power'
        : 'help-decrease-power'},model:{value:(_vm.dialogHelp),callback:function ($$v) {_vm.dialogHelp=$$v},expression:"dialogHelp"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }