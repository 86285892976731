<template>
  <div class="listLauncher">
    <MYSLauncher
      v-for="(launcher, i) in listLauncher"
      :key="i"
      :label="launcher.label"
      :redirect="launcher.redirect"
      :buttonLabel="buttonLabel"
      @redirecting="redirecting"
    />
  </div>
</template>
<script>
import MYSLauncher from './MYSLauncher.vue'
export default {
  name: 'ListLauncher',
  components: {
    MYSLauncher
  },
  props: {
    listLauncher: Array,
    buttonLabel: Boolean
  },
  methods: {
    redirecting(redirect) {
      this.$emit('redirecting', redirect)
    }
  }
}
</script>
<style scoped></style>
