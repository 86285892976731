<template>
  <div>
    <div v-if="launcher">
      <div class="HeadingL mt-8 mb-4">Tipologia della richiesta</div>
      <ListLauncher
        :listLauncher="launchers"
        :buttonLabel="true"
        @redirecting="counterChange"
      />
    </div>

    <div v-if="!launcher">
      <div @click="returnToLauncher" class="returnToLauncher">
        <img src="@assets/ic-chevron-sx-blue.svg" width="24" height="24" />
        <div class="HeadingM mt-6 mb-6 ml-2">Tipologia richiesta</div>
      </div>

      <div class="titleChange mb-2">
        <div class="HeadingL">
          {{
            change === 'counterMovement'
              ? 'Spostamento Contatore'
              : 'Verifica Contatore'
          }}
        </div>
        <img
          @click="dialogHelp = true"
          class="titleChange__info"
          src="@/assets/icons/ic-info.svg"
        />
      </div>
      <div class="BodyL">
        {{
          change === 'counterMovement'
            ? 'Inserisci le informazioni necessarie per procedere alla richiesta di spostamento contatore'
            : 'Inserisci le informazioni necessarie per procedere alla richiesta di verifica contatore'
        }}
      </div>
      <div id="counter" class="changeForm mt-8">
        <div v-if="change === 'counterMovement'">
          <div class="LabelM mb-2">SCEGLI LO SPOSTAMENTO</div>
          <v-select
            v-model="selectedType"
            :items="actions.shift"
            placeholder="Seleziona"
            persistent-placeholder
            dense
          />
        </div>

        <div class="LabelM mt-2 mb-2">MESSAGGIO</div>
        <v-textarea
          v-model="message"
          rows="4"
          name="message"
          placeholder="Scrivi quello di cui hai bisogno"
          solo
          no-resize
        />
        <div class="BodyL infoNumber">
          Inserisci il numero sul quale vuoi essere ricontattato per la gestione
          di questa richiesta
        </div>
        <div class="LabelM mt-5">NUMERO DI TELEFONO</div>
        <v-text-field
          ref="phone"
          @input="filterPhone"
          v-model="phone"
          placeholder="Numero di telefono"
          persistent-placeholder
        />
      </div>
      <MYSButton
        width="260"
        buttonClass="primaryButton mt-8 mb-15"
        @buttonClick="buttonClick"
        :disabled="disabled"
        :loading="loading"
        buttonText="Invia richiesta"
      />
    </div>
    <DialogContextualHelp
      v-model="dialogHelp"
      :idContextualHelp="
        activeTab === 'spostamentoContatore'
          ? 'help-shift-meter'
          : 'help-verify-meter'
      "
    />
  </div>
</template>
<script>
import { actions } from '@/js/constants'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'
import ListLauncher from '@/components/global/ListLauncher'
import MYSButton from '@/components/global/MYSButton'
export default {
  name: 'CounterMovement',
  props: { disabled: Boolean, loading: Boolean },
  data() {
    return {
      activeTab: '',
      selectedType: '',
      phone: '',
      message: '',
      types: [
        {
          label: 'Spostamento contatore',
          text: 'spostamento',
          name: 'spostamentoContatore'
        },
        {
          label: 'Verifica contatore',
          text: 'verifica',
          name: 'verificaContatore'
        }
      ],
      dialogHelp: false,
      launchers: [
        { label: 'Spostamento contatore', redirect: 'counterMovement' },
        { label: 'Verifica contatore', redirect: 'counterVerify' }
      ],
      launcher: true,
      change: ''
    }
  },
  computed: {
    actions() {
      return actions.counter
    },
    data() {
      if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.selectedType !== '' &&
        this.activeTab === 'spostamentoContatore'
      ) {
        return {
          telefono: this.phone[0],
          spostamento: this.selectedType,
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      } else if (
        this.phone !== '' &&
        this.phone !== undefined &&
        this.message !== '' &&
        this.activeTab === 'verificaContatore'
      ) {
        return {
          telefono: this.phone[0],
          note: this.message,
          pod: this.$route.params.supplyCode,
          commodity: this.activeTab
        }
      }
      return null
    }
  },
  watch: {
    data(value) {
      this.$emit('update:data', value)
    }
  },
  methods: {
    filterPhone(val) {
      if (val) {
        this.phone = val.match(/[0-9]+/) || this.$refs.phone[0].reset()
      } else {
        this.phone = ''
      }
    },
    counterChange(change) {
      this.change = change
      this.activeTab =
        change === 'counterMovement'
          ? 'spostamentoContatore'
          : 'verificaContatore'
      this.launcher = false
    },
    buttonClick() {
      this.$emit('submit')
    },
    returnToLauncher() {
      this.selectedType = ''
      this.phone = ''
      this.message = ''
      this.launcher = true
    }
  },
  components: {
    ListLauncher,
    DialogContextualHelp,
    MYSButton
  }
}
</script>
<style lang="scss" scoped>
.returnToLauncher {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
  .HeadingM {
    color: rgba(18, 37, 106, 1);
  }
}
.titleChange {
  margin-top: 30px;
  display: flex;
  &__info {
    margin-left: 10px;
    cursor: pointer;
  }
}
.infoNumber {
  width: 600px;
}
.LabelM {
  color: rgba(110, 118, 137, 1);
}
.changeForm {
  width: 652px;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 0px;
  margin-bottom: 0px;
}
</style>
<style lang="scss">
.changeForm#counter {
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    box-shadow: none;
    border: 1px solid #b7bfd2;
    border-radius: 8px;
  }
}
</style>
