<template>
  <div class="selfreading-warning2">
    <TcVars />
    <v-dialog
      max-width="45%"
      v-model="showCancelDialog"
      attach=".selfreading-warning2"
    >
      <div>
        <img @click="closeDialog" src="@/assets/ic_chevron_top_black.png" />
        <h3 class="dialog-title">Autolettura</h3>
        <p class="mt-8 mb-12">
          Stai abbandonando l’invio dell’autolettura. Potrai inviarcela in ogni
          momento accedendo all’apposita sezione dell’app My Sorgenia
        </p>

        <div class="text-center">
          <MYSButton
            buttonClass="primaryButton"
            @buttonClick="$router.push({ name: 'supplyDetail' })"
            buttonText="Torna alla fornitura"
          />
        </div>
      </div>
    </v-dialog>

    <v-row class="std-side-padding">
      <v-col>
        <h1 class="self-reading__title">Attenzione!</h1>
      </v-col>
    </v-row>

    <v-row class="last-reading">
      <v-col>
        <v-row class="std-side-padding">
          <v-col>
            <p class="last-reading__text mt-8">
              I valori inseriti risultano incompatibili con l’ultima lettura
              presente a sistema. Sarà nostra cura procedere a tutte le
              verifiche del caso e ti ricontatteremo in caso di necessità.
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-6 std-side-padding">
      <v-col>
        <MYSButton
          width="250"
          buttonClass="primaryInvertedButtonBorded"
          @buttonClick="canceSelfReading"
          buttonText="Abbandona"
        />
      </v-col>
      <v-col class="text-right">
        <MYSButton
          width="250"
          buttonClass="primaryButton"
          @buttonClick="sendReadings"
          :loading="sending"
          buttonText="Prosegui"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getSupplyTrackingParams } from '@/js/trackingUtils'
import TcVars from '@/components/tcTracker/TcVars'
import MYSButton from '@/components/global/MYSButton'

export default {
  props: ['details', 'enteredReadings'],
  name: 'selfReadingLight_Warning2',
  data() {
    return {
      sending: false,
      showCancelDialog: false
    }
  },
  components: {
    TcVars,
    MYSButton
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    sendReadings() {
      this.trackTapEvent({
        name: 'selfReading_continue_tap',
        params: getSupplyTrackingParams(this.details)
      })
      this.$emit('send-values', this.enteredReadings)
      this.sending = true
    },
    closeDialog() {
      this.showCancelDialog = false
    },
    canceSelfReading() {
      this.trackTapEvent({
        name: 'selfReading_quit_tap',
        params: getSupplyTrackingParams(this.details)
      })
      this.showCancelDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
.selfreading-warning2::v-deep {
  .v-dialog {
    position: relative;
    background: white;
    padding: 75px 48px 50px 48px;

    .dialog-title {
      font-size: rem-calc(32);
      font-weight: bold;
    }

    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
  }
}
</style>
