<template>
  <div class="paymentSummary std-side-padding mt-4">
    <v-row class="std-side-padding">
      <v-col>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row class="paymentSummary__title mt-6"> Riepilogo pagamenti </v-row>
    <v-row class="paymentSummary__subtitle">
      Qui trovi la sintesi dei tuoi pagamenti fino a due anni per tutte le
      forniture che hai attivato con Sorgenia.
    </v-row>
    <template v-if="areOnlyBollette">
      <v-row class="paymentSummary__clientDetail">
        <img
          class="flex-grow-0 mr-2"
          :src="
            filteredClientDetails.clientOwnerType === 'RES'
              ? require('../../assets/icons/ic-owner-res-black.svg')
              : require('../../assets/icons/business_black.svg')
          "
          width="24"
          height="24"
        />
        <h3>
          {{
            filteredClientDetails.clientOwnerType === 'RES'
              ? filteredClientDetails.firstName +
                ' ' +
                filteredClientDetails.lastName
              : filteredClientDetails.businessName
          }}
        </h3>
      </v-row>
      <v-row>
        <div class="btn-filter mr-4" @click="dialogDate = true">Periodo</div>
        <div class="paymentSummary__filtered">
          <h4 v-if="filterSelDate.length === 0">
            Stai visualizzando i dati degli ultimi 2 anni
          </h4>
          <span v-if="filterSelDate.length" @click="resetPicker">{{
            filterSelDate
          }}</span>
        </div>
        <div
          class="paymentSummary__download"
          @click="getContractFileUrl('bollette')"
        >
          <h5>Scarica riepilogo in dettaglio</h5>
        </div>
      </v-row>
      <v-row class="paymentSummary__result">
        <v-col
          :cols="formatBillsSummary.length / 2 > 2 ? '4' : '6'"
          class="pl-7 pr-7"
          v-for="(item, i) in formatBillsSummary"
          :key="i"
        >
          <div
            class="paymentSummary__item"
            :class="{
              highlighted: item.highlight,
              pay: item.showPayButton,
              skipped: item.skipSpace
            }"
          >
            <div class="field" v-if="!item.showPayButton && !item.skipSpace">
              {{ item.title }}
            </div>
            <div class="total" v-if="!item.showPayButton && !item.skipSpace">
              € {{ item.total }}
            </div>
            <div v-if="item.showPayButton">
              <v-btn
                @click="recapBillsToPay"
                class="paymentButton"
                v-if="item.hasToPay"
                x-large
                >Paga Ora</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="paymentSummary__disclamer">
          Le bollette In Pagamento e In Verifica potrebbero non rientrare
          all’interno della Sintesi visualizzata.
        </v-col>
      </v-row>
      <v-row class="paymentSummary__selectClient" v-if="!isOnlyOneClient">
        <v-btn
          color="white"
          x-large
          width="345"
          class="v-btn--shadowed"
          @click="dialogClient = true"
          >Seleziona altro intestatario</v-btn
        >
      </v-row>
    </template>

    <template v-else>
      <v-tabs v-model="tabBollettePdr" id="tabBollettePdr" icons-and-text grow>
        <v-tabs-slider></v-tabs-slider>
        <v-tab id="bollette" href="#bollette" class="pr-0">
          <div>
            <span class="BodyXL-Strong text-capitalize">Bollette</span>
            <span v-if="alertBill" class="notification"></span>
          </div>
        </v-tab>
        <v-tab id="pdr" href="#pdr" class="pl-0">
          <div class="d-flex align-center">
            <span class="BodyXL-Strong text-none">Piani di rientro</span>
            <span v-if="alertPdr" class="notification"></span>
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tabBollettePdr">
        <v-tab-item :eager="true" value="bollette">
          <template v-if="loaded">
            <v-row>
              <!-- <div class="btn-filter mr-4" @click="dialogDate = true"> -->
              <div class="btn-filter mr-4" @click="dialogDate = true">
                Periodo
              </div>
              <div class="paymentSummary__filtered">
                <h4 v-if="filterSelDate.length === 0">
                  Stai visualizzando i dati degli ultimi 2 anni
                </h4>
                <span v-if="filterSelDate.length" @click="resetPicker">{{
                  filterSelDate
                }}</span>
              </div>
              <div
                class="paymentSummary__download"
                @click="getContractFileUrl('bollette')"
              >
                <h5>Scarica riepilogo in dettaglio</h5>
              </div>
            </v-row>
            <v-row class="paymentSummary__result">
              <v-col
                :cols="formatBillsSummary.length / 2 > 2 ? '4' : '6'"
                class="pl-7 pr-7"
                :class="{ 'd-none': item.skipSpace }"
                v-for="(item, i) in formatBillsSummary"
                :key="i"
              >
                <div
                  class="paymentSummary__item"
                  :class="{
                    highlighted: item.highlight,
                    pay: item.showPayButton,
                    skipped: item.skipSpace
                  }"
                >
                  <div
                    class="field"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="total"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    € {{ item.total }}
                  </div>
                  <div v-if="item.showPayButton">
                    <v-btn
                      @click="recapBillsToPay"
                      class="paymentButton"
                      v-if="item.hasToPay"
                      x-large
                      >Paga Ora</v-btn
                    >
                  </div>
                </div>
              </v-col>
              <div class="disclaimer pb-3">
                <div class="BodyL disclaimer__bills">
                  Le bollette In Pagamento e In Verifica potrebbero non
                  rientrare all’interno della Sintesi visualizzata.
                </div>
              </div>
            </v-row>
            <v-row class="paymentSummary__selectClient" v-if="!isOnlyOneClient">
              <v-btn
                color="white"
                x-large
                width="345"
                class="v-btn--shadowed"
                @click="dialogClient = true"
                >Seleziona altro intestatario</v-btn
              >
            </v-row>
          </template>
          <template v-else>
            <v-row class="std-side-padding pa-12 loader" align="center">
              <v-col class="text-center">
                <v-progress-circular
                  :size="100"
                  indeterminate
                  color="#12256A"
                />
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
        <v-tab-item :eager="true" value="pdr">
          <template v-if="loaded">
            <v-row>
              <!-- <div class="btn-filter mr-4" @click="dialogDate = true"> -->
              <div class="btn-filter mr-4" @click="dialogDate = true">
                Periodo
              </div>
              <div class="paymentSummary__filtered">
                <h4 v-if="filterSelDate.length === 0">
                  Stai visualizzando i dati degli ultimi 2 anni
                </h4>
                <span v-if="filterSelDate.length" @click="resetPicker">{{
                  filterSelDate
                }}</span>
              </div>
            </v-row>
            <v-row
              class="row paymentSummary__result paymentSummary__result-pdr"
            >
              <v-col
                :cols="formatPdrSummary.length / 2 > 2 ? '4' : '6'"
                class="pl-7 pr-7"
                v-for="(item, i) in formatPdrSummary"
                :key="i"
              >
                <div
                  class="paymentSummary__item"
                  :class="{
                    highlighted: item.highlight,
                    pay: item.showPayButton,
                    skipped: item.skipSpace
                  }"
                >
                  <div
                    class="field"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="total"
                    v-if="!item.showPayButton && !item.skipSpace"
                  >
                    € {{ item.total }}
                  </div>
                  <div v-if="item.showPayButton">
                    <v-btn
                      @click="recapPdrToPay"
                      class="paymentButton"
                      :disabled="!item.hasToPay"
                      x-large
                      >Paga Ora</v-btn
                    >
                  </div>
                </div>
              </v-col>
              <div class="disclaimer pb-3">
                <div class="BodyL disclaimer__pdr">
                  Le rate che sono In Pagamento e In Verifica potrebbero non
                  essere incluse. I piani di rientro Annullati non rientrano
                  all’interno della Sintesi visualizzata.
                </div>
              </div>
            </v-row>
            <v-row class="paymentSummary__selectClient" v-if="!isOnlyOneClient">
              <v-btn
                color="white"
                x-large
                width="345"
                class="v-btn--shadowed"
                @click="dialogClient = true"
                >Seleziona altro intestatario</v-btn
              >
            </v-row>
          </template>
          <template v-else>
            <v-row class="std-side-padding pa-12 loader" align="center">
              <v-col class="text-center">
                <v-progress-circular
                  :size="100"
                  indeterminate
                  color="#12256A"
                />
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <v-dialog
      v-model="dialogDate"
      :max-width="!pickerStart.show && !pickerEnd.show ? 750 : 555"
    >
      <v-row>
        <v-col class="supply-dialog">
          <img
            @click="dialogDate = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <template v-if="!pickerStart.show && !pickerEnd.show">
            <h2>Scegli il periodo che vuoi visualizzare</h2>
            <v-row class="mb-8">
              <v-col class="col-6">
                <div class="btn-filter big" @click="openPicker('start')">
                  Inizio periodo -
                  {{ $filters.formatDateWithSeparator(pickerStart.date, '.') }}
                </div>
              </v-col>
              <v-col class="col-6">
                <div class="btn-filter big" @click="openPicker('end')">
                  Fine periodo -
                  {{ $filters.formatDateWithSeparator(pickerEnd.date, '.') }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  @click="dialogDate = false"
                  color="white"
                  x-large
                  width="310"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="refreshTotal()"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="310"
                  :outlined="pickerStart.date && pickerEnd.date ? false : true"
                  :disabled="pickerStart.date && pickerEnd.date ? false : true"
                  >Conferma</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else-if="pickerStart.show">
            <h3 class="mb-6">Scegli la data iniziale che vuoi visualizzare</h3>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="350"
                v-model="pickerStart.date"
                :max="maxDate"
                :min="minDate"
                :reactive="true"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="pickerStart = { date: pickerStart.date, show: false }"
                  color="white"
                  x-large
                  width="210"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="pickerStart.show = false"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="210"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <h3 class="mb-6">Scegli la data di fine che vuoi visualizzare</h3>
            <v-row justify="center">
              <v-date-picker
                :show-current="false"
                flat
                width="350"
                v-model="pickerEnd.date"
                :max="maxDate"
                :min="pickerStart.date"
                :reactive="true"
              ></v-date-picker>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="6">
                <v-btn
                  @click="pickerEnd = { date: pickerEnd.date, show: false }"
                  color="white"
                  x-large
                  width="210"
                  >Annulla</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  @click="pickerEnd.show = false"
                  class="v-btn--shadowed"
                  x-large
                  color="primary"
                  width="210"
                  >Prosegui</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-dialog>
    <PaymentStatusSummary
      v-model="dialogClient"
      :dialog="dialogClient"
      @closeModal="dialogClient = false"
    />
    <Download
      :dialogDownload="dialogDownload"
      @closeModal="cancelDowload"
    ></Download>

    <pdf-viewer
      :showDialog="dialogPdf && showDownload"
      :url="contractDataUrl"
      @closing-pdfViewer="dialogPdf = false"
      :attachTo="'body'"
      height="1290px"
    />

    <v-dialog v-model="openBillsToPayDialog" :max-width="500">
      <div class="billsRecapDialog">
        <div class="d-flex align-center justify-space-between mb-8">
          <div class="HeadingsSubtitleL">Bollette da pagare</div>
          <img
            @click="openBillsToPayDialog = false"
            src="@/assets/ic_chevron_top_black.png"
          />
        </div>
        <v-row no-gutters>
          <v-col>
            <v-list two-line class="scrollableList">
              <div
                v-for="(streetGroup, groupIndex) in Object.keys(
                  billsByStreetAndType
                )"
                :key="groupIndex + 'header'"
              >
                <div
                  class="d-flex align-center justify-space-between billsRecapDialog__title"
                >
                  <div class="BodyL-Strong">
                    {{ billsByStreetAndType[streetGroup].street }}
                  </div>
                  <div
                    :class="`small-icon-${billsByStreetAndType[streetGroup].type}`"
                  ></div>
                </div>
                <div
                  v-for="(bill, i) in billsByStreetAndType[streetGroup].bills"
                  :key="bill.idFattura"
                >
                  <v-list-item class="pr-4 pl-0">
                    <v-list-item-content>
                      <v-list-item-title>
                        <div
                          :id="bill.idFattura"
                          @click="billSelectionChange(streetGroup, i)"
                          :class="`checkbox d-flex align-center ${
                            bill.confirmPay ? 'selected' : ''
                          }`"
                        >
                          <span class="BodyL"
                            >Bolletta: {{ bill.dataInizioCompetenza }}</span
                          >
                        </div>
                        <!-- <v-checkbox
                          v-show="false"
                          v-model="bill.confirmPay"
                          @change="billSelectionChange"
                        ></v-checkbox> -->
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <span
                        ><strong
                          >€
                          {{
                            formatTotal(
                              bill.statoFattura !== 'Parziale'
                                ? parseFloat(bill.importo)
                                : parseFloat(bill.importoParzialeDaPagare)
                            )
                          }}</strong
                        ></span
                      >
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </div>
            </v-list>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-btn
              @click="goToSupplyPayment"
              v-if="!noBillToPaySelected"
              class="paymentButton"
              x-large
              >Paga € {{ formatTotal(totalToPayMassive) }}</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-dialog>
    <PhysiCallDialog :physicallObj="physiCall"></PhysiCallDialog>
    <FeedbackDialog :physicallObj="physiCall" />
    <DialogPagaPdr
      v-model="dialogPagaPdr"
      :clientDetails="filteredClientDetails"
      :pdrDetail="pdrDetail"
    />
  </div>
</template>
<script>
import PdfViewer from '@/components/pdfViewer/PdfViewer.vue'
import { ENV_CONFIGS } from '@/js/configs.js'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Download from '../../components/download/Download'
import PaymentStatusSummary from '../supplies/PaymentStatusSummary'
import TcVarService from '../../js/service/tcVarService'
import DialogPagaPdr from '../pdr/DialogPagaPdr.vue'
import Consts from '@/js/constants'

import { axiosSorgenia } from '@/js/axiosInstances.js'
import GenErr from '@/js/genericErrors'
import { b64ToBlob } from '@/js/utils'

import { formatdateMonthYear } from '@/js/utils.js'

import PhysiCall from '../../js/service/physiCall'
import PhysiCallDialog from '../../components/physicall/PhysiCallDialog.vue'
import FeedbackDialog from '../../components/physicall/FeedbackDialog.vue'

export default {
  name: 'PaymentSummary',
  data() {
    return {
      tabBollettePdr: 'bollette',
      Consts: Consts,
      dialogDate: false,
      dialogClient: false,
      dialogDownload: false,
      dialogPdf: false,
      dialogPagaPdr: false,
      filterSelDate: '',
      pickerStart: this.initPickerStart(),
      pickerEnd: this.initPickerEnd(),
      loaded: false,
      itemsBillsSummary: [
        { result: null, id: '1' },
        { result: null, id: '2' },
        { result: null, id: '3' },
        { result: null, id: '4' },
        { result: null, id: '5' },
        { result: null, id: '6' },
        { result: null, id: '7' }
      ],
      itemsBillsSummaryPDR: [
        { result: null, id: '1' },
        { result: null, id: '2' },
        { result: null, id: '3' },
        { result: null, id: '4' },
        { result: null, id: '5' },
        { result: null, id: '6' }
      ],
      formatBillsSummary: [],
      formatPdrSummary: [],
      contractDataUrl: '',
      showDownload: true,
      billsToPayMassive: [],
      openBillsToPayDialog: false,
      totalToPayMassive: 0,
      billsByStreetAndType: {},
      hasOneNoRecurrentAtleast: false,
      noBillToPaySelected: false,
      physiCall: null,
      areOnlyBollette: true,
      alertBill: false,
      alertPdr: false
    }
  },
  components: {
    PaymentStatusSummary,
    Download,
    PdfViewer,
    PhysiCallDialog,
    DialogPagaPdr,
    FeedbackDialog
  },
  watch: {
    '$route.params.clientCode': function (clientCode) {
      this.getDetailForClient({
        clientOwnerCode: this.$route.params.clientCode
      })
      this.refreshTotal()
    },
    dialogDate(val) {
      if (val) {
        this.trackTapEvent('summaryBill_timeFilter_tap')
      }
    },
    tabBollettePdr() {
      this.physiCall.resetAll()
      this.physiCall.restartTimerSection()
    }
  },
  computed: {
    ...mapGetters('account', ['filteredClientDetails', 'accountClients']),
    ...mapGetters('supply', [
      'clientsWithSupplies',
      'paymentStatus',
      'supplies'
    ]),
    ...mapGetters('session', ['token']),
    ...mapGetters('bill', ['pdrDetail']),

    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home', exact: true },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Riepilogo pagamenti'
        }
      ]

      return res
    },
    isOnlyOneClient() {
      return Object.keys(this.accountClients).length === 1
    },
    minDate() {
      let minDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      return `${minDate.getFullYear()}-${
        minDate.getMonth() + 1 < 10
          ? '0' + (minDate.getMonth() + 1)
          : minDate.getMonth() + 1
      }-01`
    },
    maxDate() {
      let maxDate = new Date()
      return `${maxDate.getFullYear() + 3}-${
        maxDate.getMonth() + 1 < 10
          ? '0' + (maxDate.getMonth() + 1)
          : maxDate.getMonth() + 1
      }-${maxDate.getDate()}`
    }
  },
  methods: {
    ...mapActions('bill', ['getBillingSummary', 'getBillingSummaryPdr']),
    ...mapActions('account', ['getDetailForClient', 'getClientsForAccount']),
    ...mapActions('supply', ['clientsHaveSupplies', 'getPaymentStatus']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('payment', ['getMethods']),
    ...mapMutations('bill', ['resetPDRPayment']),

    recapBillsToPay() {
      this.billsToPayMassive = this.itemsBillsSummary[4].result.listBollette
      this.totalToPayMassive = this.billsToPayMassive.reduce(
        (sum, bill) =>
          sum +
          (bill.statoFattura !== 'Parziale'
            ? parseFloat(bill.importo)
            : parseFloat(bill.importoParzialeDaPagare)),
        0
      )
      this.billsByStreetAndType = this.billsToPayMassive.reduce(
        (group, bill) => {
          let key = bill.street + '_' + bill.commodity
          if (!group[key])
            group[key] = {
              bills: [],
              street: bill.street,
              type: bill.commodity
            }
          bill.confirmPay = true
          group[key].bills.push(bill)
          return group
        },
        {}
      )
      this.noBillToPaySelected = false
      this.openBillsToPayDialog = true
    },

    recapPdrToPay() {
      this.trackTapEvent({
        name: 'payment_started',
        params: {
          component: 'cta',
          payment_item: 'pdr',
          screen_name: 'MySorgenia - Riepilogo Pagamenti'
        }
      })
      this.dialogPagaPdr = true
    },

    getFlatBillsFromRecapDialog() {
      return Object.values(this.billsByStreetAndType)
        .map((item) => item.bills)
        .reduce((currList, nextList) => currList.concat(nextList))
    },
    billSelectionChange(streetGroup, i) {
      if (Object.values(this.billsByStreetAndType).length === 0) {
        this.noBillToPaySelected = true
        this.totalToPayMassive = 0
      } else {
        this.billsByStreetAndType[streetGroup].bills[i].confirmPay =
          !this.billsByStreetAndType[streetGroup].bills[i].confirmPay
        this.noBillToPaySelected =
          this.getFlatBillsFromRecapDialog().filter((item) => item.confirmPay)
            .length === 0
        this.totalToPayMassive = this.getFlatBillsFromRecapDialog()
          .filter((item) => item.confirmPay)
          .reduce(
            (sum, bill) =>
              sum +
              (bill.statoFattura !== 'Parziale'
                ? parseFloat(bill.importo)
                : parseFloat(bill.importoParzialeDaPagare)),
            0
          )
      }
    },
    goToSupplyPayment() {
      this.trackTapEvent('summaryBill_multiplePayment_tap')
      let confirmedBillsToPay = this.getFlatBillsFromRecapDialog().filter(
        (item) => item.confirmPay
      )
      let idFattura =
        confirmedBillsToPay.length === 1 ? confirmedBillsToPay[0].idFattura : ''
      let isMultiPayment = confirmedBillsToPay.length > 1
      this.$router.push({
        name: 'payAllBills',
        params: {
          clientOwnerCode: this.$route.params.clientCode,
          bills: confirmedBillsToPay,
          isMultiPayment: isMultiPayment,
          idFattura: idFattura,
          from: 'paymentSummary'
        }
      })
    },
    formatdate(value) {
      return formatdateMonthYear(value)
    },
    initPickerStart() {
      let date = new Date()
      let dateStartDefault = `${date.getFullYear() - 2}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-01`

      return { date: dateStartDefault, show: false }
    },
    initPickerEnd() {
      let date = new Date()
      let dateEndDefault = `${date.getFullYear() + 3}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate()}`

      return { date: dateEndDefault, show: false }
    },
    openPicker(type) {
      if (type === 'start') {
        this.pickerStart.show = true
      } else {
        this.pickerEnd.show = true
      }
    },
    refreshTotal() {
      let dateStart = this.pickerStart.date.split('-')
      let dateEnd = this.pickerEnd.date.split('-')
      this.dialogDate = false
      this.filterSelDate = `${dateStart[1]}/${dateStart[0]} - ${dateEnd[1]}/${dateEnd[0]}`
      this.$forceUpdate()
      this.getBillsSummary()
      this.getBillsSummaryPDR()
    },
    resetPicker() {
      let date = new Date()
      let dateEndDefault = `${date.getFullYear() + 3}-${
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      }-${date.getDate()}`
      let dateStartDefault = new Date(date.setFullYear(date.getFullYear() - 2))
      dateStartDefault = `${dateStartDefault.getFullYear()}-${
        dateStartDefault.getMonth() + 1 < 10
          ? '0' + (dateStartDefault.getMonth() + 1)
          : dateStartDefault.getMonth() + 1
      }-01`
      this.pickerStart = { date: dateStartDefault, show: false }
      this.pickerEnd = { date: dateEndDefault, show: false }
      this.filterSelDate = ''
      this.$forceUpdate()

      this.getBillsSummary()
      this.getBillsSummaryPDR()
    },
    formatTotal: function (value) {
      if (value && parseFloat(value) !== 0) {
        return Number(value).toLocaleString('it-IT', {
          minimumFractionDigits: 2
        })
      } else {
        return '0'
      }
    },
    formatTotalDaPagare: function (value) {
      if (value && parseFloat(value) > 0) {
        return Number(value).toLocaleString('it-IT', {
          minimumFractionDigits: 2
        })
      } else {
        return '0'
      }
    },

    initPdrSummary() {
      this.formatPdrSummary = [
        {
          title: 'Totale emesso',
          total: 0
        },
        {
          title: 'Rate pagate',
          total: 0
        },
        {
          title: 'Rate da pagare',
          total: 0,
          highlight: 0
        },
        {
          title: 'Rate in scadenza',
          total: 0
        },
        {
          title: 'Rate scadute',
          total: 0
        },
        {
          showPayButton: true,
          hasToPay: 0
        }
      ]
    },

    getBillsSummary() {
      this.loaded = false
      let promises = []
      let isRecurrent = ['CCR', 'SDD', 'DPAY']
      this.itemsBillsSummary.forEach((payment) => {
        let data = {
          startDate: this.pickerStart.date
            .split('-')
            .slice(0, 2)
            .reverse()
            .join('/'),
          endDate: this.pickerEnd.date
            .split('-')
            .slice(0, 2)
            .reverse()
            .join('/'),
          clientCode: this.$route.params.clientCode,
          idSintesi: payment.id
        }
        promises.push(
          new Promise((resolve, reject) => {
            this.getBillingSummary(data).then((detail) => {
              if (detail.status === 'OK') {
                payment.result = detail
                resolve(detail)
              } else {
                reject(detail)
              }
            })
          }).catch(() => {
            return { status: 'error' }
          })
        )
      })
      Promise.all(promises, this).then(
        (value) => {
          if (value.find((s) => s.status === 'error')) {
            GenErr.showErrorMessage()
          }
          this.getMethods({ clientCode: this.$route.params.clientCode }).then(
            (response) => {
              let allRecurrent = response
                .map((item) => item.methodType)
                .every((mdp) => isRecurrent.includes(mdp))
              this.hasOneNoRecurrentAtleast = !allRecurrent
              if (
                this.hasOneNoRecurrentAtleast &&
                this.itemsBillsSummary[2].result?.importoTotale > 0
              ) {
                this.formatBillsSummary = [
                  {
                    title: 'Totale emesso',
                    total: this.formatTotal(
                      this.itemsBillsSummary[0].result?.importoTotale
                    )
                  },
                  {
                    title: 'RatePagate',
                    total: this.formatTotal(
                      this.itemsBillsSummary[1].result?.importoTotale
                    )
                  },
                  {
                    title: 'Da pagare',
                    total: this.formatTotalDaPagare(
                      this.itemsBillsSummary[4].result?.importoTotale
                    ),
                    highlight:
                      this.itemsBillsSummary[4].result?.importoTotale > 0
                  },
                  {
                    title: 'In scadenza',
                    total: this.formatTotal(
                      this.itemsBillsSummary[2].result?.importoTotale
                    )
                  },
                  {
                    title: 'Scadute',
                    total: this.formatTotal(
                      this.itemsBillsSummary[3].result?.importoTotale
                    )
                  },
                  {
                    title: 'Rateizzazioni in corso',
                    total: this.formatTotal(
                      this.itemsBillsSummary[6].result?.importoTotale
                    )
                  },
                  {
                    showPayButton: true,
                    hasToPay:
                      this.itemsBillsSummary[4].result?.importoTotale > 0
                  }
                ]
              } else {
                this.formatBillsSummary = [
                  {
                    title: 'Totale emesso',
                    total: this.formatTotal(
                      this.itemsBillsSummary[0].result?.importoTotale
                    )
                  },
                  {
                    title: 'Pagate',
                    total: this.formatTotal(
                      this.itemsBillsSummary[1].result?.importoTotale
                    )
                  },
                  {
                    title: 'Da pagare',
                    total: this.formatTotalDaPagare(
                      this.itemsBillsSummary[4].result?.importoTotale
                    )
                  },
                  {
                    title: 'Scadute',
                    total: this.formatTotal(
                      this.itemsBillsSummary[3].result?.importoTotale
                    )
                  },
                  {
                    title: 'Rateizzazioni in corso',
                    total: this.formatTotal(
                      this.itemsBillsSummary[6].result?.importoTotale
                    )
                  },
                  // { skipSpace: true },
                  {
                    showPayButton: true,
                    hasToPay:
                      this.itemsBillsSummary[4].result?.importoTotale > 0
                  }
                ]
              }
              this.loaded = true
            }
          )
        },
        (reason) => {}
      )
    },

    getBillsSummaryPDR() {
      this.resetPDRPayment()
      this.loaded = false
      let promises = []
      let isRecurrent = ['CCR', 'SDD', 'DPAY']
      this.itemsBillsSummaryPDR.forEach((payment) => {
        let data = {
          startDate: this.pickerStart.date
            .split('-')
            .slice(0, 2)
            .reverse()
            .join('/'),
          endDate: this.pickerEnd.date
            .split('-')
            .slice(0, 2)
            .reverse()
            .join('/'),
          clientCode: this.$route.params.clientCode,
          summaryId: payment.id
        }
        promises.push(
          new Promise((resolve, reject) => {
            this.getBillingSummaryPdr(data).then((detail) => {
              if (detail.status === 'OK') {
                payment.result = detail
                if (detail.totalAmount > 0) {
                  this.areOnlyBollette = false
                }
                resolve(detail)
              } else {
                reject(detail)
              }
            })
          }).catch(() => {
            return { status: 'error' }
          })
        )
      })

      Promise.all(promises, this).then(
        (value) => {
          if (value.find((s) => s.status === 'error')) {
            GenErr.showErrorMessage()
          }
          let hasToPay = false
          let listPdrTitlesTRUE = []
          let listPdrTitlesFALSE = []
          let toDay = new Date()
          toDay.setDate(toDay.getDate() + 5)
          listPdrTitlesTRUE = Object.values(this.pdrDetail).filter(
            (pdr) => pdr.titles
          )
          listPdrTitlesFALSE = Object.values(this.pdrDetail).filter(
            (pdr) => !pdr.titles
          )
          // Verifico se tra i PDR con titles a false hanno un metodo di pagamento ricorrente
          let allRecurrent = listPdrTitlesFALSE
            .map((pdr) => pdr.planPaymentMethod)
            .every((mdp) => isRecurrent.includes(mdp))
          if (
            listPdrTitlesTRUE.length === Object.values(this.pdrDetail).length
          ) {
            hasToPay = false
          } else if (allRecurrent) {
            if (
              listPdrTitlesFALSE
                .reduce((a, pdr) => {
                  return a.concat(pdr.instalments)
                }, [])
                .filter(
                  (instalment) =>
                    instalment.instalmentStatus !== Consts.STATUS_PDR.EXPIRED
                ).length > 0
            ) {
              hasToPay = false
            } else {
              hasToPay = true
            }
          } else {
            hasToPay = true
          }

          this.formatPdrSummary = [
            {
              title: 'Totale emesso',
              total: this.formatTotal(
                this.itemsBillsSummaryPDR[0].result?.totalAmount
              )
            },
            {
              title: 'Rate pagate',
              total: this.formatTotal(
                this.itemsBillsSummaryPDR[1].result?.totalAmount
              )
            },
            {
              title: 'Rate da pagare',
              total: this.formatTotalDaPagare(
                this.itemsBillsSummaryPDR[4].result?.totalAmount
              ),
              highlight: this.itemsBillsSummaryPDR[4].result?.totalAmount > 0
            },
            {
              title: 'Rate in scadenza',
              total: this.formatTotal(
                this.itemsBillsSummaryPDR[2].result?.instalmentsList.reduce(
                  (acc, instalment) => {
                    let dateSplit = instalment.instalmentDueDate.split('/')
                    let dateEnd = new Date(
                      `${dateSplit[1]}/${dateSplit[0]}/${dateSplit[2]}`
                    )
                    if (toDay >= dateEnd.getTime()) {
                      return acc + instalment.residualInstalmentAmount
                    }
                  },
                  0
                )
              )
              // to do
              // filtrare per data in scadenza in 5 giorni e fare la somma dei giorni
            },
            {
              title: 'Rate scadute',
              total: this.formatTotal(
                this.itemsBillsSummaryPDR[3].result?.totalAmount
              )
            },
            {
              showPayButton: true,
              hasToPay:
                hasToPay && this.itemsBillsSummaryPDR[4].result?.totalAmount > 0
            }
          ]
          this.loaded = true
        },
        (reason) => {}
      )
    },
    getContractFileUrl(type) {
      this.dialogDownload = true
      this.showDownload = true

      let data = {
        startDate: this.pickerStart.date,
        endDate: this.pickerEnd.date,
        client: {
          code: this.filteredClientDetails.clientOwnerCode,
          firstName:
            this.filteredClientDetails.firstName ||
            this.filteredClientDetails.businessName,
          lastName: this.filteredClientDetails.lastName
        },
        summaryData: {}
      }

      if (type === 'bollette') {
        this.itemsBillsSummary.forEach((sintesi) => {
          data.summaryData[sintesi.id] = sintesi.result
        })
        this.trackTapEvent('summaryBill_downloadSummary_tap')
      } else {
        this.itemsBillsSummaryPDR.forEach((sintesi) => {
          data.summaryData[sintesi.id] = sintesi.result
        })
        this.trackTapEvent('summaryBill_downloadSummary_tap')
      }

      const url = ENV_CONFIGS.ENV === 'pro' ? '/print' : '/summary/print'
      axiosSorgenia({
        url: url,
        method: 'POST',
        responseType: 'blob',
        data: data
      }).then((resp) => {
        let self = this

        var reader = new FileReader()
        reader.readAsDataURL(resp.data)
        reader.onloadend = async function () {
          var base64data = reader.result

          const blob = await b64ToBlob(
            base64data,
            'documento',
            'application/pdf'
          )
          const blobUrl = URL.createObjectURL(blob)

          self.dialogDownload = false
          self.contractDataUrl = blobUrl // base64data
          self.dialogPdf = true
        }
      })
    },
    cancelDowload() {
      this.showDownload = false
      this.dialogDownload = false
    },
    initAlert() {
      let oldYear = new Date(
        new Date().setFullYear(new Date().getFullYear() - 2)
      )
      let data = {
        startDate: oldYear.toLocaleDateString('it-IT', {
          month: 'numeric',
          year: 'numeric'
        }),
        endDate: new Date().toLocaleDateString('it-IT', {
          month: 'numeric',
          year: 'numeric'
        }),
        clientCode: this.$route.params.clientCode,
        idSintesi: '4'
      }
      this.getBillingSummary(data).then((detail) => {
        this.alertBill = detail?.listBollette.length > 0
      })
      data.summaryId = '4'
      delete data.idSintesi
      this.resetPDRPayment()
      this.getBillingSummaryPdr(data).then((detail) => {
        this.alertPdr = detail?.instalmentsList.length > 0
      })
    },
    handleWindowBlur() {
      this.physiCall.resetAll()
    },
    handleWindowFocus() {
      if (this.physiCall) {
        this.physiCall.resetAll()
      }
      this.physiCall = new PhysiCall(120000, null, null)
      this.physiCall.setJourneySection(Consts.JOURNEY.TIMER_PAGAMENTI)
    }
  },
  mounted() {
    // da decidere al sync
    new TcVarService({
      page: 'Riepilogo e archivio pagament',
      params: this.tabBollettePdr
    }).tcTrackTapEvent()
    window.addEventListener('blur', this.handleWindowBlur)
    window.addEventListener('focus', this.handleWindowFocus)
  },
  // eslint-disable-next-line vue/no-deprecated-destroyed-lifecycle
  beforeDestroy() {
    this.physiCall.clearTimer()
    window.removeEventListener('blur', this.handleWindowBlur)
    window.removeEventListener('focus', this.handleWindowFocus)
  },
  created() {
    this.physiCall = new PhysiCall(120000, null, null)
    this.physiCall.setJourneySection(Consts.JOURNEY.TIMER_PAGAMENTI)
    this.trackTapEvent('supply_summaryBill_tap')
    this.trackTapEvent('payment_summary_viewed')
    this.getDetailForClient({ clientOwnerCode: this.$route.params.clientCode })
    this.initAlert()
    this.initPdrSummary()
    this.refreshTotal()

    this.getClientsForAccount()
      .then((resp) => {
        if (resp) {
          this.clientsHaveSupplies()
        }
      })
      .catch((error) => {
        GenErr.handleGenericError(error && error.message)
      })
  }
}
</script>
<style lang="scss">
.customCheck {
  .v-icon.v-icon {
    font-size: 30px;
  }
  .v-label {
    font-size: 14px;
    color: black;
  }
}
</style>
<style lang="scss" scoped>
@import '../../styles/dialog_pay_bills/dialogPayBills.scss';
.scrollableList {
  height: 400px;
  overflow-y: auto;
  &::-webkit-scrollbar-button {
    display: block;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($srg-lightgray, 0.7);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $srg-lightgray;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
}

.loader {
  margin-top: 80px;
}

.paymentSummary {
  &__navigation {
    display: flex;
    align-items: center;
    h5 {
      color: #000;
      &:first {
        cursor: pointer;
      }
    }
    img {
      margin: 0 8px;
    }
  }
  .disclaimer {
    margin: 20px;
    display: flex;
    justify-content: center;
    width: inherit;
    &__pdr {
      width: 566px;
    }
  }
  .v-item-group.v-tabs-items {
    padding-top: 48px;
  }

  &__title {
    font-size: 40px;
    line-height: 42px;
    letter-spacing: 0.8px;
    font-weight: 500;
    margin-top: 40px;
  }
  &__subtitle {
    font-size: 20px;
    line-height: 20px;
    margin-top: 24px;
    color: #000;
  }
  &__filtered {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      display: flex;
      align-items: flex-start;
      margin-right: 35px;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.2px;
      color: $srg-blue;
      &:before {
        margin-right: 8px;
        content: url('../../assets/icons/close-blu.svg');
        fill: $srg-blue !important;
        width: 24px;
      }
    }
  }
  &__result {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 64px;
    border-radius: 20px;
    background-color: #f8f9fc;
  }
  &__info span {
    margin: 0 auto;
    font-size: 14px;
    color: black;
    margin-top: 16px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 2px solid $srg-lightgray;
    &.pay {
      border-bottom: none;
    }
    &.skipped {
      border-bottom: none;
    }
    .field {
      font-size: 16px;
      color: #000;
      letter-spacing: 1px;
    }
    .total {
      font-size: 24px;
      font-weight: bold;
    }
    &.highlighted {
      .field {
        font-weight: bold;
        color: black;
      }
      .total {
        color: black;
      }
    }
    .paymentButton {
      color: white;
      background-color: $srg-blue !important;
      height: 50px;
      width: 320px;
    }
  }
  &__clientDetail {
    margin-top: 24px;
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__disclamer {
    font-size: 20px;
    color: #000;
    font-style: normal;
    letter-spacing: 0.5px;
    display: flex;
    justify-content: center;
  }
  &__selectClient {
    margin-top: 34px;
    margin-bottom: 48px;
    display: flex;
    justify-content: center;
  }
  &__download {
    h5 {
      font-size: 18;
      font-weight: bold;
      letter-spacing: 1.2px;
    }
    display: flex;
    margin-left: auto;
    align-items: center;
    color: $srg-blue;
    cursor: pointer;
    &:before {
      content: url('../../assets/ic_download_small_blue.svg');
      margin-right: 10px;
    }
  }
}
.supply-dialog {
  padding: 40px;
  h2 {
    line-height: normal;
  }
  h3 {
    font-weight: bold;
  }
}
.btn-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 177px;
  height: 56px;
  border-radius: 20px;
  border: solid 1.1px #dbdbdb;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.2px;
  color: $srg-blue;
  cursor: pointer;
  &:before {
    content: url('../../assets/icons/calendar.svg');
    width: 18px;
    margin-right: 16px;
    padding-top: 5px;
  }
  &:after {
    content: url('../../assets/icons/chevron.svg');
    width: 18px;
    margin-left: 8px;
    padding-top: 5px;
  }
  &.big {
    width: 310px;
    height: 66px;
    padding-left: 26px;
    justify-content: start;
    &:before {
      width: 26px;
    }
    &:after {
      content: '';
    }
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #b7bfd2;
  border-bottom: 3px solid #b7bfd2;
}

.notification {
  width: 10px;
  background: #e84182;
  height: 10px;
  display: inline-block;
  margin-left: 8px;
  border-radius: 50%;
}

.limitedTo11Cols {
  width: calc(100% / 12 * 11);
}

.small-icon-Ele {
  background-image: url('../../assets/icons/ic-light-blu.svg');
  background-size: 24px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
}

.small-icon-Gas {
  background-image: url('../../assets/icons/ic-fire-blu.svg');
  background-size: 24px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
}
</style>
