<template>
  <div class="page">
    <TcVars />
    <div class="mt-8">
      <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
        <template v-slot:divider>
          <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
        </template>
      </v-breadcrumbs>
      <div class="HeadingXXL">Aggiungi un nuovo metodo di pagamento</div>
    </div>
    <PaymentMethodForm :data.sync="data" @submit="request" type="block" />
    <PopupErrorIban ref="popup" />
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import PaymentMethodForm from '@/components/upselling/fiber/PaymentMethodForm'
import PopupErrorIban from '@/components/upselling/fiber/PopupErrorIban'
import Consts from '@/js/constants'
import GenErr from '@/js/genericErrors'
import { checkIban } from '@/js/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { capitalize } from '@/js/utils'

export default {
  name: 'NewPaymentMethod',
  props: {},
  components: {
    PaymentMethodForm,
    PopupErrorIban,
    TcVars
  },
  data: () => ({
    details: null,
    data: null,
    loading: false,
    PAYMENT_METHODS: Consts.PAYMENT_METHODS
  }),
  beforeMount() {
    if (!this.$store.state.supply.supplyDetail) {
      this.$store
        .dispatch('supply/getDetail', {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
  },
  computed: {
    ...mapGetters('account', ['clientDetails']),
    ...mapGetters('supply', ['supplyDetail']),

    disabled() {
      return !this.data
    },
    breadcrumbsItems() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let res
      res = [
        { to: { name: 'home' }, text: 'Home' },
        {
          to: { name: 'ownerSupplies' },
          text: 'Servizi e Consumi',
          exact: true
        },
        { to: { name: 'supplyDetail' }, text: address, exact: true },
        {
          to: { name: 'paymentAction' },
          text: 'Metodo di pagamento',
          exact: true
        },
        {
          to: { name: this.$router.currentRoute.name },
          text: 'Nuovo metodo'
        }
      ]

      return res
    }
  },
  watch: {
    data(val) {
      if (val.type && val.type === this.PAYMENT_METHODS.CREDIT_CARD) {
        const body = {
          xaliasPan: val.aliasPan,
          circuit: val.creditCardCircuit,
          creditCardCode: val.creditCardNumber.replace(/\*/g, ''),
          expireDateCC: val.creditCardExpirationDate,
          login: this.$store.state.session.username,
          mcsExternalId: this.clientDetails.clientCode,
          codicePunto: this.$route.params.supplyCode,
          crecurr: val.crecurr
        }

        this.loading = true
        this.addMdPCreditCard(body)
          .then((res) => {
            this.$store.commit(
              'supply/resetSupplyDetailByCode',
              this.$route.params.supplyCode
            )
            this.resetState()
            this.trackTapEvent('supply_changePaymentMethodDone_view')
            this.$router.push({
              name: 'promSuccess',
              params: { type: 'payment' }
            })
            this.loading = false
          })
          .catch((error) => {
            GenErr.handleGenericError(error && error.message)
            GenErr.showErrorMessage(
              "Si è verificato un errore durante l'operazione."
            )
            this.loading = false
          })
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    request() {
      const body = {
        mcsExternalId: this.clientDetails.clientCode,
        codicePunto: this.$route.params.supplyCode,
        login: this.$store.state.session.username,
        bankIBAN: this.data.iban,
        nameAlias:
          this.clientDetails.clientType === 'RES'
            ? this.data.name + this.data.surname
            : this.clientDetails.businessName,
        fiscalCodeHolder: this.data.fiscalCode,
        firstName:
          this.data.name ||
          this.clientDetails.businessName.split(' ')[0] ||
          ' ',
        lastName:
          this.data.surname ||
          this.clientDetails.businessName.split(' ')[1] ||
          ' ',
        fiscalCodeCust: this.data.fiscalCode,
        codiceCliente: this.clientDetails.clientCode
      }
      let isValidIban = checkIban(document.getElementById('newIban'))
      isValidIban
        .then((resp) => {
          this.loading = true
          this.ibanRequest(body)
            .then((res) => {
              this.loading = false
              this.$store.commit(
                'supply/resetSupplyDetailByCode',
                this.$route.params.supplyCode
              )
              this.resetState()
              this.trackTapEvent('supply_changePaymentMethodDone_view')
              this.$router.push({
                name: 'promSuccess',
                params: { type: 'payment' }
              })
            })
            .catch((error) => {
              if (error.message === 'SAME_CASE_IN_PROGRESS') {
                GenErr.showErrorMessage(
                  "E' già in lavorazione una richiesta dello stesso tipo."
                )
              } else if (error.message === 'INVALID_FISCAL_CODE') {
                GenErr.showErrorMessage('Il codice fiscale non è valido.')
              } else {
                GenErr.handleGenericError(error && error.message)
              }
              this.loading = false
            })
        })
        .catch((resp) => {
          console.log('Servixio in errore', resp)
          // this.$refs.popup.typeError(resp)
        })
    },
    typeError(val) {
      this.dialog.text = val
      this.dialog.show = true
    },
    ...mapActions('payment', ['ibanRequest', 'addMdPCreditCard']),
    ...mapMutations('supply', ['resetState'])
  }
}
</script>

<style lang="scss" scoped></style>
