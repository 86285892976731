<template>
  <v-dialog
    v-model="showLoader"
    persistent
    max-width="600"
    max-height="600"
    overlay-opacity="0.6"
    content-class="loaderContainer"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </v-dialog>
</template>
<script>
export default {
  name: 'LoaderDialog',
  props: {
    loaderDialogOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLoader: {
      get() {
        return this.loaderDialogOpen
      },
      set(val) {
        this.$emit('update:customDialogOpen', val)
      }
    }
  }
}
</script>
<style lang="scss">
.loaderContainer {
  box-shadow: none;
  display: contents;
  .loader-container {
    background-color: transparent;
    box-shadow: none;
  }
  .v-dialog {
    box-shadow: none !important;
  }
}
</style>
