<template>
  <div>
    <div class="page" v-if="Object.values(visibleCategories).length === 0">
      Loading ...
    </div>
    <div
      v-if="Object.values(visibleCategories).length > 0"
      class="support page"
    >
      <!-- ------ Percorso ------ -->
      <TcVars env_template="mysorgenia_supporto" />
      <v-row class="std-side-padding">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
            <template v-slot:divider>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
      <!-- --------------------- -->

      <!-- ------ Pagina ------- -->
      <div>
        <div class="HeadingsTitleXXL support support__title">
          Seleziona il servizio per cui desideri ricevere supporto
        </div>
        <div class="service service__1">
          <div v-for="(item, key) in visibleCategories" :key="key">
            <service-button
              :data="item"
              :index="key"
              :length="visibleCategories.length"
            />
          </div>
        </div>
      </div>
      <div v-if="Object.values(visibleCategories).length > 0">
        <div class="HeadingsTitleXL support support__sub-title">
          Monitora le tue richieste
        </div>
        <div class="service service__2" @click="selectClient()">
          <div class="item item__2">
            <div class="d-flex">
              <div>
                <img src="@/assets/icons/Icon.svg" width="20" />
              </div>
              <div class="ButtonL ml-2">
                Stato richieste
                <span v-if="hasNotifications" class="notify">{{
                  totalNotifications
                }}</span>
              </div>
            </div>
            <div>
              <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="showSelectClient" max-width="1140">
      <v-row>
        <v-col class="owner-dialog">
          <img
            @click="showSelectClient = false"
            src="../../assets/ic_chevron_top_black.png"
          />
          <h2>Seleziona profilo</h2>
          <h4>
            Seleziona il profilo di cui vuoi verificare lo stato delle richieste
          </h4>
          <ExtensibleButtonGroup
            :per-row="2"
            v-model="selectedClient"
            :content="false"
            groupName="clients"
          >
            <ExtensibleButton
              v-for="(client, i) in clients"
              :key="i"
              :name="client.clientOwnerCode"
            >
              <template v-slot:label>
                <h5>
                  {{
                    client.clientOwnerType === 'RES'
                      ? client.firstName + ' ' + client.lastName
                      : client.businessName
                  }}
                </h5>
                <span v-if="client.notifications > 0" class="notify">{{
                  client.notifications
                }}</span>
              </template>
            </ExtensibleButton>
          </ExtensibleButtonGroup>
          <v-row>
            <v-col cols="6" :offset="4">
              <v-btn
                color="primary"
                :disabled="!selectedClient"
                large
                width="303"
                class="v-btn--shadowed"
                @click="openStatusesOrDreamService()"
                >Prosegui</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>

<script>
import TcVars from '@/components/tcTracker/TcVars'
import { checkMobile } from '@/js/utils.js'
import { mapActions, mapGetters } from 'vuex'
import ServiceButton from '../../components/support/ServiceButton'

export default {
  name: 'supportComponent',
  components: {
    serviceButton: ServiceButton,
    TcVars
    // InstrumentItem: () => import('@/components/support/InstrumentItem')
  },
  data() {
    return {
      supportList: [
        { id: '3', name: 'Richiedi informazioni' },
        { id: '4', name: 'Contattaci' }
      ],
      selectedClient: '',
      model: 0,
      showSelectClient: false,
      isMobile: checkMobile(),
      isSmallDevice: window.innerWidth <= 480
    }
  },
  watch: {
    // Controlla se visibleCategories subisce dei cambiamenti - questa gestione andrebbe cambiata quando si modificherà l'UI per il caricamento delle categorie
    visibleCategories(newValue) {
      if (Object.values(newValue).length > 0) {
        this.trackTapEvent({
          name: 'support_viewed'
        })
      }
    }
  },
  mounted() {
    // Controlla se visibleCategories è già popolato - questa gestione andrebbe cambiata quando si modificherà l'UI per il caricamento delle categorie
    if (Object.values(this.visibleCategories).length > 0) {
      this.trackTapEvent({
        name: 'support_viewed'
      })
    }
  },
  computed: {
    ...mapGetters('support', ['visibleCategories']),
    ...mapGetters('feedback', ['client']),
    ...mapGetters('welcomekit', ['tutorial', 'survey']),
    ...mapGetters('account', [
      'getClientsForAccount',
      'totalNotifications',
      'accountClients'
    ]),
    ...mapGetters('interactions', ['statuses']),

    clients() {
      return this.accountClients
    },
    hasNotifications() {
      return this.totalNotifications > 0
    },
    hasDreamService() {
      let clientCode = this.selectedClient
      return this.clients[clientCode].hasDreamService
    },
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: this.$router.currentRoute.name }, text: 'Assistenza' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('interactions', ['setStatuses']),
    ...mapActions('account', ['getMainClient']),
    buttonClicked() {
      this.$router.push('/private/tutorial')
    },
    openStatusesOrDreamService() {
      this.trackTapEvent({
        name: 'requestStatus_chooseClient_tap',
        params: {
          client_code: this.clients[this.selectedClient].clientOwnerCode,
          client_type: this.clients[this.selectedClient].clientOwnerType
        }
      })
      this.setStatuses(this.selectedClient).then(() => {
        if (this.hasDreamService && this.statuses.length === 0) {
          this.openDreamServiceList()
        } else {
          this.openStatuses()
        }
      })
    },
    openStatuses() {
      this.setStatuses(this.selectedClient).then(() => {
        this.$router.push({
          name: 'Statuses',
          params: { clientCode: this.selectedClient }
        })
      })
    },
    openDreamServiceList() {
      this.trackTapEvent('dreamService_requestProgress_tap')
      let destination = this.isMobile
        ? 'publicDreamServiceList'
        : 'DreamServiceList'
      this.$router.push({
        name: destination,
        params: { clientCode: this.selectedClient }
      })
    },
    selectClient() {
      this.trackTapEvent('support_statusOfRequests_tap')
      if (Object.keys(this.clients).length > 1) {
        this.selectedClient = null
        this.showSelectClient = true
      } else if (Object.keys(this.clients).length === 1) {
        this.selectedClient = Object.values(this.clients)[0].clientOwnerCode
        this.openStatusesOrDreamService()
      }
    }
  }
}
</script>

<style lang="scss">
.service {
  border-radius: 24px;
  box-shadow: 0px 2px 4px 0px #2c354214;
  box-shadow: 0px 1px 8px 0px #2c354214;
  box-shadow: 0px 2px 24px 0px #2c35420f;
  width: 754px;
  margin-bottom: 48px;
  &__1 {
    border: solid 1px $neutri-neutral-20;
  }
  &__2 {
    border: solid 1px $neutri-neutral-30;
  }
  .item {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    cursor: pointer;
    color: $srg-blue;
    &__1 {
      padding: 24px;
      height: 73px;
    }
    &__2 {
      padding: 16px;
      height: 57px;
    }
  }
  .border {
    border-bottom: solid 1px $neutri-neutral-20;
  }
}

.link {
  cursor: pointer;
  color: #12256a;
  font-size: 18px;
  letter-spacing: 1.2px;
}

.notify {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $srg-green;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  position: relative;
  bottom: 3px;
}

.owner-dialog .notify {
  position: relative;
  left: 10px;
  bottom: 10px;
  padding-top: 2px;
}

.support {
  padding-top: 24px;
  &__title {
    padding-bottom: 48px;
  }
  &__sub-title {
    padding-top: 48px;
    padding-bottom: 32px;
  }

  &__carousel {
    position: relative;

    .v-carousel__controls__item {
      &.v-btn {
        background: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        margin: 0;
      }

      &.v-item--active {
        .v-icon {
          color: $srg-blue;
        }
      }

      .v-icon {
        color: #bbb;
        font-size: 24px !important;
      }
    }

    &-controls {
      width: 100%;
      position: absolute;
      top: calc(50% - 45px);

      .control {
        font-size: 40px;
        color: #12256a;
        position: absolute;

        &--left {
          left: -30px;
        }

        &--right {
          right: -30px;
        }
      }
    }
  }
}

.v-dialog__content {
  .owner-dialog {
    background: white;
    padding: 48px 58px 24px 48px;
    border-radius: 40px;
    width: 1140px;
    position: relative;
    img {
      position: absolute;
      top: 30px;
      right: 30px;
      cursor: pointer;
    }
    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .owner {
      display: flex;
      align-items: center;
      width: 300px;
      height: 66px;
      border-radius: 20px;
      box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.15);
      background-color: #fff;
      padding: 0 32px;
      margin-bottom: 24px;
      cursor: pointer;
      &.disabled {
        cursor: initial;
        h5 {
          opacity: 0.3;
        }
      }
    }
  }
}
</style>
