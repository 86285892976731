import { ENV_CONFIGS } from '@/js/configs.js'
// import TcVarService from '../../js/service/tcVarService'
import { tcVarService } from '../../main'
import AmplitudeService from '@/js/service/amplitudeService'

const maxLenghtCodeList = 36

const setClientCodeList = (userProps, accountClients) => {
  let count = 1
  let start = 0

  // let mock = ['00000001', '00000002', '000003', '00000004', '000000000005', '0000006', '0000000007']
  Object.keys(accountClients).reduce((accumulator, code, index) => {
    let totalLength = accumulator + code.length
    if (totalLength + 1 > maxLenghtCodeList) {
      count++
      start = index
      totalLength = 0
    }
    const codeCount = 'client_code_list_' + count

    userProps[codeCount] = Object.keys(accountClients)
      .map((code) => String(code))
      .slice(start, index + 1)
      .join('|')
    userProps[codeCount] = userProps[codeCount].split('|').sort().join('|')
    return userProps[codeCount].length
  }, 0)

  return userProps
}

const nativeLogEvent = (name, params) => {
  if (!name) {
    return
  }

  if (window.AnalyticsWebInterface) {
    // Call Android interface
    window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params))
  } else if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.firebase
  ) {
    // Call iOS interface
    var message = {
      command: 'logEvent',
      name: name,
      parameters: params
    }
    window.webkit.messageHandlers.firebase.postMessage(message)
  } else {
    // No Android or iOS interface found
    console.log('No native APIs found.')
  }
}

export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
    userProps: {
      account_type: null,
      fiber_eligible: null,
      has_digital_points: null,
      has_no_digital_points: null,
      main_client_code: null,
      idLogin: null,
      cfpEventRegister: false
    }
  },
  getters: {
    userProps(state) {
      return state.userProps
    }
  },
  mutations: {
    updateUserProperties(state, userProps) {
      state.userProps = userProps
    },
    setCfpEventRegister(state, cfpEventRegister) {
      state.userProps.cfpEventRegister = cfpEventRegister
    }
  },
  actions: {
    setupTracking({ state, commit, dispatch, rootGetters }) {
      if (ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
        return
      }
      if (!state.userProps.cfpEventRegister) {
        const handleMessage = (event) => {
          if (event.data?.postMessageEvent === 'cfp-trackTapEvent') {
            dispatch('trackTapEvent', event.data.params)
          }
        }
        window.addEventListener('message', handleMessage)
        commit('setCfpEventRegister', true)
      }

      const accountProperties = rootGetters['account/accountProperties']
      const mainClient = rootGetters['account/mainClient'] || {}
      const accountClients = rootGetters['account/accountClients'] || []
      let userProps = {
        account_type: rootGetters['session/username']
          ? accountProperties.detailedType
          : 'anonimo',
        fiber_eligible: accountProperties.isFiberEligible ? 'true' : 'false',
        has_digital_points: accountProperties.hasAtLeast1Digital
          ? 'true'
          : 'false',
        has_no_digital_points: accountProperties.hasAtLeast1NonDigital
          ? 'true'
          : 'false',
        main_client_code: mainClient.isOnlyFV
          ? ''
          : String(mainClient.clientOwnerCode),
        idLogin: rootGetters['account/getUserIdMd5']
      }

      userProps = setClientCodeList(userProps, accountClients)

      commit('updateUserProperties', userProps)

      console.log(
        `Successfully identified the user for GA 360 as '${
          rootGetters['session/username'] || 'anonimo'
        }'`
      )
    },
    trackTapEvent(ctx, event) {
      const amplitudeService = new AmplitudeService()
      if (ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
        return
      }

      let eventName, params, toNative
      let userProps = ctx.state.userProps
      delete userProps.cfpEventRegister

      if (typeof event === 'string') {
        eventName = event
        params = {}
        toNative = false
      } else if (typeof event === 'object') {
        eventName = event.name
        params = event.params || {}
        if (params.client_code) {
          params.client_code = String(params.client_code)
        }
        toNative = event.toNative
      } else {
        return Error('Invalid parameters')
      }

      if (!toNative) {
        tcVarService.tcTrackTapEvent({
          event_name: eventName,
          ...params,
          ...userProps
        })
        amplitudeService.trackEvent(eventName, { ...params, ...userProps })
      } else {
        nativeLogEvent(eventName, params)
      }
    }
  }
}
