<template>
  <div
    class="account afterthought"
    v-if="status === 'success' && contract && clientDetail"
  >
    <v-row class="row std-side-padding account__registry">
      <div class="registry__title">
        <h1>Contratti</h1>
      </div>
    </v-row>
    <v-row class="std-side-padding">
      <v-col class="col-6">
        <v-row class="registry__subtitle" no-gutters>
          <h2>Riepilogo dati di contratto</h2>
        </v-row>

        <v-row
          v-if="clientDetail.clientType === 'RES'"
          class="registry__infoTitle"
          no-gutters
        >
          <v-col class="col-6">
            <h6>Nome</h6>
            <h5>{{ clientDetail.name }}</h5>
          </v-col>
          <v-col class="col-6">
            <h6>Cognome</h6>
            <h5>{{ clientDetail.surname }}</h5>
          </v-col>
        </v-row>
        <v-row v-else class="registry__infoTitle" no-gutters>
          <v-col>
            <h6>Ragione sociale</h6>
            <h5 v-text="clientDetail.businessName" />
          </v-col>
        </v-row>
        <v-row class="registry__infoTitle" no-gutters>
          <v-col v-if="clientDetail.clientType === 'RES'" class="col-6">
            <h6>Codice Fiscale</h6>
            <h5>{{ clientDetail.fiscalCode }}</h5>
          </v-col>
          <v-col v-else class="col-6">
            <h6>Partita Iva</h6>
            <h5>{{ clientDetail.VATNumber || clientDetail.fiscalCode }}</h5>
          </v-col>
          <v-col class="col-6">
            <h6>Codice Cliente</h6>
            <h5>{{ clientDetail.clientCode }}</h5>
          </v-col>
        </v-row>
        <v-row class="registry__infoTitle" no-gutters>
          <v-col class="col-6">
            <h6>Codice Contratto</h6>
            <h5>
              {{
                selectedContractType === 'commodity'
                  ? contract.cdPropostaContratto || contract.codProposta
                  : contract.cdPropostaContrattoFibra
              }}
            </h5>
          </v-col>
          <v-col class="col-6">
            <h6>Tipologia utenza</h6>
            <h5>
              {{
                consts.SUPPLY_TYPES[
                  selectedContractType === 'commodity'
                    ? contract.commodityType.toLowerCase()
                    : contract.commodityTypeFibra
                ]
              }}
            </h5>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-6">
        <v-row class="registry__subtitle" no-gutters>
          <h2>Motivi del ripensamento</h2>
        </v-row>
        <v-row class="registry__subtitle" no-gutters>
          <v-radio-group color="#12256A" row="row" v-model="privacy">
            <div class="subtitle__custom-check">
              <v-radio value="1" :label="labelRadio[0]"></v-radio>
            </div>
            <div class="subtitle__custom-check">
              <v-radio value="2" :label="labelRadio[1]"></v-radio>
            </div>
            <div class="subtitle__custom-check">
              <v-radio value="3" :label="labelRadio[2]"></v-radio>
            </div>
            <div class="subtitle__custom-check">
              <v-radio value="4" :label="labelRadio[3]"></v-radio>
            </div>
            <div class="subtitle__custom-check">
              <v-radio value="5" :label="labelRadio[4]"></v-radio>
            </div>
            <div class="subtitle__custom-check">
              <v-radio value="6" :label="labelRadio[5]"></v-radio>
            </div>
          </v-radio-group>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="text-center" no-gutters>
      <v-col>
        <MYSButton
          buttonText="Prosegui"
          :disabled="!privacy"
          @buttonClick="areYouSureDialog = true"
          buttonClass="primaryButtonS mb-4"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="areYouSureDialog" max-width="576">
      <div class="ripensamento-modal">
        <!-- <img src="@/assets/ic_chevron_top_black.png" alt="close" class="modal-close" @click="areYouSureDialog = false" /> -->
        <div class="ripensamento-modal__title">
          Stai rinunciando ad entrare in fornitura con Sorgenia. Sei sicuro di
          voler proseguire?
        </div>

        <v-row v-if="waitingForResponse">
          <v-col class="text-center">
            <v-progress-circular :size="50" indeterminate></v-progress-circular>
          </v-col>
        </v-row>
        <div v-else class="ripensamento-modal__continue">
          <MYSButton
            buttonText="Annulla"
            @buttonClick="areYouSureDialog = false"
            buttonClass="primaryInvertedButtonL"
          />
          <MYSButton
            buttonText="Prosegui"
            @buttonClick="sendAfterthoughtRequest"
            buttonClass="primaryButtonL"
          />
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="576" :persistent="true">
      <div class="ripensamento-modal">
        <div class="ripensamento-modal__title">
          Il tuo contratto con Sorgenia non ha più validità
        </div>
        <div class="ripensamento-modal__accept" align="left">
          Riceverai un’email di conferma.
        </div>
        <div class="ripensamento-modal__continue full-bt-group">
          <router-link :to="{ name: 'home' }">
            <MYSButton
              width="260"
              buttonText="Torna alla homepage"
              buttonClass="primaryButton"
            />
          </router-link>
        </div>
      </div>
    </v-dialog>
  </div>
  <div v-else>
    <v-row class="row std-side-padding account__registry">
      <div class="registry__title">
        <h1>Contratti</h1>
      </div>
    </v-row>
    <v-row class="std-side-padding">
      <v-col class="col-6">
        <h4>
          Il contratto selezionato non è stato trovato. Tornare alla pagina di
          Account e selezionare un contratto.
        </h4>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import GenErr from '@/js/genericErrors'
import consts from '@/js/constants'
import MYSButton from '../../components/global/MYSButton.vue'

export default {
  name: 'afterthoughtComponent',
  components: { MYSButton },
  data() {
    return {
      dialog: false,
      areYouSureDialog: false,
      waitingForResponse: false,
      privacy: null,
      consts: consts,
      labelRadio: [
        'Ho cambiato idea',
        "Ritengo l'offerta meno conveniente di altre presenti sul mercato",
        "Un familiare non è d'accordo con il cambio di utenza",
        'Ho ricevuto una proposta più vantaggiosa',
        'Mi hanno sconsigliato di entrare in fornitura con Sorgenia',
        'Altro'
      ]
    }
  },
  computed: {
    status() {
      return 'success'
    }
  },
  methods: {
    ...mapActions('supply', ['getSupplies', 'requestAfterthought']),
    ...mapActions('analytics', ['trackTapEvent']),
    sendAfterthoughtRequest() {
      this.waitingForResponse = true
      let self = this
      this.getSupplies()
        .then((supplyList) => {
          this.requestAfterthought({
            clientDetail: self.clientDetail,
            serviceType:
              self.selectedContractType.toLowerCase() === 'fiber'
                ? self.contract.commodityTypeFibra
                : self.contract.commodityType,
            contract: self.contract,
            motivazioneRipensamento: self.labelRadio[parseInt(self.privacy) - 1]
          })
            .then((resp) => {
              if (resp.status === 500 || resp.status === 400) {
                this.areYouSureDialog = false
                GenErr.showErrorMessage()
              } else {
                let anticipationRequests = JSON.parse(
                  localStorage.getItem('anticipationRequests') || null
                )
                anticipationRequests = anticipationRequests || {}
                anticipationRequests[self.contract.cdPropostaContratto] = true
                localStorage.setItem(
                  'anticipationRequests',
                  JSON.stringify(anticipationRequests)
                )

                let afterthoughtRequests = JSON.parse(
                  localStorage.getItem('afterthoughtRequests') || null
                )
                afterthoughtRequests = afterthoughtRequests || {}

                const contractId =
                  self.selectedContractType === 'commodity'
                    ? self.contract.cdPropostaContratto ||
                      self.contract.codProposta
                    : self.contract.cdPropostaContrattoFibra
                afterthoughtRequests[contractId] = true
                localStorage.setItem(
                  'afterthoughtRequests',
                  JSON.stringify(afterthoughtRequests)
                )

                this.areYouSureDialog = false
                this.dialog = true
                this.trackTapEvent({
                  name: 'account_contractsRethinkingDone_view',
                  params: {
                    client_code: self.clientDetail.clientCode,
                    client_type: self.clientDetail.clientType?.toLowerCase(),
                    supply_type:
                      self.selectedContractType === 'FIBRA'
                        ? self.contract.commodityTypeFibra.toLowerCase()
                        : self.contract.commodityType.toLowerCase()
                  }
                })
              }
            })
            .catch((error) => {
              // We do not expect any business logic error, so show an alert with generic error
              GenErr.handleGenericError(error && error.message)
              GenErr.showErrorMessage()
            })
        })
        .catch((error) => {
          this.areYouSureDialog = false
          // We do not expect any business logic error, so show an alert with generic error
          GenErr.handleGenericError(error && error.message)
        })
    }
  },
  created() {
    this.clientDetail = this.$route.params.clientDetail
    this.contract = this.$route.params.selectedContract
    this.selectedContractType = this.$route.params.selectedContractType
  }
}
</script>
<style lang="scss" scoped>
.account {
  margin-top: 30px;

  .account__registry {
    margin-bottom: 30px;

    .registry__title {
      h1 {
        font-weight: bold;
      }
    }
  }

  .registry__subtitle {
    margin-top: 30px;

    h2 {
      font-weight: bold;
    }
  }
}

.afterthought {
  .registry__infoTitle {
    padding: 36px 0;

    h6 {
      color: #000000;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    h5 {
      color: #000000;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.subtitle__custom-check {
  padding: 10px 0;
}

.ripensamento-modal {
  &__title {
    margin-bottom: 20px;
  }
}
</style>
