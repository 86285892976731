<template>
  <layout header-size="small">
    <template v-slot:header>
      <v-col cols="6">
        <v-breadcrumbs
          :items="breadcrumbsItems"
          divider="<"
          class="pb-0 reverse-breadcrumbs"
        >
          <template v-slot:divider>
            <img
              src="@/assets/icons/chevron-sx-blue.svg"
              width="16"
              height="16"
            />
          </template>
        </v-breadcrumbs>
        <h1 class="HeadingsTitleXXL">Richiedi il tuo premio</h1>
      </v-col>
      <v-spacer />
      <v-col cols="5">
        <v-img
          :src="require('@/assets/greeners/svg/logoGreeners_new.svg')"
          contain
          width="360"
        />
      </v-col>
    </template>
    <v-col v-if="email" class="gift-info pa-0 mb-8">
      <v-row class="mt-12">
        <v-col cols="12" class="pb-0">
          <p class="HeadingL mb-2">Richiedi il tuo premio</p>
          <p class="BodyL">
            Ti invieremo il premio all'indirizzo email presente nel tuo profilo:
            <strong>{{ email }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="mb-6">
        <v-col cols="12" class="pb-0">
          <p class="BodyL-Strong mb-2">
            Vuoi ricevere il premio ad un nuovo indirizzo?
          </p>
          <p class="BodyL">
            Per modificarlo
            <router-link
              class="ButtonM-Underline"
              :to="{
                name: 'Contacts',
                params: { user: mainClientLoyaltySelected.clientOwnerCode }
              }"
              >contatta l’assistenza</router-link
            >: riceverai i premi e tutte le comunicazioni da parte di Sorgenia
            al nuovo indirizzo email da te scelto.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="pl-0">
          <v-btn
            :loading="isSending"
            class="v-btn--shadowed"
            color="primary"
            x-large
            width="265"
            @click="sendRequest"
          >
            Conferma richiesta
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-else class="gift-info pa-0 mb-8">
      <v-row class="mt-12">
        <v-col cols="12" class="pb-0">
          <p class="HeadingL mb-2">Richiedi il tuo premio</p>
          <p class="BodyL">
            Per richiedere il tuo premio è necessario aggiungere un indirizzo
            email a cui riceverai i premi e tutte le comunicazioni di
            Sorgenia.<br />
            Per aggiungerlo
            <router-link
              class="ButtonM-Underline"
              :to="{
                name: 'Contacts',
                params: { user: mainClientLoyaltySelected.clientOwnerCode }
              }"
              >contatta l’assistenza</router-link
            >.
          </p>
        </v-col>
      </v-row>
    </v-col>
  </layout>
</template>

<script>
import Layout from '../Layout'
import { mapActions, mapGetters } from 'vuex'
import { AlertService } from '../../../js/service/alertService'

export default {
  name: 'GiftEmailShippingPage',
  props: {
    doubleGift: { type: Boolean, default: false }
  },
  components: {
    layout: Layout
  },
  data: () => ({
    email: '',
    errorMessage: '',
    isSending: false,
    originRoute: ''
  }),
  beforeCreate() {
    if (!this.$route.params.catalog) {
      this.$router.push({ name: 'loyaltyPage' })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path.includes('/private/loyalty/gifts')) {
        vm.originRoute = 'welcomeGift'
      } else {
        vm.originRoute = 'catalogue'
      }
    })
  },
  mounted() {
    this.getMainClient().then((resp) => {
      if (this.mainClientLoyaltySelected) {
        resp = this.mainClientLoyaltySelected
      }
      this.getDetailForClient({ clientOwnerCode: resp.clientOwnerCode }).then(
        (resp) => {
          this.email = resp.contactMail || ''
        }
      )
    })
  },
  computed: {
    ...mapGetters('account', [
      'clientDetails',
      'mainClient',
      'mainClientLoyaltySelected'
    ]),
    ...mapGetters('loyalty', ['awardItem']),
    breadcrumbsItems() {
      let res = [
        {
          to: { name: this.originRoute },
          text: 'Torna ai tuoi Premi',
          exact: true
        },
        { to: { name: this.$router.currentRoute.name }, text: '' }
      ]

      return res
    }
  },
  methods: {
    ...mapActions('account', ['getDetailForClient', 'getMainClient']),
    ...mapActions('loyalty', ['requestAward']),
    sendRequest() {
      this.isSending = true
      const {
        businessName,
        mobilePhone,
        birthPlace,
        residentialCAP,
        residentialNation,
        residentialRegion,
        residentialAddress
      } = this.clientDetails
      var body = {}
      if (
        this.awardItem.attributes &&
        !!this.awardItem.attributes.is_required_shipping
      ) {
        body = {
          catalog_name: this.$route.params.catalog,
          prizeoption_id: this.$route.params.id,
          shipping_email: this.email,
          shipping_receiver_name: businessName,
          shipping_phone: mobilePhone,
          shipping_city: birthPlace,
          shipping_zip_code: residentialCAP,
          shipping_street: residentialAddress,
          shipping_street_number: '',
          shipping_district: residentialRegion,
          shipping_country: residentialNation
        }
      } else {
        body = {
          catalog_name: this.$route.params.catalog,
          prizeoption_id: this.$route.params.id,
          shipping_email: this.email
        }
      }
      this.requestAward(body).then((res) => {
        if (res.status === 200) {
          AlertService(
            'Hai richiesto correttamente il tuo premio',
            "Il tuo premio ariverà all'indirizzo email indicato",
            'success',
            'Vai al diario',
            'loyaltyPage'
          )
        } else {
          AlertService(
            'Riprova più tardi',
            'Siamo spiacenti ma per un errore temporaneo non è possibile richiedere il premio. Non preoccuparti: i tuoi dati sono al sicuro.',
            'alert',
            'Torna al diario'
          )
        }
        this.isSending = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-info {
  &__desc {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }
  &__link {
    color: $srg-green;
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
  }
  &__img {
    margin: 20px;
  }
}
</style>
