<template>
  <div class="serviceprofile">
    <TcVars />
    <div class="serviceprofile__header std-side-padding">
      <v-breadcrumbs
        :items="breadcrumbsItems"
        divider=">"
        class="itemsWhite py-0 mb-10"
      >
        <template v-slot:divider>
          <img src="@/assets/icons/chevron-dx-white.svg" width="24" />
        </template>
      </v-breadcrumbs>
      <div class="serviceprofile__title" v-if="$route.params.type">
        {{ title }}
      </div>
      <div class="serviceprofile__title" v-else>Servizi Sorgenia</div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TcVars from '@/components/tcTracker/TcVars'
import { mapActions } from 'vuex'

export default {
  name: 'ServiceProfile',
  data() {
    return {
      to: false
    }
  },
  components: {
    TcVars
  },
  mounted() {
    this.trackTapEvent({
      name: 'other_services_viewed'
    })
  },
  computed: {
    ...mapGetters('account', ['mainClient', 'accountClients']),
    breadcrumbsItems() {
      let res = [
        { to: { name: 'home' }, text: 'Home' },
        { to: { name: 'entryPage' }, text: 'Servizi Sorgenia' }
      ]
      if (this.$route.name !== 'entryPage') {
        res.push({ to: { name: 'serviceSection' }, text: this.title })
      }

      return res
    },
    title() {
      switch (this.$route.params.type) {
        case 'casa':
          return 'Casa'
        case 'fotovoltaico':
          return 'Fotovoltaico'
        case 'mobility':
          return 'Auto'
        default:
          return null
      }
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent'])
  }
}
</script>
<style lang="scss" scoped>
.serviceprofile {
  display: flex;
  flex-direction: column;
  height: 100%;
  &__header {
    padding-top: 48px;
    padding-bottom: 48px;
    background: $srg-blue;
  }
  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: white;
    text-transform: capitalize;
  }
}
</style>
