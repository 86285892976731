<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <h6 class="back mb-7" @click="$emit('backToStep')">
        <strong>Indietro</strong>
      </h6>
    </div>
    <h3 class="mb-8 text-center">
      Vuoi salvare le tue risposte e riprendere il questionario in seguito?
    </h3>
    <div class="d-flex justify-space-between align-center flex-column">
      <MYSButton
        width="269"
        @buttonClick="saveAndExit"
        buttonText="Sì, salva le risposte"
        buttonClass="primaryButton mb-4"
      />
      <MYSButton
        width="300"
        @buttonClick="exit"
        buttonText="No, non salvare le risposte"
        buttonClass="primaryInvertedButtonL"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex/dist/vuex.esm.browser'
import MYSButton from '../../../components/global/MYSButton.vue'
export default {
  name: 'exitComponent',
  props: ['backToStep', 'type'],
  components: { MYSButton },
  computed: {
    ...mapGetters('profile', ['userProfile'])
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    saveAndExit() {
      this.trackTapEvent({
        name: 'profile2_exitbs_tap',
        params: {
          survey_type: this.type,
          action_name: 'save',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      this.$emit('saveSurvey')
      this.$emit('update:dialog', false)
    },
    exit() {
      this.trackTapEvent({
        name: 'profile2_exitbs_tap',
        params: {
          survey_type: this.type,
          action_name: 'dont_save',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      this.$emit('update:dialog', false)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
</style>
