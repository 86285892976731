var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey mt-10"},[_c('h5',{staticClass:"mb-1"},[_vm._v("fotovoltaico")]),_c('h4',{staticClass:"mb-6"},[_vm._v(" Tieni aggiornate le informazioni su casa e impianto fotovoltaico: possiamo aiutarti a trovare la soluzione ideale per te, anche se devi ancora installarlo. ")]),_c('v-expansion-panels',{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.surveyList),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"survey__box mb-6",on:{"click":function($event){return _vm.selectAddress(item)}}},[_c('v-expansion-panel-header',[_c('h4',[_vm._v(_vm._s(item.indirizzo))])]),_c('v-expansion-panel-content',[_c('div',{class:`survey__item mb-4 mt-4 ${item.saved ? 'edit' : ''}`,on:{"click":function($event){return _vm.openSurvey(item)}}},[_c('h6',[_vm._v(" Questionario 1"),_c('img',{staticClass:"ml-6 mr-1",attrs:{"src":require("../../../assets/profile/clock.svg")}}),_vm._v("1 min ")]),_c('h6',{staticClass:"bold"},[_vm._v("Com’è fatto?")])]),(item.completed && _vm.isCompletedHouse(item))?_c('div',{staticClass:"survey__suggestion ButtonS mt-6",on:{"click":function($event){return _vm.$router.push({
              name: 'serviceSection',
              params: { type: 'fotovoltaico' }
            })}}},[_vm._v(" Scopri le offerte su misura per te ")]):(item.completed && !_vm.isCompletedHouse(item))?_c('div',{staticClass:"survey__suggestion ButtonS mt-6",on:{"click":_vm.gotoSurveyHome}},[_vm._v(" Completa le domande sulla tua casa ")]):(item.saved)?_c('div',{staticClass:"survey__suggestion ButtonS mt-6",on:{"click":function($event){return _vm.openSurvey(item)}}},[_vm._v(" Finisci di compilare il questionario! ")]):_vm._e()])],1)}),1),_c('div',{staticClass:"d-flex align-center justify-center mt-8"},[_c('h5',{staticClass:"addAddress",on:{"click":_vm.addAddressDialogOpen}},[_vm._v(" Aggiungi nuovo indirizzo ")])]),_c('v-dialog',{attrs:{"max-width":"555"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"dialogSurvey",class:`${_vm.step === 0 ? 'scrollable' : ''}`},[(_vm.step > 0)?_c('div',{staticClass:"d-flex justify-space-between align-center mb-6"},[_c('h3',[_vm._v("Il mio impianto")]),_c('img',{staticClass:"close",attrs:{"src":require("../../../assets/icons/ic-close.svg")},on:{"click":_vm.exitSurveyDialog}})]):_vm._e(),(_vm.step === 0)?_c('AddressProfile',{attrs:{"address":_vm.address,"dialog":_vm.dialog,"view":_vm.viewAddressList,"canSaveAddress":true},on:{"update:address":function($event){_vm.address=$event},"update:dialog":function($event){_vm.dialog=$event},"update:view":function($event){_vm.viewAddressList=$event},"next":_vm.next,"resetState":_vm.resetState}}):_vm._e(),(_vm.step === 1)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 3")]),_c('div',{staticClass:"question implant mb-6"},[_c('h4',[_vm._v("Hai un impianto fotovoltaico?")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.photovoltaicData.possessoImpianto ===
            _vm.Installation.YES_WHIT_SORGENIA
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.possessoImpianto = _vm.Installation.YES_WHIT_SORGENIA}}},[_c('h6',[_vm._v("Sì, ho già un pannello installato con Sorgenia")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.photovoltaicData.possessoImpianto ===
            _vm.Installation.YES_WHIT_ANOTHER
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.possessoImpianto = _vm.Installation.YES_WHIT_ANOTHER}}},[_c('h6',[_vm._v("Sì, ho un pannello installato con altro fornitore")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.photovoltaicData.possessoImpianto === _vm.Installation.NOT_YET
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.possessoImpianto = _vm.Installation.NOT_YET}}},[_c('h6',[_vm._v("Non ancora, ma vorrei saperne di più")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.photovoltaicData.possessoImpianto === _vm.Installation.CANT_PUT
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.possessoImpianto = _vm.Installation.CANT_PUT}}},[_c('h6',[_vm._v("Non potrei metterlo")])])]):_vm._e(),(_vm.step === 2)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 3")]),_c('div',{staticClass:"question implant mb-6"},[_c('h4',[_vm._v(" Ti interessano altri prodotti? Seleziona quello che più ti interessa ")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.photovoltaicData.interesse === _vm.Interest.BATTERY ? 'selected' : ''
          }`,on:{"click":function($event){return _vm.setInteresse(_vm.Interest.BATTERY)}}},[_c('h6',[_vm._v("Batteria di accumulazione")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.photovoltaicData.interesse === _vm.Interest.HEAT_PUMP
              ? 'selected'
              : ''
          }`,on:{"click":function($event){return _vm.setInteresse(_vm.Interest.HEAT_PUMP)}}},[_c('h6',[_vm._v("Pompa di calore")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.photovoltaicData.interesse === _vm.Interest.EXPANSION
              ? 'selected'
              : ''
          }`,on:{"click":function($event){return _vm.setInteresse(_vm.Interest.EXPANSION)}}},[_c('h6',[_vm._v("Potenziamento dell'impianto")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.photovoltaicData.interesse === _vm.Interest.NOT_INTERESTED
              ? 'selected'
              : ''
          }`,on:{"click":function($event){return _vm.setInteresse(_vm.Interest.NOT_INTERESTED)}}},[_c('h6',[_vm._v("No, non sono interessato")])]),_c('div',{staticClass:"text-area-label mb-2"},[_vm._v("ALTRO")]),_c('v-textarea',{attrs:{"rows":"4","name":"message","placeholder":"Specificare","persistent-placeholder":"","outlined":""},on:{"change":function($event){return _vm.setInteresseToOther(_vm.val)}},model:{value:(_vm.other),callback:function ($$v) {_vm.other=$$v},expression:"other"}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.step)+" di 3")]),_c('div',{staticClass:"question callme mb-6"},[_c('h4',[_vm._v("Vuoi avere una consulenza?")])]),_c('div',{class:`dialogSurvey__box resp mb-6 ${
            _vm.photovoltaicData.consulenza === _vm.Consulting.RECALL
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.consulenza = _vm.Consulting.RECALL}}},[_c('h6',[_vm._v("Sì, voglio lasciare un mio recapito")])]),_c('div',{class:`dialogSurvey__box resp mb-8 ${
            _vm.photovoltaicData.consulenza === _vm.Consulting.NOT_INTERESTED
              ? 'selected'
              : ''
          }`,on:{"click":function($event){_vm.photovoltaicData.consulenza = _vm.Consulting.NOT_INTERESTED}}},[_c('h6',[_vm._v("No, non mi interessa")])]),(_vm.photovoltaicData.consulenza === _vm.Consulting.RECALL)?_c('div',{staticClass:"pt-5 mb-5"},[_c('v-text-field',{staticClass:"auth__input mb-6",attrs:{"label":"E-mail per essere ricontattato","color":"#6e7689","error-messages":_vm.emailErrors},on:{"blur":function($event){return _vm.v$.photovoltaicData.mailDiContatto.$touch()}},model:{value:(_vm.photovoltaicData.mailDiContatto),callback:function ($$v) {_vm.$set(_vm.photovoltaicData, "mailDiContatto", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"photovoltaicData.mailDiContatto"}}),_c('v-text-field',{staticClass:"auth__input",attrs:{"type":"number","label":"telefono per essere ricontattato","color":"#6e7689","error-messages":_vm.phoneErrors},on:{"blur":function($event){return _vm.v$.photovoltaicData.telefonoDiContatto.$touch()}},model:{value:(_vm.photovoltaicData.telefonoDiContatto),callback:function ($$v) {_vm.$set(_vm.photovoltaicData, "telefonoDiContatto", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"photovoltaicData.telefonoDiContatto"}})],1):_vm._e()]):_vm._e(),(!Number.isInteger(_vm.step))?_c('Exit',{attrs:{"dialog":_vm.dialog,"type":"photovoltaic"},on:{"update:dialog":function($event){_vm.dialog=$event},"backToStep":_vm.backToStep,"saveSurvey":_vm.saveSurvey}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center"},[(_vm.step > 1)?_c('MYSButton',{attrs:{"buttonText":"Indietro","buttonClass":"primaryInvertedButtonXSBorded"},on:{"buttonClick":_vm.prev}}):_vm._e(),(_vm.step > 0)?_c('MYSButton',{attrs:{"disabled":_vm.isDisabled,"buttonText":_vm.step === 3 ? 'Conferma' : 'Avanti',"buttonClass":"primaryButtonXS ml-auto"},on:{"buttonClick":_vm.next}}):_vm._e()],1)],1)]),_c('Completed',{attrs:{"dialog":_vm.dialogCompleted,"type":"photovoltaic"},on:{"update:dialog":function($event){_vm.dialogCompleted=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }