<template>
  <div class="survey mt-10">
    <h5 class="mb-1">casa</h5>
    <h4 class="mb-6">
      Tieni aggiornate le informazioni sulla tua casa per ottenere offerte
      dedicate e consigli su come ottimizzare i tuoi consumi!
    </h4>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel
        v-for="(item, i) in surveyList"
        :key="i"
        class="survey__box mb-6"
        @click="selectAddress(item)"
      >
        <v-expansion-panel-header>
          <h4>{{ item.indirizzo }}</h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            :class="`survey__item mb-4 mt-4 ${item.saved ? 'edit' : ''}`"
            @click="openSurvey(item)"
          >
            <h6>
              Questionario 1<img
                class="ml-6 mr-1"
                src="../../../assets/profile/clock.svg"
              />1 min
            </h6>
            <h6 class="bold">Com’è fatta?</h6>
          </div>
          <div class="survey__item mb-4" @click="openCheckup">
            <h6>
              Questionario 2<img
                class="ml-6 mr-1"
                src="../../../assets/profile/clock.svg"
              />5 min
            </h6>
            <h6 class="bold">Check-up energetico</h6>
          </div>
          <div
            v-if="item.completed"
            class="survey__suggestion ButtonS mt-6"
            @click="
              $router.push({ name: 'serviceSection', params: { type: 'casa' } })
            "
          >
            Scopri le offerte su misura per te
          </div>
          <div
            v-else-if="item.saved"
            class="survey__suggestion ButtonS mt-6"
            @click="openSurvey(item)"
          >
            Finisci di compilare il questionario!
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex align-center justify-center mt-8">
      <h5 @click="addAddressDialogOpen" class="addAddress">
        Aggiungi nuovo indirizzo
      </h5>
    </div>
    <v-dialog v-model="dialog" max-width="555" persistent>
      <div class="dialogSurvey" :class="`${step === 0 ? 'scrollable' : ''}`">
        <div
          v-if="step > 0"
          class="d-flex justify-space-between align-center mb-6"
        >
          <h3>La mia casa</h3>
          <img
            class="close"
            src="../../../assets/icons/ic-close.svg"
            @click="exitSurveyDialog"
          />
        </div>
        <AddressConsumption
          v-if="step === 0"
          :address.sync="address"
          :dialog.sync="dialog"
          :view.sync="viewAddressList"
          @next="next"
          @resetState="resetState"
          :canSaveAddress="true"
        />
        <div v-if="step === 1">
          <h5 class="mb-2">{{ step }} di 4</h5>
          <div class="question home mb-6">
            <h4>Di che tipo è la tua abitazione?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              houseData.tipoAbitazione === HouseType.INDIPENDENT
                ? 'selected'
                : ''
            }`"
            @click="houseData.tipoAbitazione = HouseType.INDIPENDENT"
          >
            <h6>Casa indipendente</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              houseData.tipoAbitazione === HouseType.APARTAMENT
                ? 'selected'
                : ''
            }`"
            @click="houseData.tipoAbitazione = HouseType.APARTAMENT"
          >
            <h6>Appartamento</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              houseData.tipoAbitazione === HouseType.TOWNHOUSE ? 'selected' : ''
            }`"
            @click="houseData.tipoAbitazione = HouseType.TOWNHOUSE"
          >
            <h6>Villetta a schiera</h6>
          </div>
        </div>
        <div v-if="step === 2">
          <h5 class="mb-2">{{ step }} di 4</h5>
          <div class="question size mb-12">
            <h4>Quanto è grande la tua abitazione?</h4>
          </div>
          <!-- <div class="slider-legend">
            <span :class="houseData.dimensioneCasa > 35 ? 'over' : ''">30m²</span>
            <span :class="houseData.dimensioneCasa > 90 ? 'over' : ''">90m²</span>
            <span :class="houseData.dimensioneCasa > 150 ? 'over' : ''">150m²</span>
            <span :class="houseData.dimensioneCasa > 210 ? 'over' : ''">210m²</span>
          </div> -->
          <v-slider
            v-model="houseData.dimensioneCasa"
            :thumb-size="30"
            thumb-color="#1197AD"
            thumb-label="always"
            track-color="#DFE4EF"
            loader-height="10"
            step="10"
            min="20"
            max="200"
            class="sizeHouse mb-6"
          />
        </div>
        <div v-if="step === 3">
          <h5 class="mb-2">{{ step }} di 4</h5>
          <div class="question people mb-6">
            <h4>Quante persone ci vivono?</h4>
          </div>
          <!-- <div class="slider-legend justify-space-between">
            <span :class="houseData.nPersone >= 2 ? 'over' : ''">1-2</span>
            <span :class="houseData.nPersone >= 4 ? 'over' : ''">3-4</span>
            <span :class="houseData.nPersone >= 6 ? 'over' : ''">5-6</span>
            <span :class="houseData.nPersone >= 8 ? 'over' : ''">7-8</span>
          </div> -->
          <v-slider
            v-model="houseData.nPersone"
            :thumb-size="30"
            thumb-color="#1197AD"
            thumb-label="always"
            track-color="#DFE4EF"
            loader-height="10"
            step="1"
            min="0"
            max="8"
            class="peopleHouse mb-6"
          />
        </div>
        <div v-if="step === 4">
          <h5 class="mb-2">{{ step }} di 4</h5>
          <div class="question key mb-6">
            <h4>L'abitazione è di tua proprietà o sei in affitto?</h4>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-6 ${
              houseData.tipoTitolarita === Ownership.RENT ? 'selected' : ''
            }`"
            @click="houseData.tipoTitolarita = Ownership.RENT"
          >
            <h6>Sono in affitto</h6>
          </div>
          <div
            :class="`dialogSurvey__box resp mb-8 ${
              houseData.tipoTitolarita === Ownership.OWNER ? 'selected' : ''
            }`"
            @click="houseData.tipoTitolarita = Ownership.OWNER"
          >
            <h6>Sono proprietario</h6>
          </div>
        </div>
        <Exit
          v-if="!Number.isInteger(step)"
          :dialog.sync="dialog"
          @backToStep="backToStep"
          @saveSurvey="saveSurvey"
          type="house"
        />
        <div class="d-flex justify-space-between align-center">
          <MYSButton
            v-if="step > 1"
            buttonText="Indietro"
            buttonClass="primaryInvertedButtonXSBorded"
            @buttonClick="prev"
          />
          <MYSButton
            :disabled="isDisabled"
            v-if="step > 0"
            :buttonText="step === 4 ? 'Conferma' : 'Avanti'"
            buttonClass="primaryButtonXS ml-auto"
            @buttonClick="next"
          />
        </div>
      </div>
    </v-dialog>
    <Completed :dialog.sync="dialogCompleted" type="house" />
  </div>
</template>
<script>
import { eventBus } from '@/main'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertService } from '../../../js/service/alertService'
import { HouseType, Ownership } from '../../../js/type/survey_house.type.js'
import AddressConsumption from './Address.vue'
import Completed from './Completed.vue'
import Exit from './Exit.vue'
import MYSButton from '../../../components/global/MYSButton.vue'

export default {
  name: 'surveyHouse',
  data() {
    return {
      houseData: {
        tipoAbitazione: '',
        dimensioneCasa: 0,
        nPersone: 0,
        tipoTitolarita: ''
      },
      dialog: false,
      viewAddressList: 'list',
      slider: 0,
      step: 0,
      address: '',
      dialogCompleted: false,
      Ownership: Ownership,
      HouseType: HouseType
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        setTimeout(() => {
          this.viewAddressList = 'list'
        }, 400)
      } else {
        if (this.step > 0) {
          this.trackTapEvent({
            name: 'profile2_openSurvey_tap',
            params: {
              survey_type: 'house',
              survey_name: 'Com’è fatta?',
              client_code: this.userProfile.clientOwnerCode
            }
          })
        }
      }
    }
  },
  components: {
    AddressConsumption,
    Exit,
    Completed,
    MYSButton
  },
  created() {
    eventBus.$on('profile:onboardingClosed', () => {
      this.panel = []
    })
    let onboardingStorage = JSON.parse(
      localStorage.getItem('onboardingProfile')
    )
    if (
      !onboardingStorage ||
      !onboardingStorage[this.mainClient.clientOwnerCode]
    ) {
      this.panel = 0
    }
  },
  beforeCreate() {
    eventBus.$on('house:changeSurvey', (address) => {
      if (address) {
        this.getSurveyByAddress(address)
        this.selectAddress(address)
        this.openSurvey(address)
      } else {
        this.getSurveyByAddress()
        this.selectAddress(this.surveyList[0])
        this.openSurvey(this.surveyList[0])
      }
    })
  },
  computed: {
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('profile', ['house', 'houseSelected', 'userProfile']),
    surveyList() {
      if (this.house.length === 0 || !this.house[0].egonIndirizzo) {
        return [
          {
            indirizzo: 'La mia casa',
            saved: false
          }
        ]
      } else {
        return this.house
      }
    },
    panel() {
      return this.surveyList.map((survey, index) => index) || []
    },
    isDisabled() {
      if (this.step === 1) {
        return !this.houseData.tipoAbitazione
      } else if (this.step === 2) {
        return !this.houseData.dimensioneCasa
      } else if (this.step === 3) {
        return !this.houseData.nPersone
      } else if (this.step === 4) {
        return !this.houseData.tipoTitolarita
      }
      return true
    }
  },
  methods: {
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapActions('checkupAnalytics', ['checkupTrackTapEvent']),
    ...mapActions('profile', ['homeSaveSurvey']),
    ...mapMutations('profile', [
      'setHouseValues',
      'setPhotovoltaicValues',
      'setAddressSelected',
      'setTypOnboardingHouse'
    ]),
    prev() {
      this.step--
    },
    next() {
      if (this.step === 0) {
        this.setAddressSelected({
          egonIndirizzo: this.address.egonIndirizzo,
          indirizzo: this.address.indirizzo
        })
        this.houseData.indirizzo = this.address.indirizzo
        this.houseData.egonIndirizzo = this.address.egonIndirizzo
        this.setHouseValues(this.address)
        this.setPhotovoltaicValues(this.address)
      } else if (this.step === 4) {
        // if (this.houseData.completed) {
        //   this.houseData.edited = true
        // }
        // this.houseData.completed = true
        this.saveSurvey()
        this.dialog = false
        // this.dialogCompleted = true
      }
      if (this.step < 4) this.step++
    },
    selectAddress(item) {
      if (item.egonIndirizzo) {
        this.setAddressSelected({
          egonIndirizzo: item.egonIndirizzo,
          indirizzo: item.indirizzo
        })
      }
    },
    openSurvey(item) {
      this.selectAddress(item)
      this.resetState()
      Object.assign(this.houseData, item)
      if (this.houseData.egonIndirizzo) {
        this.step = 1
      } else {
        this.step = 0
      }
      this.dialog = true
    },
    saveSurvey() {
      this.houseData.saved = !!this.houseData.tipoAbitazione
      if (this.step === 4) {
        if (this.houseData.completed) {
          this.houseData.edited = true
        }
        this.houseData.completed = true
      }
      this.setHouseValues(this.houseData)
      this.homeSaveSurvey()
        .then(() => {
          if (this.step === 4) {
            this.dialogCompleted = true
          }
        })
        .catch(() => {
          AlertService(
            'Servizio non disponibile',
            'Al momento non è possibile salvare i questionari. Ti invitiamo a riprovare.',
            false,
            'Chiudi',
            '#'
          )
        })
    },
    resetState() {
      this.houseData = {
        tipoAbitazione: '',
        dimensioneCasa: 0,
        nPersone: 0,
        tipoTitolarita: ''
      }
    },
    addAddressDialogOpen() {
      this.step = 0
      this.viewAddressList = 'list' // addAddress
      this.dialog = true
    },
    exitSurveyDialog() {
      this.setTypOnboardingHouse(false)
      this.trackTapEvent({
        name: 'profile2_surveyClose_tap',
        params: {
          survey_type: 'house',
          client_code: this.userProfile.clientOwnerCode
        }
      })
      if (this.step > 1) {
        this.step = this.step + 'exit'
      } else {
        this.dialog = false
      }
    },
    backToStep() {
      this.step = parseInt(this.step.replace('exit', ''))
    },
    openCheckup() {
      this.trackTapEvent({
        name: 'profile2_openCheckup_tap',
        params: {
          client_code: this.userProfile.clientOwnerCode
        }
      })
      // eventBus.$emit('checkup-wating:open', 'Chiudi') //R5
      eventBus.$emit('checkup-wating:open', 'Inizia ora!')
    },
    getSurveyByAddress(survey) {
      if (survey) {
        this.panel =
          this.house.findIndex((s) => s.indirizzo === survey.indirizzo) || 0
      } else {
        this.panel = 0
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './styleSurvey.scss';
</style>
<style lang="scss">
@import './styleSlider.scss';
</style>
