<template>
  <div class="page light-action">
    <div class="std-side-padding mt-6">
      <div>
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="pb-0">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="light-action__header" v-if="title">
      <div>
        <div class="d-flex align-center">
          <div class="HeadingXXL mr-5">{{ title }}</div>
          <img
            class="bottoneInformativa"
            v-if="idContextualHelp"
            width="24"
            src="../../../assets/icons/info.svg"
            @click="dialogHelp = true"
          />
        </div>
      </div>
      <div class="d-flex align-center justify-end" cols="5" v-if="columnTitle">
        <div @click="$emit('addOption')" class="add_btn">
          <img class="mt-n10" src="@/assets/bluePlus.svg" alt="add" />
          <p class="mt-n5 ml-2">Aggiungi nuovo metodo di pagamento</p>
        </div>
      </div>
    </div>
    <div class="light-action__content">
      <div>
        <slot />
      </div>
    </div>
    <!-- <div>
      <div cols="3">
        <v-btn
          @click="buttonClick"
          class="v-btn--shadowed"
          x-large
          color="primary"
          width="260"
          :disabled="disabled"
          :loading="loading"
        >
          Invia richiesta
        </v-btn>
      </div>
    </div> -->
    <DialogContextualHelp
      v-model="dialogHelp"
      :clientCode="$route.params.clientOwnerCode"
      :idContextualHelp="idContextualHelp"
    />
  </div>
</template>

<script>
import GenErr from '@/js/genericErrors'
import { mapGetters } from 'vuex'
import DialogContextualHelp from '@/components/contextualHelp/Dialog'
import { capitalize } from '@/js/utils'

export default {
  name: 'LightActionTemplate',
  props: {
    longBreadcrumbs: {
      type: String
    },
    longBreadcrumbsAction: {
      type: String
    },
    breadcrumbs: {
      type: Array
    },
    pageName: {
      type: String,
      required: true
    },
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    desc: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    columnTitle: {
      type: Boolean,
      default: false
    },
    idContextualHelp: {
      type: String
    }
  },
  data() {
    return {
      details: null,
      dialogHelp: false
    }
  },
  beforeCreate() {
    if (!this.$store.state.supply.supplyDetail) {
      this.$store
        .dispatch('supply/getDetail', {
          clientOwnerCode: this.$route.params.clientOwnerCode,
          supplyCode: this.$route.params.supplyCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
    if (!this.$store.state.account.clientDetails) {
      this.$store
        .dispatch('account/getDetailForClient', {
          clientOwnerCode: this.$route.params.clientOwnerCode
        })
        .catch((error) => {
          GenErr.handleGenericError(error && error.message)
        })
    }
  },
  computed: {
    ...mapGetters('supply', ['supplyDetail']),
    breadcrumbsItems() {
      let address = this.supplyDetail
        ? this.supplyDetail[this.$route.params.supplyCode].address
        : ''
      address = capitalize(address.toLowerCase())
      let res
      if (this.longBreadcrumbs) {
        res = [
          { to: { name: 'home' }, text: 'Home' },
          {
            to: { name: 'ownerSupplies' },
            text: 'Servizi e Consumi',
            exact: true
          },
          { to: { name: 'supplyDetail' }, text: address, exact: true },
          {
            to: { name: this.longBreadcrumbsAction },
            text: this.longBreadcrumbs,
            exact: true
          },
          { to: { name: this.$router.currentRoute.name }, text: this.pageName }
        ]
      } else {
        res = [
          { to: { name: 'home' }, text: 'Home' },
          {
            to: { name: 'ownerSupplies' },
            text: 'Servizi e Consumi',
            exact: true
          },
          { to: { name: 'supplyDetail' }, text: address, exact: true },
          { to: { name: this.$router.currentRoute.name }, text: this.pageName }
        ]
      }
      return res
    }
  },
  methods: {
    buttonClick() {
      this.$emit('submit')
    }
  },
  components: {
    DialogContextualHelp
  }
}
</script>

<style lang="scss">
.light-action {
  width: 100%;
  .add_btn {
    font-weight: 500;
    color: $srg-blue;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  &__title {
    font-weight: bold;
    color: black;
    font-size: 28px;
    letter-spacing: 1.2px;
  }
  &__subTitle {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.5px;
    margin-top: 8px;
    font-weight: normal;
    color: black;
  }
  &__title-desc {
    font-size: 1.25rem;
    line-height: 26px;
    font-weight: 300;
  }
  h5 {
    font-weight: bold !important;
  }
  .typeMdp,
  .codeMdp {
    font-size: $std-font-size--small;
  }
  .extensible__btn {
    display: flex;
    justify-content: center;
    .v-radio {
      width: 100%;
      .v-label {
        flex-direction: column;
        align-items: flex-start;
        .typeInvoice {
          font-size: 14px;
          font-weight: 300;
          line-height: 18px;
          color: rgba(0, 0, 0, 0.5);
          &--collapsed {
            font-size: 16px;
            font-weight: bold;
            color: black;
          }
        }
        .codeInvoice {
          font-size: 16px;
          font-weight: 300;
          color: black;
        }
      }
    }
  }
  .bottoneInformativa {
    cursor: pointer;
  }
}
</style>
