var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"successPage bkg--blue"},[_c('TcVars'),_c('v-row',{staticClass:"successPage__content"},[_c('v-col',{staticClass:"col-5 img"}),_c('v-col',{staticClass:"col-7 info-block"},[(_vm.type === 'fiber')?_c('div',[_c('h1',{staticClass:"info-block__title"},[_vm._v(" Grazie! "),_c('br'),_vm._v(" La tua richiesta è "),_c('br'),_vm._v(" stata inviata! ")]),_c('span',{staticClass:"info-block__text"},[_vm._v("Verrai ricontattato nella fascia"),_c('br'),_vm._v("oraria da te indicata dal numero"),_c('br'),_vm._v(_vm._s(_vm.phone)+".")])]):_vm._e(),(_vm.type === 'power1' || _vm.type === 'power2')?_c('div',[_c('h1',{staticClass:"info-block__title"},[_vm._v(" La tua richiesta "),_c('br'),_vm._v("è stata inviata! ")])]):_vm._e(),(
          _vm.type === 'counter1' || _vm.type === 'voltage2' || _vm.type === 'voltage1'
        )?_c('div',[_c('h1',{staticClass:"info-block__title"},[_vm._v(" La tua richiesta "),_c('br'),_vm._v("è stata inviata! ")])]):_vm._e(),(_vm.type === 'counter2')?_c('div',[_c('h1',{staticClass:"info-block__title"},[_vm._v(" La tua richiesta "),_c('br'),_vm._v("è stata inviata! ")])]):_vm._e(),(_vm.type === 'payment')?_c('div',[_c('h1',{staticClass:"info-block__title"},[_vm._v(" La richiesta di "),_c('br'),_vm._v("modifica di "),_c('br'),_vm._v("pagamento è stata "),_c('br'),_vm._v("inviata con successo! ")]),_c('span',{staticClass:"info-block__text"},[_vm._v("A breve troverai il metodo di"),_c('br'),_vm._v("pagamento aggiornato nella pagina"),_c('br'),_vm._v("di dettaglio della tua utenza.")])]):_vm._e(),_c('v-btn',{staticClass:"info-block__button v-btn--shadowed",attrs:{"x-large":"","width":"288","color":"white"},on:{"click":function($event){_vm.haveToReturnUser
            ? _vm.$router.push({
                name: 'supplyDetail'
              })
            : _vm.$router.push({ name: 'home' })}}},[_vm._v(_vm._s(_vm.haveToReturnUser ? "Torna all'utenza" : 'Torna alla homepage'))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }