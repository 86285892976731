var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"header-size":"small"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-col',{attrs:{"cols":"6"}},[_c('v-breadcrumbs',{staticClass:"pb-0 reverse-breadcrumbs",attrs:{"items":_vm.breadcrumbsItems,"divider":"<"},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/icons/chevron-sx-blue.svg"),"width":"16","height":"16"}})]},proxy:true}])}),_c('h1',{staticClass:"HeadingsTitleXXL"},[_vm._v("Richiedi il tuo premio")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{attrs:{"src":require('@/assets/greeners/svg/logoGreeners_new.svg'),"contain":"","width":"360"}})],1)]},proxy:true}])},[(_vm.email)?_c('v-col',{staticClass:"gift-info pa-0 mb-8"},[_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"HeadingL mb-2"},[_vm._v("Richiedi il tuo premio")]),_c('p',{staticClass:"BodyL"},[_vm._v(" Ti invieremo il premio all'indirizzo email presente nel tuo profilo: "),_c('strong',[_vm._v(_vm._s(_vm.email))])])])],1),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"BodyL-Strong mb-2"},[_vm._v(" Vuoi ricevere il premio ad un nuovo indirizzo? ")]),_c('p',{staticClass:"BodyL"},[_vm._v(" Per modificarlo "),_c('router-link',{staticClass:"ButtonM-Underline",attrs:{"to":{
              name: 'Contacts',
              params: { user: _vm.mainClientLoyaltySelected.clientOwnerCode }
            }}},[_vm._v("contatta l’assistenza")]),_vm._v(": riceverai i premi e tutte le comunicazioni da parte di Sorgenia al nuovo indirizzo email da te scelto. ")],1)])],1),_c('v-row',[_c('v-col',{staticClass:"pl-0",attrs:{"align":"left"}},[_c('v-btn',{staticClass:"v-btn--shadowed",attrs:{"loading":_vm.isSending,"color":"primary","x-large":"","width":"265"},on:{"click":_vm.sendRequest}},[_vm._v(" Conferma richiesta ")])],1)],1)],1):_c('v-col',{staticClass:"gift-info pa-0 mb-8"},[_c('v-row',{staticClass:"mt-12"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"HeadingL mb-2"},[_vm._v("Richiedi il tuo premio")]),_c('p',{staticClass:"BodyL"},[_vm._v(" Per richiedere il tuo premio è necessario aggiungere un indirizzo email a cui riceverai i premi e tutte le comunicazioni di Sorgenia."),_c('br'),_vm._v(" Per aggiungerlo "),_c('router-link',{staticClass:"ButtonM-Underline",attrs:{"to":{
              name: 'Contacts',
              params: { user: _vm.mainClientLoyaltySelected.clientOwnerCode }
            }}},[_vm._v("contatta l’assistenza")]),_vm._v(". ")],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }