import * as amplitude from '@amplitude/analytics-browser'
import { ENV_CONFIGS } from '../configs'
import store from '../../store/index'

export default class AmplitudeService {
  #props = null

  constructor(props) {
    this.#props = props
    this.init()
  }
  init() {
    const userIdMd5 = store.getters['account/getUserIdMd5']
    amplitude.init(ENV_CONFIGS.AMPLITUDE_API_KEY, userIdMd5, {
      serverZone: 'EU',
      includeUtm: true,
      defaultTracking: {
        sessions: true,
        pageViews: {
          trackOn: () => {
            const path = window.location.pathname
            const excludedPaths = [
              'punDetail',
              'home',
              'profile',
              'account',
              'entry-page',
              'offering',
              'subscribed',
              'login'
            ]

            // Esclude esattamente le pagine principali di support e loyalty (ma non le sottoroute)
            const excludeExactPaths = ['/private/support/', '/private/loyalty/']

            const isExactPath = (exactPath) => path === exactPath
            const isExcluded =
              excludedPaths.some((excluded) => path.includes(excluded)) ||
              excludeExactPaths.some(isExactPath)

            return !isExcluded
          }
        }
      }
    })
  }

  async trackEvent(event, eventProps = {}) {
    if (ENV_CONFIGS.ENABLE_ADMIN_LOGIN) {
      return
    }
    if (eventProps) {
      amplitude.track(event, eventProps)
    } else {
      amplitude.track(event)
    }
  }
}
