<template>
  <div class="sel-address">
    <div v-if="loaded">
      <div class="mt-10" v-for="(client, i) in clientsCode" :key="i">
        <div class="name">{{ client }}</div>

        <div
          class="box-address"
          v-for="(supply, i) in clients[client]"
          :key="i"
          @click="requestType(supply)"
        >
          <h5>{{ supply.addressComplete }}</h5>
        </div>
      </div>
      <div class="d-flex align-center justify-center mt-8">
        <div @click="addAddressDialogOpen" class="addAddress">
          Aggiungi nuovo indirizzo
        </div>
      </div>
    </div>
    <div v-else>Loading...</div>
    <!-- <div class="mt-12" v-if="false">
      <div class="box-address" @click="goToAddAddress">
        <h5 class="color--blue"><strong>Aggiungi nuovo indirizzo</strong></h5>
      </div>
    </div> -->
    <v-dialog v-model="dialog" max-width="555" persistent>
      <div>
        <div v-if="index == 0">
          <div class="dialogSurvey">
            <div class="d-flex justify-space-between align-center mb-6">
              <h3>Seleziona profilo</h3>
              <img
                class="close"
                src="../../../../assets/icons/ic-close.svg"
                @click=";(dialog = false), setStartIndex()"
              />
            </div>
            <div
              v-for="(client, i) in clientListRes"
              :key="i"
              class="dialogSurvey__box user resp mb-6"
              @click="nextStepAddAddress(client)"
            >
              <div class="ButtonL accountSelect">
                {{ client.firstName }} {{ client.lastName }}
              </div>
            </div>
          </div>
        </div>

        <div class="dialogSurvey" v-if="index == 1">
          <AddressConsumption
            :address.sync="address"
            :dialog.sync="dialog"
            :view.sync="viewAddressList"
            @next="$emit('nextStep')"
            :canSaveAddress="false"
            @resetState="$emit('resetState')"
            @setStartIndex="setStartIndex"
            :typeCommodity="typeCommodity"
            @setFlowType="$emit('setFlowType', 'selectedAddress')"
            @resetFlagNewAddress="setStartIndex()"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { capitalize } from '@/js/utils'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { AlertServiceGasSardegna } from '../../../../js/service/alertService'
import AddressConsumption from '../../../profile/consumption/Address.vue'
import { required } from '@vuelidate/validators'

export default {
  name: 'selectAddress',
  components: {
    AddressConsumption
  },
  props: ['typeCommodity'],
  data: function () {
    return {
      loaded: false,
      //loadingCap: false,
      index: 0,
      address: '',
      newAddress: '',
      addressObject: {},
      dialog: false,
      viewAddressList: 'list'
    }
  },
  async beforeMount() {
    await this.getClientsForAccount()
    await this.getSupplies()
    this.loaded = true
    if (this.getIsDeepLink) {
      this.trackTapEvent({
        name: 'subscription_started',
        params: {
          services_to_subscribe:
            this.$route.name === 'gasOffering' ? 'gas' : 'ele',
          entry_point: 'deeplink'
        }
      })
    }
  },
  mounted() {
    this.setStartIndex()
    this.setFlagAddedAddress(false)
  },
  computed: {
    ...mapGetters('supply', ['supplies']),
    ...mapGetters('account', ['accountClients']),
    ...mapGetters('session', ['username']),
    ...mapGetters('account', ['mainClient']),
    ...mapGetters('upsellingComm', ['getIsDeepLink']),
    clients() {
      let eligibleAddress = this.supplies
      if (!eligibleAddress) return {}
      let clientsGroup = {}
      eligibleAddress.forEach((s) => {
        if (!s.egonStreet) return false
        const addressId = (sup) =>
          `${sup.egonRegion || ''}_${sup.county}_${sup.city}_${
            sup.egonLocality
          }_${sup.zipcode}_${sup.egonStreet}_${sup.egonStreetNumber}_${
            sup.egonStreetNumberLetter
          }`.toLowerCase()
        if (s.clientOwnerType === 'BUS') return false
        let name = s.firstName + ' ' + s.lastName
        if (
          clientsGroup[name] &&
          clientsGroup[name].find((sup) => addressId(sup) === addressId(s))
        ) {
          return false
        }
        clientsGroup[name] = [
          ...(clientsGroup[name] || ''),
          { ...s, addressComplete: capitalize(s.street + ', ' + s.city) }
        ]
      })
      return clientsGroup
    },
    clientsCode() {
      if (!this.clients) return null
      return Object.keys(this.clients)
    },
    addressErrors() {
      let errArray = []
      if (!this.v$.newAddress.$dirty) return errArray
      this.v$.newAddress.required.$invalid === true &&
        errArray.push("L'indirizzo è obbligatorio")
      typeof this.addressObject === 'string' &&
        errArray.push('Seleziona un indirizzo valido')
      !this.addressObject.street_number &&
        errArray.push('Il numero civico non è presente')
      !this.addressObject.route && errArray.push('La via non è presente')
      !this.addressObject.postal_code && errArray.push('Il CAP non è presente')
      !this.addressObject.locality && errArray.push('La città non è presente')
      return errArray
    },

    clientListRes() {
      let listResClients = []
      Object.values(this.accountClients).forEach((client) => {
        if (client.clientOwnerType === 'RES') {
          listResClients.push(client)
        }
      })
      return listResClients
    }
  },
  methods: {
    ...mapMutations('upsellingComm', [
      'updateAddress',
      'setClient',
      'setSupplyUpselling'
    ]),
    ...mapActions('supply', ['getSupplies']),
    ...mapActions('account', ['getClientsForAccount', 'getDetailForClient']),
    ...mapActions('egon', ['getEgonComune', 'getComune']),
    ...mapActions('analytics', ['trackTapEvent']),
    ...mapMutations('upsellingComm', [
      'setFlagNewAddress',
      'setFlagAddedAddress'
    ]),
    nextStepAddAddress(client) {
      this.setFlagNewAddress(true)
      this.setClient(client)
      this.index++
    },
    addAddressDialogOpen() {
      this.step = 0
      this.viewAddressList = 'list' // addAddress
      this.dialog = true
    },
    getAddressData(addressData) {
      this.addressObject = addressData
      this.newAddress = `${addressData.route || ''} ${
        addressData.street_number || ''
      }, ${addressData.postal_code || ''}, ${addressData.locality || ''}`
      this.$forceUpdate()
    },
    requestType(supply) {
      if (this.$route.name === 'gasOffering' && supply.egonRegion === 'SAR') {
        AlertServiceGasSardegna(
          'Indirizzo non raggiunto',
          "Ci dispiace, l'indirizzo selezionato non è raggiunto dalla rete nazionale",
          false,
          'Chiudi',
          '#'
        )
      } else {
        this.setFlagNewAddress(true)
        this.setSupplyUpselling(supply)
        this.$emit('setFlowType', 'selectedAddress')
        this.$router.push({ name: `offers${this.typeCommodity}` })
      }
    },
    goToAddAddress() {
      this.$emit('nextStep', 'upsellingcom_addaddress_open')
    },
    setStartIndex() {
      switch (Object.keys(this.clientListRes).length) {
        case 1:
          this.setClient(this.mainClient)
          this.index = 1
          break
        default:
          this.index = 0
          break
      }
      this.setFlagNewAddress(false)
    }
  },
  validations: {
    newAddress: { required }
  }
}
</script>
<style lang="scss" scoped>
.schema-progressbar {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.sel-address {
  display: inline-block;
  .name {
    font-size: 20px;
    font-weight: bold;
    &::after {
      content: url('../../../../assets/ic-residenziale_black.svg');
      width: 24px;
      margin-left: 8px;
    }
  }

  .box-address {
    width: 652px;
    height: 66px;
    margin: 24px 0;
    padding: 0 24px;
    border-radius: 20px;
    border: solid 1px #ccd0e1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &::after {
      content: url('../../../../assets/icons/chevron_rx.svg');
      width: 16px;
    }
  }
}
.dialogSurvey {
  padding: 40px 40px 48px;
  background: white;

  h3 {
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 1px;
    color: #000000;
  }

  .close {
    cursor: pointer;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    width: 475px;
    height: 57px;
    background: #ffffff;
    border: 1px solid #dfe4ef;
    border-radius: 20px;
    cursor: pointer;
  }
}
.addAddress {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.8px;
  text-transform: initial;
  color: #12256a;
  cursor: pointer;
  &::before {
    content: url('../../../../assets/profile/plus.svg');
    margin-right: 13px;
  }
}
.accountSelect {
  color: #12256a;
  cursor: pointer;
  display: flex;
  &::before {
    content: url('../../../../assets/ic-residenziale_blue.svg');
    margin-right: 13px;
  }
}
</style>
