<template>
  <v-btn
    :class="buttonClass"
    :disabled="disabled"
    :href="href"
    :target="href ? '_blank' : ''"
    :width="width"
    @click="$emit('buttonClick')"
    :outlined="outlined"
    :loading="loading"
  >
    {{ buttonText }}
    <img v-if="isGME" class="isGME" src="../../assets/icons/arrow-up-dx.svg" />
    <div v-if="counterValue" class="d-flex align-center">
      Premio da ritirare
      <div class="counter">{{ counterValue }}</div>
    </div>
    <v-icon v-if="iconText" :size="iconSize">{{ iconText }}</v-icon>
    <v-progress-circular
      v-if="sizeProgress"
      indeterminate
      :color="buttonClass === 'primaryButton' ? 'white' : 'primary'"
      :size="sizeProgress"
    ></v-progress-circular>
  </v-btn>
</template>

<script>
export default {
  name: 'MYSButton',
  props: {
    disabled: { type: Boolean, default: false },
    buttonText: { type: String },
    buttonClass: { type: String },
    href: { type: String },
    width: { type: String },
    outlined: { type: Boolean, default: false },
    counterValue: { type: Number },
    iconText: { type: String },
    iconSize: { type: String },
    loading: { type: Boolean, default: false },
    imgSrc: { type: String },
    sizeProgress: { type: String }
  },
  computed: {
    isGME() {
      return this.buttonClass.includes('GMEButton')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';
.primaryButton {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
}
.primaryButtonXL {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 327px;
}
.primaryButtonL {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 220px;
}
.primaryButtonS {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 167px;
}
.primaryButtonXS {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 152px;
}
.primaryButtonXXL {
  background-color: $srg-blue !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 100%;
}

.primaryInvertedButton {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none !important;
}
.primaryInvertedButtonXL {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 327px;
  box-shadow: none !important;
}
.primaryInvertedButtonL {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 220px;
  box-shadow: none !important;
}
.primaryInvertedButtonS {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 167px;
  box-shadow: none !important;
}
.primaryInvertedButtonXXL {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 100%;
  box-shadow: none !important;
}
.primaryInvertedButtonXS {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 152px;
  padding: 14.5px 48px 14.5px 48px !important;
  box-shadow: none !important;
}

.primaryInvertedButtonBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  border: 1px solid #dfe4ef;
}
.primaryInvertedButtonXLBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 327px;
  border: 1px solid #dfe4ef;
}
.primaryInvertedButtonLBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 220px;
  border: 1px solid #dfe4ef;
  box-shadow: none !important;
}
.primaryInvertedButtonSBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 167px;
  border: 1px solid #dfe4ef;
  box-shadow: none;
}
.primaryInvertedButtonXXLBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 100%;
  border: 1px solid #dfe4ef;
}
.primaryInvertedButtonXSBorded {
  background-color: white !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px !important;
  gap: 8px;
  border-radius: 20px;
  width: 152px;
  padding: 14.5px 48px 14.5px 48px !important;
  border: 1px solid #dfe4ef;
  box-shadow: none;
}
.srgButton {
  background-color: $srg-green !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
}
.srgButtonXL {
  background-color: $srg-green !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 327px;
}
.srgButtonL {
  background-color: $srg-green !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 220px;
}
.srgButtonS {
  background-color: $srg-green !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 167px;
}
.srgButtonXXL {
  background-color: $srg-green !important;
  color: $srg-blue !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
  width: 100%;
}

.shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.bold {
  font-weight: bold;
}
.counter {
  width: 24px;
  height: 24px;
  background: $srg-purple;
  color: white;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-left: 8px;
}
.iconMessage {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 24px;
  bottom: 24px;
  background-color: $srg-green !important;
  width: 56px !important;
  height: 56px !important;
  color: $srg-blue !important;
}
.redAlert {
  background-color: #d01414 !important;
  color: white !important;
  height: 54px !important;
  padding: 14.5px 48px 14.5px 48px;
  gap: 8px;
  border-radius: 20px;
  box-shadow: none;
}
.plusOperationButton {
  background-color: $srg-blue !important;
  height: 32px !important;
  width: 32px !important;
  padding: 10px !important;
  border-radius: 100% !important;
  box-shadow: none;
  min-width: 32px !important;
  content: url('../../assets/checkup-energetico/plus-white.svg');
}
.lessOperationButton {
  background-color: $srg-blue !important;
  height: 32px !important;
  width: 32px !important;
  padding: 10px !important;
  border-radius: 100% !important;
  box-shadow: none;
  min-width: 32px !important;
  content: url('../../assets/checkup-energetico/less-white.svg');
}
.plusOperationButtonDisabled {
  height: 32px !important;
  width: 32px !important;
  padding: 10px !important;
  border-radius: 100% !important;
  box-shadow: none;
  min-width: 32px !important;
  content: url('../../assets/checkup-energetico/plus-grey.svg');
}
.lessOperationButtonDisabled {
  height: 32px !important;
  width: 32px !important;
  padding: 10px !important;
  border-radius: 100% !important;
  box-shadow: none;
  min-width: 32px !important;
  content: url('../../assets/checkup-energetico/less-gray.svg');
}
/*
.whatsappShare {
  ::before {
    content: url('../../assets/icons/social/whatsapp-24.svg');
    margin-top: 2px;
    margin-right: 10px;
  }
}
.facebookShare {
  ::before {
    content: url('../../assets/icons/social/facebook-24.svg');
    margin-top: 2px;
    margin-right: 10px;
  }
}
*/
.iosShare {
  content: url('../../assets/icons/social/mdi_apple_btn.svg');
  display: block;
  width: 130px;
  height: auto;
}
.androidShare {
  content: url('../../assets/icons/social/mdi_google_btn.svg');
  display: block;
  width: 130px;
  height: auto;
}
.spaziatura {
  letter-spacing: 0.8px;
}
.rulerButton {
  height: 66px !important;
  letter-spacing: 0.8px;
}
.paymentButton {
  color: white;
  background-color: $srg-blue !important;
  height: 50px !important;
  width: 100% !important;
}
.billsButton {
  height: 50px !important;
  min-width: 245px !important;
}
.tutorialButton {
  min-width: 300px;
  margin: auto;
  letter-spacing: 0;
}
.resize-btn {
  height: auto !important;
}
.filterButton {
  ::after {
    content: url('../../assets/message-center/filter-by.svg');
    margin-top: 4px;
    margin-left: 5px;
  }
}
.closeButton {
  content: url('../../assets/icons/ic-close.svg');
}
.isGME {
  width: 24px;
  margin-left: 7px;
}
</style>
<style lang="scss" scoped>
@import '../../styles/authentication/auth.scss';
</style>
