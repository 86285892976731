var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pdr-archive"},[_c('div',_vm._l((_vm.pdrList),function(pdr,i){return _c('v-row',{key:i,attrs:{"align":"center"},on:{"click":function($event){_vm.goToPdrDetails(pdr, $event),
          _vm.trackTapEvent({
            name: 'supplyDetail_PDRCard_tap'
          })}}},[_c('v-col',{staticClass:"std-panel std-panel--shadowed pa-0",class:{
          notRegular:
            _vm.countScadutaInstalments(pdr) > 0 &&
            !_vm.checkInVerifica(pdr) &&
            pdr.planStatus !== _vm.Consts.STATUS_PDR.CANCELED
        }},[_c('v-row',{staticClass:"bills__iteams_container",class:pdr.planStatus === _vm.Consts.STATUS_PDR.CANCELED
              ? 'pdr-cancelled'
              : '',attrs:{"no-gutters":"","disabled":pdr.planStatus === _vm.Consts.STATUS_PDR.CANCELED}},[_c('v-col',{staticClass:"bills__iteams col-md-9 px-8 py-8"},[_c('v-row',{staticClass:"bills__item-bold mb-6",attrs:{"no-gutters":""}},[_c('v-col',[_c('h3',{staticClass:"HeadingsTitleL"},[_vm._v("Piano di rientro")])])],1),_c('v-row',{staticClass:"bills__item mb-2",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Data inizio")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Data fine")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Stato pagamento")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyM"},[_vm._v("Importo")])])],1),_c('v-row',{staticClass:"bills__item",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyL-Strong"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateSplitJoin(pdr.planStartDate))+" ")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"BodyL-Strong"},[_vm._v(" "+_vm._s(_vm.$filters.formatDateSplitJoin(pdr.planEndDate))+" ")])]),_c('v-col',{staticClass:"col-md-3"},[(
                    _vm.countScadutaInstalments(pdr) > 0 &&
                    !_vm.checkInVerifica(pdr) &&
                    pdr.planStatus !== _vm.Consts.STATUS_PDR.CANCELED
                  )?_c('h5',{staticClass:"BodyL-Strong color--purple"},[_vm._v(" "+_vm._s(_vm.countScadutaInstalments(pdr) > 1 ? _vm.countScadutaInstalments(pdr) + ' RATE SCADUTE' : _vm.countScadutaInstalments(pdr) + ' RATA SCADUTA')+" ")]):(pdr.planStatus === _vm.Consts.STATUS_PDR.ACTIVE)?_c('h5',{staticClass:"BodyL-Strong status"},[_vm._v(" IN CORSO ")]):_c('h5',{staticClass:"BodyL-Strong status"},[_vm._v(" "+_vm._s(_vm.Consts.TRANSLATE_STATUS_PDR[pdr.planStatus])+" ")])]),_c('v-col',{staticClass:"col-md-3"},[_c('h5',{staticClass:"HeadingsTitleL-Upper"},[_vm._v(" € "+_vm._s(_vm.countAmountInstalments(pdr))+" ")])])],1)],1),_c('v-col',{staticClass:"bills__link col-md-3 d-flex justify-center align-center flex-column"},[_c('a',{staticClass:"d-flex align-center justify-start",attrs:{"target":"_blank"},on:{"click":function($event){return _vm.goToPdrDetails(pdr)}}},[_c('h5',{staticClass:"ButtonL color--blue"},[_vm._v("Dettaglio piano di rientro")])]),(pdr.planStatus === _vm.Consts.STATUS_PDR.ACTIVE)?_c('v-btn',{staticClass:"mt-10 resize-btn",attrs:{"color":"primary","x-large":"","text":"","disabled":!pdr.isPossibleToPay},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.openModal(pdr),
                  _vm.trackTapEvent({
                    name: 'payment_started',
                    params: {
                      service_type: _vm.supply.serviceType.toLowerCase(),
                      component: 'card',
                      payment_item: 'pdr',
                      screen_name:
                        'MySorgenia - Riepilogo e Archivio Pagamenti'
                    }
                  })}}},[_c('h5',{staticClass:"ButtonL"},[_vm._v("Paga rate")])]):_vm._e()],1)],1)],1)],1)}),1),(Object.keys(_vm.pdrSelected).length > 0)?_c('DialogPagaRate',{attrs:{"source":_vm.source,"pdrAttivo":_vm.pdrSelected,"clientDetails":_vm.clientDetails,"intestatario":_vm.getIntestatario(),"dialogKey":_vm.pdrSelected.planId},model:{value:(_vm.dialogPayRatePdr),callback:function ($$v) {_vm.dialogPayRatePdr=$$v},expression:"dialogPayRatePdr"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }