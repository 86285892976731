<template>
  <div class="dashboard-chart" :class="{ item: showBatteria }">
    <h2 class="mb-1 HeadingsTitleL">{{ chartData.title }}</h2>
    <div class="BodyL hAuto">
      {{ chartData.description }}
    </div>
    <div class="grafico">
      <DashboardChartApexVue :apexData="apexData"></DashboardChartApexVue>
    </div>
    <div class="list">
      <div class="list-item" v-for="stat in statsArray" :key="stat.title">
        <div class="leggenda">
          <div :class="stat.type"></div>
          <div class="title">{{ stat.title }}</div>
        </div>
        <div v-if="stat.title">
          <span class="percentage">
            {{ stat.percentage.replace('.', ',') }} %</span
          >
          <span class="value">{{ stat.value }}</span>
        </div>
      </div>
    </div>

    <div>
      <MYSButton
        buttonClass="primaryButtonXXL"
        :buttonText="chartData.labelButton"
        @buttonClick="handleClick"
      />
    </div>
  </div>
</template>
<script>
import DashboardChartApexVue from './DashboardChartApex.vue'
import MYSButton from '../../global/MYSButton.vue'

export default {
  props: {
    chartData: {
      type: Object,
      required: true
    },
    handleClick: { type: Function, required: true },
    showBatteria: { type: Boolean }
  },
  components: { DashboardChartApexVue, MYSButton },
  computed: {
    statsArray() {
      // check to create third object to align values
      let stats = this.chartData.stats
      if (stats.length === 2) {
        stats = [...stats, {}]
      }

      stats.forEach((element) => {
        if (Object.keys(element).length === 0) {
          element.percentage = '0,00 %'
        } else {
          element.percentage = element.percentage.slice(0, -1)
          if (
            element.percentage == 'undefined' ||
            element.percentage == undefined ||
            element.percentage == '0' ||
            !element.percentage ||
            element.percentage === ' '
          ) {
            element.percentage = '0,00'
          } else {
            element.percentage = parseFloat(element.percentage).toFixed(2)
          }
        }
      })
      return stats
    },
    apexData() {
      const { apexLabel, apexTotal } = this.chartData
      const labels = this.chartData.stats.map((x) => x.title)
      const series = this.chartData.stats.map((x) => {
        let sub = x.percentage.slice(0, -1) // remove %
        if (sub == 'undefined' || sub == '0' || sub == 0 || sub == '0,0') {
          return 0
        } else {
          const repl = sub.replace(',', '.') // format to number
          const num = Number(repl) // convert to number
          return num
        }
      })
      return { apexLabel, apexTotal, labels, series }
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-chart {
  width: 100%;
  padding: 16px 16px 0px 16px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: center;

  &.item {
    &:last-child {
      .list-item {
        &:last-child {
          opacity: 0;
          min-height: 41px;
        }
      }
    }
  }

  h2 {
    font-size: 24px !important;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 28.8px;
  }

  .grafico {
    padding-top: 32px;
  }
  .list {
    margin: 16px 0 !important;
    > :not(:last-child) {
      border-bottom: 1px solid #eaeef5;
    }

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;

      .leggenda {
        display: flex;
        align-items: center;
        .icon-green-light {
          background-color: #97d700;
          border-radius: 50%;
          height: 12px;
          width: 12px;
        }

        .icon-green {
          background-color: #0f9c38;
          border-radius: 50%;
          height: 12px;
          width: 12px;
        }

        .icon-green-blue {
          background-color: #1197ad;
          border-radius: 50%;
          height: 12px;
          width: 12px;
        }

        .title {
          padding: 0 4px;
          font-size: 14px !important;
          font-weight: 400;
          letter-spacing: 0.5px;
          line-height: 18.2px;
        }
      }

      .percentage {
        font-size: 14px !important;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 18.2px;
        color: #6e7689;
      }

      .value {
        padding: 0 4px;
        font-size: 14px !important;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 18.2px;
      }
    }
  }
  .hAuto {
    min-height: 65px;
  }
}
</style>
