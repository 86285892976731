<template>
  <v-row no-gutters class="align-center">
    <v-col class="col-md-9">
      <div class="user__detail">
        <v-breadcrumbs :items="breadcrumbsItems" divider=">" class="py-0 mb-10">
          <template v-slot:divider>
            <img src="@/assets/icons/chevron_rx_light.svg" width="24" />
          </template>
        </v-breadcrumbs>
        <div class="detail__address mb-2">
          <h1>{{ $filters.capitalize(detail.address) }}</h1>
          <img
            class="mr-4"
            :src="getServiceIcon(detail.serviceType)"
            height="56"
            width="56"
            contain
          />
        </div>
        <div class="detail__city mb-6">
          <h3>{{ $filters.capitalize(detail.city) }}</h3>
        </div>
        <div
          class="detail__noActive"
          v-if="detail.supplyState === SUPPLY_DETAIL_STATUS.REJECTED"
        >
          <div class="mt-6">UTENZA ANNULLATA</div>
        </div>
        <div
          class="detail__noActive"
          v-if="detail.supplyState === SUPPLY_DETAIL_STATUS.CLOSED"
        >
          <div class="mt-6">
            UTENZA CESSATA IL
            {{ $filters.formatDateWithSeparator(detail.dataCessazione, '.') }}
          </div>
        </div>
        <div
          class="detail__name"
          v-if="detail.supplyState !== SUPPLY_DETAIL_STATUS.REJECTED"
        >
          <div class="d-inline-flex align-center mb-2">
            <h5 class="capitalized mr-2">
              <strong>{{
                detail.clientOwnerType === 'RES'
                  ? detail.firstName + ' ' + detail.lastName
                  : detail.businessName
              }}</strong>
            </h5>
            <v-img
              :src="
                detail.clientOwnerType === 'RES'
                  ? require('../../assets/icons/ic-owner-res-black.svg')
                  : require('../../assets/icons/business_black.svg')
              "
              width="24"
              height="24"
            ></v-img>
          </div>
          <!--
          COMMENTO DA LEVARE QUANDO LA DESIGNER FA LE SUE VERIFICHE
          -->
          <!-- <div class="BodyXL">
            Tipologia cliente:
            {{ detail.clientOwnerType === 'RES' ? 'Residenziale' : 'Business' }}
          </div> -->
          <h5 class="noBold">Codice cliente: {{ detail.clientOwnerCode }}</h5>
        </div>
      </div>
    </v-col>
    <v-col class="col-md-3 align-self-baseline" align="right">
      <!-- <img src='../../assets/ic_light.png'/> -->
    </v-col>
  </v-row>
  <!-- <v-row no-gutters class="align-center">
        <v-col>
          <div class="user__cfp">
            <div class="cfp__number">
              <h3>12.347</h3><h6>Kg di CO2</h6>
            </div>
            <div class="cfp__text">
              <h6>Risparmiati al pianeta fino ad oggi</h6>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="user__share">
            <v-btn
              class="white--text"
              color="#12256A"
              :rounded="true"
            >
              Condividi fornitura
            </v-btn>
          </div>
        </v-col>
      </v-row> -->
</template>
<script>
import { capitalize } from '@/js/utils'
import Constants from '@/js/constants'

export default {
  name: 'userDetail',
  data() {
    return {
      detail: {},
      SERVICE_TYPE: Constants.SERVICE_TYPE,
      SUPPLY_DETAIL_STATUS: Constants.SUPPLY_DETAIL_STATUS
    }
  },
  beforeCreate() {
    this.$store
      .dispatch('supply/getDetail', {
        clientOwnerCode: this.$route.params.clientOwnerCode,
        supplyCode: this.$route.params.supplyCode
      })
      .then((detail) => {
        this.detail = detail
      })
  },

  computed: {
    breadcrumbsItems() {
      let res = []
      if (this.detail && this.detail.address) {
        let address = this.detail ? this.detail.address : ''
        address = capitalize(address.toLowerCase())
        if (this.$router.currentRoute.name === 'billsArchive') {
          res = [
            { to: { name: 'home' }, text: 'Home' },
            {
              to: { name: 'ownerSupplies' },
              text: 'I tuoi servizi',
              exact: true
            },
            {
              to: {
                name: 'supplyDetail',
                params: {
                  clientOwnerCode: this.$route.params.clientOwnerCode,
                  supplyCode: this.$route.params.supplyCode
                }
              },
              text: address,
              exact: true
            },
            {
              to: { name: 'billsArchive' },
              text: 'Riepilogo e archivio pagamenti'
            }
          ]
        } else {
          res = [
            { to: { name: 'home' }, text: 'Home' },
            {
              to: { name: 'ownerSupplies' },
              text: 'I tuoi Servizi'
            },
            {
              to: { name: 'ownerSupplies' },
              text: 'Servizi e Consumi',
              exact: true
            },
            { to: { name: 'supplyDetail' }, text: address }
          ]
        }
      }
      return res
    }
  },
  methods: {
    getServiceIcon(service) {
      service = service ? service.toUpperCase() : ''
      if (
        this.detail.supplyState === this.SUPPLY_DETAIL_STATUS.CLOSED ||
        this.detail.supplyState === this.SUPPLY_DETAIL_STATUS.REJECTED
      ) {
        if (service === this.SERVICE_TYPE.GAS)
          return require('../../assets/icons/ic-fire-grey.svg')
        if (service === this.SERVICE_TYPE.ELE)
          return require('../../assets/icons/ic-light-grey.svg')
        if (service === this.SERVICE_TYPE.FIBER)
          return require('../../assets/icons/ic-wifi-grey.svg')
      } else {
        if (service === this.SERVICE_TYPE.GAS)
          return require('../../assets/icons/ic-fire-blu.svg')
        if (service === this.SERVICE_TYPE.ELE)
          return require('../../assets/icons/ic-light-blu.svg')
        if (service === this.SERVICE_TYPE.FIBER)
          return require('../../assets/icons/ic-wifi-blu.svg')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.user__detail {
  .detail__city h3 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .detail__noActive {
    font-size: 20px;
    color: #6e7689;
    letter-spacing: 0.4px;
    font-weight: bold;
  }
  .detail__name {
    margin-top: 26px;
    h5 {
      font-size: 20px;
      margin-right: 10px;
      font-weight: 500;
      color: black;
      letter-spacing: 1px;
      &.noBold {
        font-weight: normal;
      }
    }
    .v-image {
      margin-bottom: 5px;
    }
  }
  .detail__address {
    display: flex;
    justify-content: space-between;
    width: 1140px;
    h1 {
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.8px;
    }
  }
}
.user__cfp {
  .cfp__number {
    // background-image: url('../../styles/images/nuvola-cfp.png');
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 20px;
    height: 45px;
    padding-top: 10px;
    h3,
    h6 {
      display: inline-block;
      font-weight: bold;
    }
  }
  .cfp__text {
    padding-left: 20px;
  }
}
</style>
