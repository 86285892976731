<template>
  <div @click="redirecting" class="launcher">
    <div :class="buttonLabel ? 'ButtonL' : 'BodyL'">
      {{ label }}
    </div>
    <img width="24" height="24" src="@/assets/icons/chevron_rx.svg" />
  </div>
</template>

<script>
export default {
  name: 'MYSLauncher',
  props: {
    label: String,
    buttonLabel: {
      type: Boolean,
      default: false
    },
    redirect: null
  },
  methods: {
    redirecting() {
      this.$emit('redirecting', this.redirect)
    }
  }
}
</script>

<style lang="scss" scoped>
.launcher {
  height: 54px;
  width: 652px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid rgba(223, 228, 239, 1);
  margin: 15px 0;
  cursor: pointer;
}
.ButtonL {
  color: $srg-blue;
}
</style>
