import { render, staticRenderFns } from "./PunChart.vue?vue&type=template&id=5241e31c&scoped=true"
import script from "./PunChart.vue?vue&type=script&lang=js"
export * from "./PunChart.vue?vue&type=script&lang=js"
import style0 from "./PunChart.vue?vue&type=style&index=0&id=5241e31c&prod&lang=scss&scoped=true"
import style1 from "./PunChart.vue?vue&type=style&index=1&id=5241e31c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5241e31c",
  null
  
)

export default component.exports